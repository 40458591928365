import { ReactElement, ReactNode } from 'react'
import { Box } from '@ui/structure'

interface Props {
  children: ReactNode[]
  fullHeight?: boolean
  overlayingDrawer?: boolean
}

const Layout = ({ children, fullHeight = true, overlayingDrawer = true }: Props): ReactElement => {
  return (
    <Box
      sx={({ distances, spacing }) => ({
        display: 'flex',
        [fullHeight ? 'height' : 'minHeight']: `calc(100vh - ${distances.navBarHeight})`,
        margin: fullHeight ? spacing(-3) : 0,
        paddingLeft: overlayingDrawer ? 0 : '64px',
      })}
    >
      {children.map(childElement => {
        return childElement
      })}
    </Box>
  )
}

export default Layout
