import { ReactElement, useMemo } from 'react'
import { includes } from 'lodash-es'
import ConnectorMesh from '../ConnectorMesh'
import VerticalTransmitterMesh from '../VerticalTransmitterMesh'

const TransmitterMesh = ({
  data,
  hiddenElements = new Set(),
  elementGuids = [],
  ...props
}: TransmitterMeshProps): ReactElement => {
  const [lineGraph, pointGraph] = useMemo(() => {
    const [lineSupports, pointSupports] = data.element_supports.reduce(
      (acc, item) => {
        if (
          hiddenElements.has(item.element_guid) ||
          (elementGuids.length && !includes(elementGuids, item.element_guid))
        ) {
          return acc
        }

        return item.support_type === 'line'
          ? [[...acc[0], item], acc[1]]
          : [acc[0], [...acc[1], item]]
      },
      [[], []] as [ElementSupportItem[], ElementSupportItem[]],
    )

    return [
      {
        ...data,
        element_supports: lineSupports,
      },
      {
        ...data,
        element_supports: pointSupports,
      },
    ]
  }, [data, elementGuids, hiddenElements])

  return (
    <>
      <VerticalTransmitterMesh data={lineGraph} {...props} />
      <ConnectorMesh data={pointGraph} {...props} />
    </>
  )
}

export default TransmitterMesh
