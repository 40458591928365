import { useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { EventDispatcher, Vector3, Event } from 'three'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useThree } from '@react-three/fiber'

type OrbitControlEndEvent = Event & {
  type: 'end'
} & {
  target: EventDispatcher<Event>
}

/**
 * This hook will reset the camera of the focused connector, but only if it has
 * not been manually changed by the user in between
 * @param guid
 */
const useFocusedConnector = (guid?: string) => {
  const setRotationTarget = useCameraStore(state => state.setRotationTarget)
  const rotationTarget = useCameraStore(state => state.rotationTarget)
  const originalRotationTarget = useRef(rotationTarget)
  const targetMoved = useRef(false)

  const controls = useThree(state => state.controls)

  useEffect(() => {
    if (guid) targetMoved.current = false
  }, [guid])

  useEffect(() => {
    const targetLog = (e: OrbitControlEndEvent) => {
      const currentRotationTarget = useCameraStore.getState().rotationTarget

      // @ts-expect-error
      const target = e.target.target as Vector3
      if (!currentRotationTarget.v.equals(target)) {
        targetMoved.current = true
      }
    }

    // @ts-expect-error
    controls?.addEventListener('end', targetLog)

    // @ts-expect-error
    return () => controls?.removeEventListener('end', targetLog)
  }, [controls])

  useHotkeys('esc', () => {
    if (originalRotationTarget.current && !targetMoved.current) {
      setRotationTarget(originalRotationTarget.current)
    }
  })
}

export default useFocusedConnector
