import { useMemo } from 'react'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import Arrow from '../Arrow'

interface Props {
  loadDisplayData: PointLoadDisplayData
  onClick?: () => void
  isActive?: boolean
}

const PointLoadMeshElement = ({ loadDisplayData, onClick, isActive }: Props) => {
  const { magnitudePoints: loadPoint, label } = loadDisplayData

  const direction = useMemo(() => loadPoint[0].from.clone().sub(loadPoint[0].to), [loadPoint])

  const length = useMemo(() => direction.length(), [direction])

  return (
    <BasicMesh cursor="pointer" onClick={onClick}>
      <Arrow
        onClick={onClick}
        direction={direction.clone().normalize()}
        origin={loadPoint[0].to}
        length={length}
        label={label}
        highlighted={isActive}
      />
    </BasicMesh>
  )
}

export default PointLoadMeshElement
