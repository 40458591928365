import { ReactElement } from 'react'
import produce from 'immer'
import { reduce, findIndex, find } from 'lodash-es'
import numeral from 'numeral'
import { Stack, Typography } from '@mui/material'
import { SelectedItem } from '@ui/forms'
import { useControlStore } from '@editorStores'
import { loadCaseCategories } from '../AssignmentForm/constants'

interface Props {
  configurations: SnowLoadConfiguration[]
  removeConfigurations: (loadCase: LoadCase, distributedLoad: number) => void
}

const Assignments = ({ configurations, removeConfigurations }: Props): ReactElement => {
  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)

  const assignments = reduce(
    configurations,
    (collector, config) => {
      const index = findIndex(
        collector,
        item =>
          item.load_per_area === config.load_per_area &&
          item.load_case.category === config.load_case.category,
      )
      if (index === -1) {
        return [
          ...collector,
          {
            load_per_area: config.load_per_area,
            load_case: config.load_case,
            element_guids: [config.element_guid],
          },
        ]
      }

      return produce(collector, draft => {
        draft[index].element_guids = [...draft[index].element_guids, config.element_guid]
      })
    },
    [] as SnowLoadAssignment[],
  )

  return (
    <Stack direction="column" mt={1} spacing={1}>
      <Typography>Bestehende Zuweisungen:</Typography>
      {assignments.map(({ load_case, load_per_area, element_guids }, i) => {
        const loadCaseLabel = find(loadCaseCategories, { value: load_case.category })?.label
        const formattedLoadPerArea = numeral(load_per_area).format('0[.][00]a')

        return (
          <SelectedItem
            key={i}
            title={`${loadCaseLabel} - ${formattedLoadPerArea}N/m²`}
            onDelete={() => removeConfigurations(load_case, load_per_area)}
            onVisible={visible => {
              if (visible) removeHiddenElementIds(element_guids)
              else addHiddenElementIds(element_guids)
            }}
            count={element_guids.length}
          />
        )
      })}
    </Stack>
  )
}

export default Assignments
