import { useEffect } from 'react'
import { useElementSelectionStore } from '@editorStores'

const useHighlightPrimary = (ids: string[], condition = true) => {
  const setHighlightedIds = useElementSelectionStore(state => state.setSelectedStandaloneIds)

  useEffect(() => {
    if (!condition) return

    setHighlightedIds(ids)

    return () => setHighlightedIds([])
  }, [condition, setHighlightedIds, ids])
}

export default useHighlightPrimary
