import { ReactElement, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { findIndex } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Stack, Switch, Typography } from '@mui/material'
import { ErrorField, UnitConvertedField } from '@ui/forms'
import Box from '@ui/structure/Box'
import { useResultsStore } from '@editorStores'
import { smoothingSettingsSchema } from '../../schema'
import AddIntervalFields from './components/AddIntervalForm'
import IntervalList from './components/IntervalList'
import InvalidPositions from './components/InvalidPositions'

interface Props {
  isLoadingPreview: boolean
  onClickResetPreview: () => void
  onClickPosition: (guid: string) => void

  position: string
}

const LoadsSmoothing = ({
  isLoadingPreview,
  onClickResetPreview,
  onClickPosition,
  position,
}: Props): ReactElement => {
  const showPreviewLoads = useResultsStore(state => state.showPreviewLoads)
  const setShowPreviewLoads = useResultsStore(state => state.setShowPreviewLoads)

  const formState = useWatch()

  const index = useMemo(
    () => findIndex(formState.bundles, ['representative_position', position]),
    [formState, position],
  )

  const { setValue } = useFormContext()

  const smoothLoads = !!(formState.bundles as MemberPositionBundle[])[index]?.smoothing_settings

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">Lasten Glätten</Typography>

      <Stack spacing={2}>
        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Lasten anzeigen</Typography>
          <Switch
            checked={showPreviewLoads || false}
            onChange={e => setShowPreviewLoads(e.target.checked)}
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography>Lasten Glätten</Typography>
          <Checkbox
            checked={smoothLoads}
            onChange={e => {
              const newSettings = e.target.checked
                ? smoothingSettingsSchema.getDefaultFromShape()
                : undefined

              setValue(`bundles[${index}].smoothing_settings`, newSettings)
            }}
          />
        </Stack>

        {smoothLoads && (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Grenzwert für Einzellasten</Typography>
              <Box>
                <UnitConvertedField
                  name={`bundles[${index}].smoothing_settings.point_load_threshold`}
                  sx={{ width: 120 }}
                />
              </Box>
            </Stack>

            <AddIntervalFields index={index} />

            <IntervalList index={index} />

            <ErrorField name={`bundles[${index}].smoothing_settings.intervals`} />
            <ErrorField name={`bundles[${index}].smoothing_settings.isOverlapping`} />

            <Stack spacing={2} p={2} border={1} borderColor="grey.200" borderRadius={1}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setValue(`bundles[${index}].smoothing_settings.intervals`, [])
                    onClickResetPreview()
                  }}
                  fullWidth
                >
                  Zurücksetzen
                </Button>
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  loading={isLoadingPreview}
                  type="submit"
                  name="preview"
                >
                  Vorschau
                </LoadingButton>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>

      <InvalidPositions index={index} onClickPosition={onClickPosition} />
    </Stack>
  )
}

export default LoadsSmoothing
