import { ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { toImmutable } from '@modugen/scene/lib/utils'
import { ThreeEvent } from '@react-three/fiber'
import SimpleShape from '../SimpleShape'
import { getPolygonNormal } from '../utils'

interface Props extends IntervalProps {
  data: Slab[]
  onClick: (event: ThreeEvent<MouseEvent>) => void
}

const AddingSlabsMesh = ({ data, onClick }: Props): ReactElement => {
  const { scenePalette, sceneOffsets, sceneConfig } = useTheme()

  return (
    <>
      {data?.map((item: Slab) => {
        const vPoints = item.shape.points
        const normalNegated = getPolygonNormal(vPoints).v.negate()

        // we do this in order to cover any existing slab
        const movedPoints = vPoints.map(v => v.addScaledVector(toImmutable(normalNegated), 0.02))

        return (
          <SimpleShape
            key={item.guid}
            userData={{ guid: item.guid }}
            points={movedPoints}
            onClick={onClick}
            thickness={sceneConfig.defaultThickness + sceneOffsets.verticalSlabs.z + 0.04}
            cursor="pointer"
            color={scenePalette.highlight}
          />
        )
      })}
    </>
  )
}

export default AddingSlabsMesh
