import { getPolygonNormal } from '@scene'
import { maxBy } from 'lodash-es'
import { Vector3 } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const interpolateShapeObject = (element: ShapeObjectLine, relative_position: number) => {
  const start_p = element.shape.start
  const start = new Vector3(start_p.x, start_p.y, start_p.z)
  const end_p = element.shape.end
  const end = new Vector3(end_p.x, end_p.y, end_p.z)
  const vec = end.sub(start)
  const interpolated = start.add(vec.multiplyScalar(relative_position))
  return new Vector3(interpolated.x, interpolated.y, interpolated.z)
}

export const createProposalConfig = (
  wall: ShapeObject,
  leftIntervalInput?: NumberInterval,
  rightIntervalInput?: NumberInterval,
) => {
  const wallStart = wall.shape.points[0]
  const wallEnd = wall.shape.points[1]
  const wallLength = wallStart.distanceTo(wallEnd)
  const wallDirection = wall.shape.points[0].directionTo(wall.shape.points[1])

  const wallOrthoDirection = getPolygonNormal(wall.shape.points)

  const topPoint = maxBy(wall.shape.points, p =>
    new ImmutableVector3(p.x, p.y, wallStart.z).distanceTo(p),
  ) as ImmutableVector3

  let leftInterval: ImmutableVector3[] | null = null
  if (leftIntervalInput) {
    const ip1 = wallStart.addScaledVector(wallDirection, wallLength * leftIntervalInput.lower)
    const ip2 = wallStart.addScaledVector(wallDirection, wallLength * leftIntervalInput.upper)

    const ip3 = new ImmutableVector3(ip2.x, ip2.y, topPoint.z)
    const ip4 = new ImmutableVector3(ip1.x, ip1.y, topPoint.z)

    // we do this in order to display it in front of the wall
    leftInterval = [ip1, ip2, ip3, ip4].map(p => p.addScaledVector(wallOrthoDirection, -0.02))
  }

  let rightInterval: ImmutableVector3[] | null = null
  if (rightIntervalInput) {
    const ip1 = wallStart.addScaledVector(wallDirection, wallLength * rightIntervalInput.lower)
    const ip2 = wallStart.addScaledVector(wallDirection, wallLength * rightIntervalInput.upper)

    // TODO: this approach will always create rectangular intervals (also on
    // gable walls)
    const ip3 = new ImmutableVector3(ip2.x, ip2.y, topPoint.z)
    const ip4 = new ImmutableVector3(ip1.x, ip1.y, topPoint.z)

    rightInterval = [ip1, ip2, ip3, ip4].map(p => p.addScaledVector(wallOrthoDirection, -0.02))
  }

  return { leftInterval, rightInterval }
}
