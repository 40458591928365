import { ReactElement, useMemo } from 'react'
import { SimpleShape } from '@scene'
import { ColorRepresentation } from 'three'
import { Typography } from '@mui/material'
import { getCenterMultiple } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Html } from '@react-three/drei'

interface Props {
  points: ImmutableVector3[]
  color: ColorRepresentation
  label: string
}

const CornerConnectionProposalMesh = ({ points, color, label }: Props): ReactElement => {
  const center = useMemo(() => getCenterMultiple(points), [points])

  return (
    <>
      <SimpleShape
        points={points}
        color={color}
        renderOrder={1000}
        thickness={0.04}
        outlinesColor="#ffffff"
        outlines
        translucent
      />
      <Html position={center.v} transform sprite>
        <Typography variant="caption">{label}</Typography>
      </Html>
    </>
  )
}

export default CornerConnectionProposalMesh
