import { ReactElement, ReactNode, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import {
  TextField as MuiTextField,
  InputProps,
  OutlinedTextFieldProps,
  InputAdornment,
} from '@mui/material'
import FormControl from '../FormControl'
import NumberInput from '../NumberInput'

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string
  placeholder?: string
  label?: string | ReactNode
  inputLabel?: string
  tooltip?: TooltipContents
  InputProps?: InputProps
  unitLabel?: string | ReactNode
  conversionFactor?: number
  'data-cy'?: string
}

const UnitConvertedField = ({
  name,
  rows = 1,
  multiline = false,
  autoFocus = false,
  placeholder = '',
  disabled = false,
  label = '',
  inputLabel,
  InputProps = {},
  tooltip,
  unitLabel = 'kN',
  conversionFactor = 1000,
  'data-cy': dataCy,
  ...textFieldProps
}: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({ name, control })

  const displayValue = useMemo(() => {
    return !value ? value : Number(value / conversionFactor)
  }, [value])

  return (
    <FormControl
      label={!inputLabel ? label : undefined}
      error={error?.message}
      tooltip={tooltip}
      data-cy={dataCy}
    >
      <MuiTextField
        variant="outlined"
        onChange={event => {
          const eventValue = event.target.value
          const value = eventValue.length ? Number(eventValue) * conversionFactor : eventValue
          onChange(value)
        }}
        onBlur={onBlur}
        value={displayValue}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={fieldName}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        size="small"
        InputProps={{
          // eslint-disable-next-line
          inputComponent: NumberInput as any,
          endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment>,
          ...InputProps,
        }}
        label={inputLabel}
        inputProps={{
          step: 0.01,
          min: 0,
        }}
        data-cy={dataCy}
        {...textFieldProps}
      />
    </FormControl>
  )
}

export default UnitConvertedField
