export const memberCheckTypeToReadableMap: Record<MemberCheckType, string> = {
  BendingYZ: 'zweiachsige Biegung',
  BendingY: 'Biegung Y',
  BendingZ: 'Biegung Z',
  BendingWithTension: 'Biegung und Zug',
  BendingWithCompression: 'Biegung und Druck',
  TensionOnly: 'Zug',
  CompressionOnly: 'Druck',
  TwoWayShearStress: 'zweiachsiger Schub',
  ShearStressY: 'Schub Y',
  ShearStressZ: 'Schub Z',
  BendingYZHot: 'zweiachsige Biegung bei Brand',
  BendingWithTensionHot: 'Biegung und Zug bei Brand',
  BendingWithCompressionHot: 'Biegung und Druck bei Brand',
  TensionOnlyHot: 'Zug bei Brand',
  CompressionOnlyHot: 'Druck bei Brand',
  TwoWayShearStressHot: 'zweiachsiger Schub bei Brand',
  HotDimensioning: 'Querschnittsreduzierung bei Brand',
  DisplacementNetFinZ: 'Enddurchbiegung Z',
  DisplacementNetFinY: 'Enddurchbiegung Y',
  DisplacementNetFinYZ: 'zweiachsige Enddurchbiegung',
  DisplacementInstY: 'Durchbiegung Y',
  DisplacementInstZ: 'Durchbiegung Z',
  DisplacementInstYZ: 'Durchbiegung YZ',
  SupportCompression: 'Auflagerpressung',
  Vibration: 'Schwingung',
  SteelBasicCheck: 'Kombiniert',
  SteelDisplacement: 'Durchbiegung',
  SteelCompression: 'Druck',
  SteelSupportCompression: 'Auflagerpressung',
  SteelBasePlateWeld: 'Schweißnaht Fußplatte',
  FireProtectionRip: 'vereinfachte Heißbemessung Rippe',
}

const MEMBER_CHECK_TYPE_SORT_ORDER = [
  'TensionOnly',
  'CompressionOnly',
  'BendingY',
  'BendingZ',
  'BendingYZ',
  'BendingWithTension',
  'BendingWithCompression',
  'ShearStressY',
  'ShearStressZ',
  'TwoWayShearStress',
  'SupportCompression',
  'DisplacementInstY',
  'DisplacementInstZ',
  'DisplacementInstYZ',
  'DisplacementNetFinY',
  'DisplacementNetFinZ',
  'DisplacementNetFinYZ',
  'Vibration',
  'HotDimensioning',
  'FireProtectionRip',
  'TensionOnlyHot',
  'CompressionOnlyHot',
  'BendingYZHot',
  'BendingWithTensionHot',
  'BendingWithCompressionHot',
  'TwoWayShearStressHot',
  'SteelCompression',
  'SteelBasicCheck',
  'SteelSupportCompression',
  'SteelBasePlateWeld',
  'SteelDisplacement',
] as const

export const getCheckTypeSortValue = (checkType: MemberCheckType): number => {
  const index = MEMBER_CHECK_TYPE_SORT_ORDER.indexOf(
    checkType as typeof MEMBER_CHECK_TYPE_SORT_ORDER[number],
  )
  if (index === -1) {
    return MEMBER_CHECK_TYPE_SORT_ORDER.length
  }
  return index
}
