import { ReactElement } from 'react'
import { UnitField, UnitFieldProps } from '../UnitField'

type MillimeterFieldProps = Omit<
  UnitFieldProps,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
> & {
  numeralFormatter?: string
}

const MillimeterField = ({
  numeralFormatter = '0',
  ...textFieldProps
}: MillimeterFieldProps): ReactElement => {
  return (
    <UnitField
      {...textFieldProps}
      internalUnit="m"
      displayUnit="mm"
      numeralFormatter={numeralFormatter}
    />
  )
}

export default MillimeterField
