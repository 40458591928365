import { useEffect } from 'react'
import { useElementSelectionStore } from '@editorStores'

const useHighlightSecondary = (ids: string[], condition = true) => {
  const setHighlightedIds = useElementSelectionStore(state => state.setSecondaryHighlightedIds)

  useEffect(() => {
    if (!condition) return

    setHighlightedIds(ids)

    return () => setHighlightedIds([])
  }, [condition, setHighlightedIds, ids])
}

export default useHighlightSecondary
