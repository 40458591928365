import { ReactElement } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Switch as MuiSwitch, SwitchProps } from '@mui/material'

interface Props extends SwitchProps {
  name: string
}

const Switch = ({ name, ...switchProps }: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, name: fieldName, value, ref },
  } = useController({ name, control })

  return (
    <MuiSwitch ref={ref} name={fieldName} checked={value} onChange={onChange} {...switchProps} />
  )
}

export default Switch
