import { ReactElement, useCallback, useMemo } from 'react'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import { DraggableLine } from '@modugen/scene/lib/components/Lines/DraggableLine'
import { HighlightableLine } from '@modugen/scene/lib/components/Lines/HighlightableLine'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'
import { useEnhanceClickEvent } from '../../hooks'

export enum PurlinElements {
  Line = 'PurlinLine',
  Handle = 'PurlinHandle',
}
interface Props {
  purlin: ShapeObjectLine
  isActive?: boolean
  onClick?: (beamGuid: string) => void
  isSecondary?: boolean
}

const Purlin2D = ({
  purlin,
  onClick,
  isActive = false,
  isSecondary = false,
}: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const points = useMemo(
    () =>
      [purlin.shape.start, purlin.shape.end].map(({ x, y, z }) => new ImmutableVector3(x, y, z)),
    [purlin],
  )

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[purlin.guid], [purlin.guid]),
  ) as ColorRepresentation | undefined

  const { onClickEnhanced, onClickBase } = useEnhanceClickEvent(purlin.guid, onClick)

  if (isSecondary) {
    return (
      <HighlightableLine
        key={purlin.guid}
        line={points}
        color={scenePalette.elements3d.beams as string}
        cursor="pointer"
        isHighlighted={false}
        // width={wallWidth}
        hoverable={false}
        clickable={false}
        onClick={onClickBase}
        opacity={0.65}
      />
    )
  }

  return (
    <DraggableLine
      line={{ start: points[0], end: points[1] }}
      color={scenePalette.elements3d.beams as string}
      selectedColor={(mappedColor || scenePalette.elements3d.beams) as string}
      isSelected={isActive}
      showLengthIndicator={isActive}
      onClick={onClickEnhanced ? () => onClickEnhanced(purlin.guid) : undefined}
      showIndicators={false}
      lineName={PurlinElements.Line}
      hoverable={onClickEnhanced !== undefined}
    />
  )
}

export default Purlin2D
