import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import SelectedItem from '../SelectedItem'

interface Props {
  title: string
  arrayKey: string
  onVisible: (visible: boolean) => void
  onDelete: () => void
}

const SelectedArrayItem = ({
  title,
  arrayKey,
  onVisible,
  onDelete,
}: Props): ReactElement | null => {
  const { watch, setValue } = useFormContext()
  const arrayWatch = watch(arrayKey)

  if (!arrayWatch?.length) return null

  return (
    <SelectedItem
      title={title}
      count={arrayWatch.length}
      onVisible={onVisible}
      onDelete={() => {
        setValue(arrayKey, [])
        onDelete()
      }}
    />
  )
}

export default SelectedArrayItem
