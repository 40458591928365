import { useMemo } from 'react'
import { memoize, reduce } from 'lodash-es'
import useGuidToElement from './useGuidToElement'

const getSupportGuidToElement = memoize(
  (
    tensileGraph: TensileTransmissionGraph | null,
    guidToElement: Record<string, ShapeObject | ShapeObjectLine>,
  ) => {
    if (!tensileGraph) return null

    return reduce(
      tensileGraph.element_supports,
      (col, es) => {
        const element = guidToElement[es.element_guid]
        return {
          ...col,
          [es.guid]: element,
        }
      },
      {} as Record<string, ShapeObject | ShapeObjectLine | undefined>,
    )
  },
)

const useSupportGuidToElement = (tensileGraph: TensileTransmissionGraph | null) => {
  const guidToElement = useGuidToElement()

  const supportGuidToElement = useMemo(
    () => getSupportGuidToElement(tensileGraph, guidToElement),
    [tensileGraph, guidToElement],
  )

  return supportGuidToElement
}

export default useSupportGuidToElement
