import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { SelectedElementProblemsList, TensileTransmitterList } from '@structuralPlanningComponents'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Stack, Tab } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore } from '@editorStores'
import { useResultsInvalidation, useElementType } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { getElementCrossSectionAssignment, getModel } from '@queries'
import {
  deleteRip,
  recalculateTensileTargets,
  restoreSelectedPositionCSFromAssembly,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { useSaveTensileTransmissionGraph } from '../../hooks/useSaveTensileTransmissionGraph'
import VerticalTransmitterList from '../VerticalTransmitterList'
import { positionTabs } from './tabs'

interface Props {
  position: string
}

const PositionForm = ({ position }: Props): ReactElement => {
  const { projectId } = useParams()
  const client = useQueryClient()
  const invalidateResults = useResultsInvalidation()
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { verticalTransmitter, selectedElements, activeTab },
    actions: { setVerticalTransmitter, setActiveTab },
  } = useStructuralPlanningQueryParams()

  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )

  const elementType = useElementType(position)

  const { mutate: handleDelete, isLoading: isDeleting } = useMutation(
    (position: string) => {
      return deleteRip.request(projectId, position)
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Wandrippe erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: Error | AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Wandrippe'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: restorePositionCS, isLoading: isRestoringPositionCS } = useMutation(
    (element_guid: string) => {
      return restoreSelectedPositionCSFromAssembly.request(projectId as string, [element_guid])
    },
    {
      onSuccess: async () => {
        client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        enqueueSnackbar('Querschnitte von Rippen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )

  const { mutate: saveGraph, isLoading: isSavingGraph } = useSaveTensileTransmissionGraph(
    projectId as string,
  )

  const { mutate: recalculateTargets, isLoading: isRecalculating } = useMutation(
    () => recalculateTensileTargets.request(projectId, selectedElements?.[0] as string),
    {
      onSuccess: async data => {
        setTensileTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const selectedTab = useMemo(() => {
    if (activeTab && find(Object.entries(positionTabs), ([, e]) => e.tab === activeTab))
      return activeTab

    return positionTabs.verticalTransmission.tab
  }, [activeTab])

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList
            onChange={(_, value) => setActiveTab(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={positionTabs.geometry.tab}
              label={positionTabs.geometry.label}
              data-cy="tab-geometry"
            />
            <Tab
              value={positionTabs.verticalTransmission.tab}
              label={positionTabs.verticalTransmission.label}
            />
            <Tab
              value={positionTabs.connector.tab}
              label={positionTabs.connector.label}
              data-cy="tab-connector"
            />
          </TabList>
        </Box>
        <TabPanel value={positionTabs.connector.tab}>
          <TensileTransmitterList
            key={`connector-list-${isRecalculating}`}
            selectedElement={position}
            onSave={() => saveGraph(graph)}
            onRecalculate={() => recalculateTargets()}
            isRecalculating={isRecalculating}
            isLoading={isSavingGraph}
          />
        </TabPanel>
        <TabPanel value={positionTabs.verticalTransmission.tab}>
          <VerticalTransmitterList
            selectedElement={position}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={setVerticalTransmitter}
          />
          <SelectedElementProblemsList selectedElement={position} />
        </TabPanel>
        <TabPanel value={positionTabs.geometry.tab}>
          <Stack direction="column" spacing={2}>
            <SingleElementCSForm
              selectedElement={position}
              elementType={elementType as ElementTypes}
            />

            <LoadingButton
              loading={isRestoringPositionCS}
              type="submit"
              variant="contained"
              size="small"
              data-cy="restore-rip-cs-btn"
              onClick={() => restorePositionCS(position)}
            >
              Querschnitt aus Aufbau wiederherstellen
            </LoadingButton>

            <Box display="flex" justifyContent="flex-end">
              <DeleteButton
                onClick={() => handleDelete(position)}
                loading={isDeleting}
                data-cy="delete-rip-btn"
              >
                Löschen
              </DeleteButton>
            </Box>
          </Stack>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default PositionForm
