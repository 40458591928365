import { ReactElement, useEffect, useMemo, useState } from 'react'
import { MAX_UTILIZATION_WARNING_THRESHOLD } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { orderBy } from 'lodash-es'
import { ArrowForward } from '@mui/icons-material'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Tooltip,
  Typography,
  TableSortLabel,
  Stack,
  IconButton,
} from '@mui/material'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { useResultsStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { makeCrossSectionLabel } from '../../../../utils/crossSectionLabel'

interface Props {
  bundles: MemberPositionBundleWithMaxUtilization[]
}

const StandardRipCategoryGroupingList = ({ bundles }: Props): ReactElement => {
  const { palette } = useTheme()

  const {
    params: { selectedElement },
    actions: { selectPosition, setIsLocalMode },
  } = useResultsQueryParams()

  const getLabel = useElementLabel()

  const [sortTableBy, setSortBy] = useState<PositionGroupingTableSortCategory>(
    'utilization-without-compression',
  )
  const [sortDescending, setSortDescending] = useState(true)

  const standardRipChecks = useResultsStore(state => state.standardRipChecks)

  const [intermediateBundles, setIntermediateBundles] = useState(bundles)

  // MEMOS

  const sortedBundles = useMemo(
    () =>
      orderBy(
        intermediateBundles,
        ({
          exported,
          representative_position,
          maxUtilOnlySupportCompression,
          maxUtilWithoutSupportCompression,
          representative_for,
        }) => {
          if (sortTableBy === 'export') return exported
          if (sortTableBy === 'utilization-without-compression')
            return maxUtilWithoutSupportCompression?.max_utilization
          if (sortTableBy === 'utilization-just-compression')
            return maxUtilOnlySupportCompression?.max_utilization
          if (sortTableBy === 'name') return getLabel(representative_position)
          if (sortTableBy === 'representative') return representative_for.length
        },
        sortDescending ? 'desc' : 'asc',
      ),
    [intermediateBundles, sortTableBy, sortDescending],
  )

  // EFFECTS

  useEffect(() => {
    setIntermediateBundles(bundles)
  }, [bundles])

  return (
    <Stack direction="column" spacing={2}>
      <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
        <Table size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={sortTableBy === 'name'}
                  direction={sortDescending ? 'desc' : 'asc'}
                  onClick={() =>
                    sortTableBy === 'name' ? setSortDescending(!sortDescending) : setSortBy('name')
                  }
                  hideSortIcon={sortTableBy !== 'name'}
                >
                  Bauteilname
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Maximale Ausnutzung ohne Auflagerpressung" placement="top">
                  <TableSortLabel
                    active={sortTableBy === 'utilization-without-compression'}
                    direction={sortDescending ? 'desc' : 'asc'}
                    onClick={() =>
                      sortTableBy === 'utilization-without-compression'
                        ? setSortDescending(!sortDescending)
                        : setSortBy('utilization-without-compression')
                    }
                    hideSortIcon={sortTableBy !== 'utilization-without-compression'}
                  >
                    <UtilizationTextIcon />
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Maximale Ausnutzung für Auflagerpressung" placement="top">
                  <TableSortLabel
                    active={sortTableBy === 'utilization-just-compression'}
                    direction={sortDescending ? 'desc' : 'asc'}
                    onClick={() =>
                      sortTableBy === 'utilization-just-compression'
                        ? setSortDescending(!sortDescending)
                        : setSortBy('utilization-just-compression')
                    }
                    hideSortIcon={sortTableBy !== 'utilization-just-compression'}
                  >
                    <UtilizationC90TextIcon />
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>QS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-cy="position-category-table-body">
            {sortedBundles.map(
              ({
                representative_position,
                maxUtilizationCheck,
                maxUtilOnlySupportCompression,
                maxUtilWithoutSupportCompression,
              }) => {
                if (!standardRipChecks || standardRipChecks.length === 0) return <></>
                const checksForRip = standardRipChecks?.filter(
                  ripCheck => ripCheck.standard_rip_member.guid === representative_position,
                )

                if (checksForRip.length !== 1) return <></>

                const crossSection = checksForRip[0].standard_rip_member.cross_section

                const crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)

                const displayId = checksForRip[0].wall_guid

                return (
                  <TableRow
                    key={representative_position}
                    onClick={() => selectPosition(representative_position)}
                    hover
                    selected={selectedElement === representative_position}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '.MuiTableCell-root': {
                        color:
                          maxUtilizationCheck &&
                          maxUtilizationCheck?.max_utilization > MAX_UTILIZATION_WARNING_THRESHOLD
                            ? `${palette.error.main}`
                            : undefined,
                      },
                    }}
                  >
                    <TableCell>{getLabel(displayId)}</TableCell>
                    <TableCell align="right">
                      <Tooltip title={maxUtilWithoutSupportCompression?.check_type}>
                        <Typography>
                          {maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) || 'n/a'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={maxUtilOnlySupportCompression?.check_type}>
                        <Typography>
                          {maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) || 'n/a'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{crossSectionLabel}</TableCell>

                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={(e: { stopPropagation: () => void }) => {
                          e.stopPropagation()
                          setIsLocalMode(true, representative_position)
                        }}
                        data-cy={
                          selectedElement === representative_position
                            ? 'btn-to-local-active'
                            : undefined
                        }
                      >
                        <ArrowForward fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default StandardRipCategoryGroupingList
