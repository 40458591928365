import { ReactElement, useState } from 'react'
import * as React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonProps,
} from '@mui/material'

interface OptionType {
  value: any // eslint-disable-line
  label: string
}

interface Props {
  children: string
  onClick: (option: OptionType) => void
  options: OptionType[]
  buttonProps?: ButtonProps
  executeOnSelect?: boolean
  'data-cy'?: string
}

const SelectableButton = ({
  options,
  children,
  buttonProps = {},
  onClick,
  executeOnSelect = false,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (executeOnSelect) onClick(options[index])
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        color={buttonProps.color}
      >
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          data-cy={`${dataCy}-dropdown`}
          {...buttonProps}
        >
          {options[selectedIndex].label}
          <ArrowDropDownIcon fontSize="small" />
        </Button>
        <Button
          onClick={() => onClick(options[selectedIndex])}
          data-cy={`${dataCy}-button`}
          {...buttonProps}
        >
          {children}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map(({ label }, index) => (
                    <MenuItem
                      key={label}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                      data-cy={`${dataCy}-item-${index}`}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default SelectableButton
