import { ReactElement, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { findIndex } from 'lodash-es'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { TextField } from '@ui/forms'
import { useModelClickListeners, useSelectionMode } from '@editorHooks'
import { reassignRepresentativePositionInBundles } from './utils'

interface Props {
  element: string
}

const FormFields = ({ element }: Props): ReactElement => {
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const formState = useWatch()

  const { setValue } = useFormContext()

  const { isSelectionMode } = useSelectionMode()

  const index = useMemo(
    () => findIndex(formState.bundles, ['representative_position', element]),
    [formState, element],
  )

  // EVENTS

  useModelClickListeners(
    event => {
      const guid = event.object.name as string

      if (guid === element) return

      setValue('bundles', reassignRepresentativePositionInBundles(formState.bundles, element, guid))
    },
    [formState.bundles, element],
    isSelectionMode && !isTapelineActive,
  )

  return (
    <>
      <TextField
        name={`bundles[${index}].comment`}
        label="Kommentar für Positionsliste"
        placeholder="Kommentar für Positionsliste"
      />
      <TextField
        name={`bundles[${index}].extended_comment`}
        label="Vorbemerkungen"
        placeholder="Vorbemerkungen (mehrere Zeilen möglich)"
        multiline={true}
        rows={3}
        data-cy={'txt-extended-comment'}
      />
    </>
  )
}

export default FormFields
