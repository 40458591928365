import { useRef, ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/web'
import { ThreeEvent } from '@react-three/fiber'
import { useModelHoverListeners } from '@editorHooks'

interface Props {
  onPointerOver: (event: ThreeEvent<MouseEvent>) => string | null
  offset?: number
  delay?: number
}

const SceneMouseTooltip = ({ onPointerOver, offset = 15, delay = 500 }: Props): ReactElement => {
  const { palette, shape, spacing } = useTheme()
  const tooltipRef = useRef<HTMLDivElement>()
  const [spring, set] = useSpring(() => ({
    top: '0px',
    left: '0px',
    opacity: 0,
  }))

  useModelHoverListeners(
    [
      event => {
        const text = onPointerOver(event)

        setTimeout(() => {
          if (tooltipRef.current && text) {
            tooltipRef.current.innerHTML = text
          }
        }, delay)

        set({
          top: `${event.clientY + offset}px`,
          left: `${event.clientX + offset}px`,
          opacity: text ? 1 : 0,
          delay,
        })
      },
      () => {
        set({ opacity: 0 })
      },
    ],
    [onPointerOver],
  )

  return (
    <a.div
      // @ts-expect-error
      ref={tooltipRef}
      style={{
        // @ts-ignore
        position: 'fixed',
        background: 'black',
        color: '#fff',
        pointerEvents: 'none',
        borderRadius: shape.borderRadius,
        padding: spacing(0.5, 1),
        backgroundColor: palette.grey[800],
        fontSize: 12,
        ...spring,
      }}
    />
  )
}

export default SceneMouseTooltip
