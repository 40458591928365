import { ReactElement, useEffect, useRef } from 'react'
import {
  plotCLTAssemblyLayer,
  plotTFAssemblyLayer,
  plotPlateLayer,
  plotScatteredFormwork,
} from '../utils'

interface Props {
  layers: PlotLayer[]
  length: number
  thicknessScalingFactor: number
  orientation?: LayerOrientation
  height?: number
  xStartPos?: number
  yStartPos?: number
  viewBox?: string
}

const PlotIllustration = ({
  layers,
  length,
  thicknessScalingFactor,
  orientation = 'Vertical',
  height = 250,
  xStartPos = 0,
  yStartPos = 0,
  viewBox = '',
}: Props): ReactElement => {
  const ref = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    let xPos = xStartPos
    let yPos = yStartPos

    if (ref.current) {
      ref.current.innerHTML = ''
    }

    layers.forEach(value => {
      switch (value.layerType) {
        case 'CLT3Layer': {
          plotCLTAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            3,
            orientation,
          )
          break
        }
        case 'CLT5Layer': {
          plotCLTAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            5,
            orientation,
          )
          break
        }
        case 'CLT7Layer': {
          plotCLTAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            7,
            orientation,
          )
          break
        }
        case 'CLT9Layer': {
          plotCLTAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            9,
            orientation,
          )
          break
        }
        case 'TimberFrameInsulationFull': {
          plotTFAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            'Full',
            orientation,
          )
          break
        }
        case 'TimberFrameInsulationPartly': {
          plotTFAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            'Half',
            orientation,
          )
          break
        }
        case 'TimberFrameNoInsulation': {
          plotTFAssemblyLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            'None',
            orientation,
          )
          break
        }
        case 'GypsumBoard': {
          plotPlateLayer(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            orientation,
          )
          break
        }
        case 'Formwork': {
          plotScatteredFormwork(
            ref,
            xPos,
            yPos,
            length,
            value.thickness * thicknessScalingFactor,
            value.color,
            orientation,
          )
          break
        }
      }

      if (orientation === 'Vertical') {
        xPos = xPos + value.thickness * thicknessScalingFactor
      } else {
        yPos = yPos + value.thickness * thicknessScalingFactor
      }
    })
  }, [layers, length])

  return (
    <div>
      <svg
        viewBox={viewBox}
        style={{
          width: '100%',
          height,
        }}
        ref={ref}
      ></svg>
    </div>
  )
}

export default PlotIllustration
