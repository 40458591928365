import { ReactElement, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { TextField, MillimeterField } from '@ui/forms'

type UnitSettingState = 'weight' | 'density'

const FormFields = (): ReactElement => {
  const { watch, setValue } = useFormContext()
  const thickness = watch('thickness')
  const density = watch('product.density')
  const weight = watch('product.weight')

  const [unitSetting, setUnitSetting] = useState<UnitSettingState>('density')

  useEffect(() => {
    setValue('product.weight', densityToWeight(density, thickness))
  }, [])

  const handleUnitToggle = () => {
    if (unitSetting === 'weight') {
      setUnitSetting('density')
    } else {
      setUnitSetting('weight')
    }
  }

  const weightToDensity = (weight: number, thickness: number) => {
    return (weight * 100) / thickness
  }

  const densityToWeight = (density: number, thickness: number) => {
    return (density * thickness) / 100
  }

  return (
    <Stack alignContent={'center'}>
      <Stack direction={'row'} justifyContent={'space-around'}>
        <ToggleButtonGroup value={unitSetting} exclusive onChange={handleUnitToggle}>
          <ToggleButton value="weight" data-cy="product-toggle-weight">
            Flächenlast
          </ToggleButton>
          <ToggleButton value="density" data-cy="product-toggle-density">
            Dichte
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Product name"
            name="product.name"
            autoFocus
            data-cy="product-layer-name"
          />
        </Grid>
        <Grid item xs={6}>
          <MillimeterField
            label="Dicke"
            name="thickness"
            data-cy="product-layer-thickness"
            onChange={event => {
              const value = event.target.value.length
                ? Number(event.target.value) / 1000
                : Number(event.target.value)

              setValue('thickness', value)
              if (!isNaN(value)) {
                setValue('product.density', weightToDensity(weight, value))
                setValue('product.weight', densityToWeight(density, value))
              }
            }}
            numeralFormatter="0.[0]"
          />
        </Grid>
        <Grid item xs={6}>
          {unitSetting === 'density' && (
            <TextField
              label="Dichte"
              type="number"
              name="product.density"
              unit="kg/m3"
              data-cy="product-layer-density"
              disabled={unitSetting !== 'density'}
              onChange={event => {
                const value = Number(event.target.value)

                setValue('product.density', value)

                if (!isNaN(value)) {
                  setValue('product.weight', densityToWeight(value, thickness))
                }
              }}
            />
          )}
          {unitSetting === 'weight' && (
            <TextField
              label="Flächenlast"
              type="number"
              name="product.weight"
              unit="kN/m2"
              data-cy="product-layer-weight"
              disabled={unitSetting !== 'weight'}
              onChange={event => {
                const value = Number(event.target.value)

                setValue('product.weight', value)

                if (!isNaN(value)) {
                  setValue('product.density', weightToDensity(value, thickness))
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default FormFields
