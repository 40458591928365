import { ReactElement, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { Download, Settings } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ListItem, ListItemText, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { DialogBase } from '@ui/feedback'
import { Form, Switch, UnitConvertedField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { downLoadFile } from '@utils'
import { getExportDocument } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'

interface Props {
  title: string
  path: string
  type: BlobType
  filename: string
  disabled?: boolean
  extension?: string
  'data-cy'?: string
}

const FoundationLoadItemWithSettings = ({
  title,
  path,
  type,
  filename,
  disabled = false,
  extension = 'txt',
}: Props): ReactElement => {
  const theme = useTheme()
  const { projectId } = useParams()
  const project = useModelStore(state => state.project)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const formID = 'rfem-settings-form'

  const { mutate: downloadExportDocument, isLoading } = useMutation(
    async ({
      documentPath,
      type,
      filename,
      queryParams,
    }: {
      documentPath: string
      type: BlobType
      filename: string
      queryParams?: Record<string, string>
    }) => {
      const data = await getExportDocument.request(projectId as string, documentPath, queryParams)
      return {
        data,
        type,
        filename: `${filename} - ${project.name || projectId}.${extension}`,
      }
    },
    {
      onSuccess: async ({ data, type, filename }) => {
        if (!data) {
          enqueueSnackbar('Ergebnisse noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(data, type, filename)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Abrufen des Dokuments'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <ListItem>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ color: disabled ? theme.palette.text.disabled : undefined }}
      />
      <LoadingButton onClick={() => setDialogOpen(true)} variant="outlined">
        <Settings />
      </LoadingButton>
      <DialogBase
        title={'Fundamentlasten Einstellungen'}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <Form
          id={formID}
          defaultValues={{ smoothLoads: true, pointLoadThreshold: 20000 }}
          onSubmit={({
            pointLoadThreshold,
            smoothLoads,
          }: {
            pointLoadThreshold: number
            smoothLoads: boolean
          }) =>
            downloadExportDocument({
              documentPath: path,
              type,
              filename,
              queryParams: {
                point_load_threshold: pointLoadThreshold.toString(),
                apply_smoothing: smoothLoads.toString(),
              },
            })
          }
        >
          <Stack direction="column" spacing={4}>
            <Tooltip
              title="Lasten werden über die Gesamte Elementlänge geglättet. 
              Punktlasten kleiner als der Grenzwert"
            >
              <Stack direction="row" alignItems="center">
                <Switch name="smoothLoads" size="small" />
                <Typography sx={{ p: 1 }} noWrap>
                  Lasten vor Export glätten?
                </Typography>
              </Stack>
            </Tooltip>
            <UnitConvertedField
              name="pointLoadThreshold"
              conversionFactor={1000}
              inputLabel="Grenzwert für Punktlasten"
              unitLabel="kN"
            />
          </Stack>
        </Form>
        <LoadingButton type="submit" form={formID} variant="contained" loading={isLoading}>
          <Download />
        </LoadingButton>
      </DialogBase>
    </ListItem>
  )
}

export default FoundationLoadItemWithSettings
