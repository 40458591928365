import { ReactElement, useMemo } from 'react'
import { find, isFunction, filter, toNumber } from 'lodash-es'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { ThreeEvent } from '@react-three/fiber'
import { getPointAtPercentage } from '../utils'
import CylinderMesh from './CylinderMesh'

interface PositionedSupport {
  item: ElementSupportItem
  position: ImmutableVector3
}

interface PositionedTarget extends PositionedSupport {
  supportItem: ElementSupportItem
  supportPosition: ImmutableVector3
}

const ConnectorMesh = ({
  data,
  domains,
  onClick,
  transmitterGuid,
}: TransmitterMeshProps): ReactElement => {
  const theme = useTheme()
  const supportItems = useMemo(() => {
    const supportPositions = data.element_supports.reduce((acc: PositionedSupport[], support) => {
      const { domain_guid, relative_position } = support

      const domain = find(domains, ['guid', domain_guid])

      if (!domain) return acc

      return [
        ...acc,
        {
          position: getPointAtPercentage(domain.start, domain.end, toNumber(relative_position)),
          item: support,
        },
      ]
    }, [])

    return supportPositions
  }, [data.element_supports, domains])

  const supportTargetMap = useMemo(() => {
    return supportItems.reduce(
      (
        acc: {
          [key: string]: boolean
        },
        { item },
      ) => {
        const hasTarget = !!find(data.support_targets, ['support_guid', item.guid])

        return {
          ...acc,
          [item.guid]: hasTarget,
        }
      },
      {},
    )
  }, [supportItems, data.element_supports, data.support_targets])

  const targetItems = useMemo(() => {
    return supportItems.reduce(
      (acc: PositionedTarget[], { item: supportItem, position: supportPosition }) => {
        const { guid: supportGuid } = supportItem
        const items = filter(data.support_targets, ['support_guid', supportGuid]).reduce(
          (itemAcc: PositionedTarget[], { target_guid }) => {
            const target = find(data.element_targets, ['guid', target_guid])

            if (!target) return itemAcc

            const { domain_guid, relative_position } = target
            const domain = find(domains, ['guid', domain_guid])

            if (!domain) return itemAcc

            return [
              ...itemAcc,
              {
                position: getPointAtPercentage(
                  domain.start,
                  domain.end,
                  toNumber(relative_position),
                ),
                item: target,
                supportItem: supportItem,
                supportPosition,
              },
            ]
          },
          [],
        )

        return [...acc, ...items]
      },
      [],
    )
  }, [data.support_targets, data.element_targets, supportItems, supportTargetMap])

  const handleConnectorClick = (event: ThreeEvent<MouseEvent>, connector: ElementSupportItem) => {
    event.stopPropagation()

    if (!isFunction(onClick)) return

    onClick(connector)
  }

  return (
    <>
      {supportItems.map(({ position, item }, index) => {
        const isActive = item.guid === transmitterGuid
        const hasTarget = supportTargetMap[item.guid]

        return (
          <CylinderMesh
            onClick={event => handleConnectorClick(event, item)}
            key={index}
            position={position}
            isActive={isActive}
            color={hasTarget ? theme.scenePalette.elements3d.supports : theme.scenePalette.invalid}
          />
        )
      })}
      {targetItems.map(({ position, supportItem, supportPosition }, index) => {
        const isActive = supportItem.guid === transmitterGuid

        return (
          <CylinderMesh
            onClick={event => handleConnectorClick(event, supportItem)}
            key={index}
            position={position}
            supportPosition={supportPosition}
            isActive={isActive}
            isTarget
            color={theme.scenePalette.elements3d.targets}
          />
        )
      })}
    </>
  )
}

export default ConnectorMesh
