import { ReactElement } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useResultsInvalidation } from '@editorHooks'
import { getLiveLoads } from '@queries'
import { saveLiveLoads } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import LiveLoads from './components/LiveLoads'

const LiveLoadsTab = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const invalidateResults = useResultsInvalidation()

  const { isFetching: isLoadingLiveLoads, data: liveLoadsData } = useQuery(
    getLiveLoads.getKey(projectId),
    () => getLiveLoads.request(projectId),
    {
      onError: () => enqueueSnackbar('Fehler beim Laden der Nutzlasten', { variant: 'error' }),
      refetchOnMount: 'always',
    },
  ) as { isFetching: boolean; data?: LiveLoadConfiguration[] }

  const { isLoading: isSavingLiveLoads, mutate: mutateLiveLoads } = useMutation(
    (data: LiveLoadConfiguration[]) => saveLiveLoads.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        enqueueSnackbar('Nutzlasten erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Nutzlasten'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <LiveLoads
      isLoading={isLoadingLiveLoads}
      isSaving={isSavingLiveLoads}
      data={liveLoadsData}
      onSave={data => mutateLiveLoads(data.configurations)}
    />
  )
}

export default LiveLoadsTab
