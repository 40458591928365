import { useEffect, memo, ReactElement, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import numeral from 'numeral'
import { Grid, InputAdornment, TextField as MuiTextField } from '@mui/material'
import { LoadForceField, TextField, RelativePositionField, FormControl } from '@ui/forms'
import { useElementLoadStore } from '@editorStores'
import { useAllDomains, useDomain, useStoreSubscription } from '@editorHooks'
import LoadCaseSelect from '../LoadCaseSelect'
import SelectDomain from '../SelectDomain'
import { getDefaultForce } from '../utils'

interface Props {
  selectedElement?: string
  index: number
  active: boolean
}

const PointLoadFormFields = ({ selectedElement, index, active }: Props): ReactElement | null => {
  const { watch, setValue } = useFormContext()
  const disabled = !selectedElement
  const fieldName = `point-load[${index}]`
  const domainGuid = watch(`${fieldName}.domain_guid`)
  const initialDomainGuid = useRef(domainGuid)
  const domain = useDomain(domainGuid)
  const allDomains = useAllDomains()
  const editLoadsPerElement = useElementLoadStore(state => state.editLoadsPerElement)

  useEffect(() => {
    setValue(`${fieldName}.domain`, domain)

    if (domainGuid !== initialDomainGuid.current) {
      setValue(`${fieldName}.force`, getDefaultForce(domain))
      initialDomainGuid.current = domainGuid
    }
  }, [domainGuid])

  useStoreSubscription({
    writeCallback: editLoadsPerElement,
    fieldName,
    compareValues: true,
    active,
  })

  const category: LoadCategory = watch(`${fieldName}.load_case.category`)
  const loadCaseType: LoadCaseTypes = watch(`${fieldName}.load_case.load_case_type`)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectDomain
          selectedElement={selectedElement}
          name={`${fieldName}.domain_guid`}
          domains={allDomains}
        />
      </Grid>
      <Grid item xs={6}>
        <LoadCaseSelect
          onSelect={({ identifier, category, loadCaseType }) => {
            setValue(`${fieldName}.load_case.identifier`, identifier)
            setValue(`${fieldName}.load_case.category`, category)
            setValue(`${fieldName}.load_case.load_case_type`, loadCaseType)
          }}
          value={`${category}+${loadCaseType}`}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl label="Kantenlänge">
          <MuiTextField
            key={numeral(domain?.length).format('0.00')}
            value={numeral(domain?.length).format('0.00')}
            disabled
            type="string"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <RelativePositionField
          length={domain?.length || 0}
          label="Position"
          name={`${fieldName}.relative_position`}
          disabled={disabled}
          data-cy="input-load-position"
        />
      </Grid>
      <Grid item xs={6}>
        <LoadForceField
          domainFieldName={`${fieldName}.domain`}
          label="Kraft"
          name={`${fieldName}.force`}
          disabled={disabled}
          data-cy="input-load-force"
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          label="Kommentar"
          placeholder="Kommentar"
          rows={2}
          name={`${fieldName}.comment`}
          multiline
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default memo(PointLoadFormFields)
