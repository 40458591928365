import { ReactElement, cloneElement } from 'react'
import { Stack, Typography } from '@mui/material'

interface Props {
  icon: ReactElement
  children: string
}

const Info = ({ icon, children }: Props): ReactElement => {
  const Icon = cloneElement(icon, { color: 'action', fontSize: 'medium' })

  return (
    <Stack
      direction="row"
      sx={theme => ({
        m: 1,
        p: 2,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 1.5,
        alignItems: 'center',
      })}
      spacing={1}
    >
      {Icon}
      <Typography fontSize={16}>{children}</Typography>
    </Stack>
  )
}

export default Info
