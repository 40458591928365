import { ReactElement, ReactNode } from 'react'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'

interface Props {
  children: ReactNode
}

interface SceneStoreInitialState {
  guidToColor: Record<string, string>
}

interface ControlStoreType extends SceneStoreInitialState {
  setGuidToColorMapping: (guidToColor: Record<string, string>) => void
  clearGuidToColorMapping: () => void
}

const initialState: SceneStoreInitialState = {
  guidToColor: {},
}

const createStore = () =>
  create(
    combine(
      {
        ...initialState,
      },
      set => ({
        setGuidToColorMapping: (guidToColor: Record<string, string>) => set({ guidToColor }),
        clearGuidToColorMapping: () => set({ guidToColor: {} }),
      }),
    ),
  )

const { Provider, useStore: useSceneStore } = createContext<ControlStoreType>()

const SceneStoreProvider = ({ children }: Props): ReactElement => (
  <Provider createStore={createStore}>{children}</Provider>
)

export { SceneStoreProvider, useSceneStore }
