import { useEffect, memo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash-es'
import numeral from 'numeral'
import { Grid, InputAdornment, TextField as MuiTextField } from '@mui/material'
import { LoadForceField, TextField, RelativePositionField, FormControl } from '@ui/forms'
import { useElementLoadStore } from '@editorStores'
import { useAllDomains, useDomain, useStoreSubscription } from '@editorHooks'
import LoadCaseSelect from '../LoadCaseSelect'
import SelectDomain from '../SelectDomain'
import { getDefaultForce } from '../utils'

interface Props {
  selectedElement?: string
  index: number
  active: boolean
}

const LineLoadFormFields = ({ selectedElement, index, active }: Props) => {
  const {
    watch,
    setValue,
    formState: { touchedFields },
  } = useFormContext()
  const disabled = !selectedElement
  const fieldName = `line-load[${index}]`
  const domainGuid = watch(`${fieldName}.domain_guid`)
  const initialDomainGuid = useRef(domainGuid)
  const startForce = watch(`${fieldName}.start`)
  const isLocal = watch(`${fieldName}.isLocal`)
  const allDomains = useAllDomains()
  const domain = useDomain(domainGuid)
  const editLoadsPerElement = useElementLoadStore(state => state.editLoadsPerElement)

  useEffect(() => {
    if (!isLocal || isNaN(startForce?.z)) return

    const path = `${fieldName}.end`
    const touched = get(touchedFields, path)

    if (touched) return

    setValue(path, startForce)
  }, [startForce?.z])

  useEffect(() => {
    setValue(`${fieldName}.domain`, domain)

    if (domainGuid !== initialDomainGuid.current) {
      setValue(`${fieldName}.start`, getDefaultForce(domain))
      setValue(`${fieldName}.end`, getDefaultForce(domain))
      initialDomainGuid.current = domainGuid
    }
  }, [domainGuid])

  useStoreSubscription({
    writeCallback: editLoadsPerElement,
    fieldName,
    compareValues: true,
    active,
  })

  const category: LoadCategory = watch(`${fieldName}.load_case.category`)
  const loadCaseType: LoadCaseTypes = watch(`${fieldName}.load_case.load_case_type`)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectDomain
          selectedElement={selectedElement}
          name={`${fieldName}.domain_guid`}
          data-cy={`select-domain-${index}`}
          domains={allDomains}
        />
      </Grid>
      <Grid item md={6}>
        <LoadCaseSelect
          onSelect={({ identifier, category, loadCaseType }) => {
            setValue(`${fieldName}.load_case.identifier`, identifier)
            setValue(`${fieldName}.load_case.category`, category)
            setValue(`${fieldName}.load_case.load_case_type`, loadCaseType)
          }}
          value={`${category}+${loadCaseType}`}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl label="Kantenlänge">
          <MuiTextField
            value={numeral(domain?.length).format('0.00')}
            disabled
            type="string"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <RelativePositionField
          length={domain?.length || 0}
          label="Startposition"
          name={`${fieldName}.relative_start`}
          disabled={disabled}
          data-cy={`input-load-start-${index}`}
        />
      </Grid>
      <Grid item xs={6}>
        <RelativePositionField
          length={domain?.length || 0}
          label="Endposition"
          name={`${fieldName}.relative_end`}
          disabled={disabled}
          data-cy={`input-load-end-${index}`}
        />
      </Grid>
      <Grid item xs={6}>
        <LoadForceField
          label="Anfangsgröße"
          name={`${fieldName}.start`}
          domainFieldName={`${fieldName}.domain`}
          disabled={disabled}
          data-cy={`input-force-start-${index}`}
        />
      </Grid>
      <Grid item xs={6}>
        <LoadForceField
          label="Endgröße"
          name={`${fieldName}.end`}
          domainFieldName={`${fieldName}.domain`}
          disabled={disabled}
          data-cy={`input-force-end-${index}`}
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          label="Kommentar"
          placeholder="Kommentar"
          rows={2}
          name={`${fieldName}.comment`}
          multiline
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default memo(LineLoadFormFields)
