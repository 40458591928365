import { getRequest, postRequest, retryUntilSuccessOrFailure } from '../utils'

export const getMemberCheckSettings: RequestTypeVariable = {
  getKey: id => ['getMemberCheckSettings', id],
  request: async (id: string): Promise<SettingsOnMember[]> => {
    const { data } = await getRequest<SettingsOnMember[]>({
      url: `/projects/${id}/member-check-settings`,
    })

    return data as SettingsOnMember[]
  },
}

export const getAnchorChecks: RequestTypeVariable = {
  getKey: id => ['getAnchorChecks', id],
  request: async (id: string): Promise<AnchorCheckOnSupport[]> => {
    const { data } = await getRequest<AnchorCheckOnSupport[]>({
      url: `/projects/${id}/decisive-anchor-checks`,
    })

    return data as AnchorCheckOnSupport[]
  },
}

export const getChecksForCrossSections = {
  getKey: (id: string) => ['getChecksForCrossSections', id],
  request: async (
    projectId: string,
    elementGuid: string,
    settings: SettingsOnMember | null,
  ): Promise<CrossSectionSuggestion[]> => {
    const dataForRequest = {
      element_guid: elementGuid,
      settings: settings,
    }

    const { data } = await postRequest<CrossSectionSuggestion[]>({
      url: `/projects/${projectId}/calc-checks`,
      data: dataForRequest,
    })

    return data as CrossSectionSuggestion[]
  },
}

export const getCalcChecksForElement = {
  getKey: (id: string) => ['getCalcChecksForElement', id],
  request: async (
    projectId: string,
    elementGuid: string,
    settings: SettingsOnMember,
    crossSection: CrossSection,
  ): Promise<CrossSectionSuggestion> => {
    const dataForRequest = {
      element_guid: elementGuid,
      settings: settings,
      crossSection: crossSection,
    }

    const { data } = await postRequest<CrossSectionSuggestion>({
      url: `/projects/${projectId}/calc-element-checks`,
      data: dataForRequest,
    })

    return data as CrossSectionSuggestion
  },
}

export const getDefaultCheckSettingsForElement = {
  getKey: (id: string) => ['getDefaultCheckSettingsForElement', id],
  request: async (
    projectId: string,
    elementGuid: string,
    newCS: CrossSection,
  ): Promise<GetDefaultCheckSettingsForElementResponse> => {
    const dataForRequest = {
      new_cs: newCS,
    }

    const { data } = await postRequest<GetDefaultCheckSettingsForElementResponse>({
      url: `/projects/${projectId}/updated-check-settings/${elementGuid}`,
      data: dataForRequest,
    })

    return data as GetDefaultCheckSettingsForElementResponse
  },
}

export const getCalcMemberChecks = {
  getKey: (id: string) => ['getCalcMemberChecks', id],
  request: async (projectId: string): Promise<void> => {
    await getRequest<void>({
      url: `/projects/${projectId}/calc-member-checks`,
    })
  },
}

export const postStartCalcMemberChecks: RequestTypeVariable = {
  getKey: (projectId: string) => ['postStartCalcMemberChecks', projectId],
  request: async (id: string): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/calc-member-checks/compute`,
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}

export const postStartCSOptimization: RequestTypeVariable = {
  getKey: (projectId: string, elementGuid: string) => [
    'postStartCSOptimization',
    projectId,
    elementGuid,
  ],
  request: async (id: string, elementGuid: string): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/cs-optimization/${elementGuid}/compute`,
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}
