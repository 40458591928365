import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import Decimal from 'decimal.js'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { DeleteButton, SaveButton } from '@ui/actions'
import { ErrorField, Form } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useResultsInvalidation, useSelectionMode } from '@editorHooks'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { getElementCrossSectionAssignment, getModel, getVerticalTransmissionGraph } from '@queries'
import { createRip, deleteRip } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { WALL_RIP_SELECTION } from '../../../WallRipForm/constants'
import FormFields from './components/FormFields'
import { createRipSchema } from './schema'

const ABS_POSITION_NUM_DECIMALS = 2

interface Props {
  positionGuid: string
}

export const WallRipForm = ({ positionGuid }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const client = useQueryClient()
  const invalidateResults = useResultsInvalidation()

  const {
    actions: { resetElements },
  } = useStructuralPlanningQueryParams()
  const { setSelectionMode } = useSelectionMode()

  const removeRip = useModelStore(state => state.removeRip)

  const rips = useModelStore(state => state.model.rips)

  const rip = useMemo(() => find(rips, { position_guid: positionGuid }), [rips, positionGuid])

  const { walls } = useModelStore(state => state.model)
  const wall = useMemo(() => {
    if (rip?.wall_guid) {
      return find(walls, { guid: rip?.wall_guid })
    }
  }, [rip, walls]) as ShapeObject

  const wallLength = useMemo(() => wall?.shape.points[0].distanceTo(wall?.shape.points[1]), [wall])

  const { schema, defaultValues } = useMemo(() => {
    const absolutePosition = rip ? wall.shape.points[0].distanceTo(rip?.start) : 0
    const absolutePositionRounded = absolutePosition.toFixed(ABS_POSITION_NUM_DECIMALS)
    const wallLengthRounded = wallLength.toFixed(ABS_POSITION_NUM_DECIMALS)
    const relativePosition = new Decimal(absolutePositionRounded).div(
      new Decimal(wallLengthRounded),
    )
    const relativePositionAsString = relativePosition.toString()

    const schema = createRipSchema(positionGuid, rip?.wall_guid as string)

    const defaultValues = {
      ...schema.getDefault(),
      relative_position: relativePositionAsString,
    }

    return { schema, defaultValues }
  }, [wall, rip])

  const { mutateAsync, isLoading } = useMutation(
    async (ripData: WallRipRequest) => {
      if (!rip?.is_local) {
        await deleteRip.request(projectId, ripData.guid)
      }
      return await createRip.request(projectId, ripData)
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Wandrippe erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Wandrippe'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (ripGuid: string) => deleteRip.request(projectId, ripGuid),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Wandrippe erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Wandrippe'), {
          variant: 'error',
        })
      },
    },
  )

  const onSubmit = (data: WallRipRequest) => {
    mutateAsync(data)
    resetElements()
    setSelectionMode({
      key: WALL_RIP_SELECTION,
      message: 'Wählen Sie eine Wand zum einzeichnen von Wandrippen aus',
    })
  }

  const onDelete = () => {
    if (rip?.is_local) {
      removeRip(rip.position_guid)
    } else {
      handleDelete(rip?.position_guid as string)
      resetElements()
      setSelectionMode({
        key: WALL_RIP_SELECTION,
        message: 'Wählen Sie eine Wand zum einzeichnen von Wandrippen aus',
      })
    }
  }

  return (
    <>
      <Form
        key={positionGuid}
        onSubmit={onSubmit}
        validationSchema={schema}
        defaultValues={defaultValues}
        validationContext={{ wall, rips }}
      >
        <Stack direction="column" spacing={2}>
          <FormFields guid={positionGuid} length={wallLength} />

          <ErrorField name="isIntersecting" />
          <ErrorField name="isOverlapping" />

          <Stack direction="row" justifyContent="end" spacing={1}>
            <SaveButton type="submit" fullWidth loading={isLoading}>
              Speichern
            </SaveButton>

            <DeleteButton
              size="small"
              fullWidth
              data-cy="btn-delete-rip"
              loading={isDeleting}
              onClick={onDelete}
            >
              Löschen
            </DeleteButton>
          </Stack>
        </Stack>
      </Form>
    </>
  )
}
