import { ReactElement, ReactNode } from 'react'
import { TabContext } from '@mui/lab'
import { Box } from '@ui/structure'

interface Props {
  initialTabValue: string
  children: ReactNode
}

const WallFormBase = ({ initialTabValue, children }: Props): ReactElement => {
  return (
    <Box>
      <TabContext value={initialTabValue}>{children}</TabContext>
    </Box>
  )
}

export default WallFormBase
