import { ReactElement, useEffect, useMemo, useState } from 'react'
import { getModelBoundingBox } from '@editorUtils'
import { Vector3 } from 'three'
import { useModelStore } from '@editorStores'

interface Props {
  dimension: Dimensions
}

const TRANSPOSITION = -1

const DimensionsIndicator = ({ dimension }: Props): ReactElement | null => {
  const model = useModelStore(state => state.model)

  const [values, setValues] = useState<{
    length: number
    direction: Vector3
  } | null>(null)
  const transposition = new Vector3(TRANSPOSITION, TRANSPOSITION, 0)
  const color = '#000000'

  const bbox = useMemo(() => getModelBoundingBox(model), [model])

  useEffect(() => {
    const { x, y, z } = bbox.getSize(new Vector3())

    if (dimension === 'length') {
      setValues({
        length: x,
        direction: new Vector3(1),
      })
    }

    if (dimension === 'length-x') {
      setValues({
        length: x,
        direction: new Vector3(1),
      })
    }

    if (dimension === 'depth') {
      setValues({
        length: y,
        direction: new Vector3(0, 1),
      })
    }

    if (dimension === 'length-y') {
      setValues({
        length: y,
        direction: new Vector3(0, 1),
      })
    }

    if (dimension === 'height') {
      setValues({
        length: z,
        direction: new Vector3(0, 0, 1),
      })
    }

    if (dimension === 'length-z') {
      setValues({
        length: z,
        direction: new Vector3(0, 0, 1),
      })
    }
  }, [bbox])

  if (!values) return null

  const { direction, length } = values

  return (
    <group key={values.length}>
      <arrowHelper args={[direction, transposition, length, color, 0.2, 0.1]} />
      <arrowHelper
        args={[
          direction.clone().negate(),
          transposition.clone().addScaledVector(direction, length),
          length,
          color,
          0.2,
          0.1,
        ]}
      />
    </group>
  )
}

export default DimensionsIndicator
