import { ReactElement, useState } from 'react'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Toolbar,
  Link,
  IconButton,
  Stack,
  Fade,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Theme,
  Divider,
  Typography,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import logoIcon from '@img/logo-icon.png'
import logo from '@img/modugen-logo.png'
import { Box } from '@ui/structure'
import { useAppStore, useUIStore } from '@stores'
import { appConfig } from 'src/constants'
import { Logo } from './styles'

const Navbar = (): ReactElement => {
  const { logout, user } = useAuth0()

  const recordSession = useAppStore(state => state.recordSession)
  const setRecordSession = useAppStore(state => state.setRecordSession)

  const showSnow = useAppStore(state => state.showSnow)
  const setShowSnow = useAppStore(state => state.setShowSnow)

  const showStats = useAppStore(state => state.showStats)
  const setShowStats = useAppStore(state => state.setShowStats)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const setPortalRootLeft = useUIStore(state => state.setPortalRootLeft)
  const setPortalRootCenter = useUIStore(state => state.setPortalRootCenter)
  const setPortalRootRight = useUIStore(state => state.setPortalRootRight)
  const reducedLogo = useUIStore(state => state.reducedLogo)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin + '/authorize' } })
  }

  return (
    <AppBar sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: 'flex', gap: 2 }}>
        <Link
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component={RouterLink}
          to="/"
        >
          <Logo reduced={reducedLogo} src={reducedLogo ? logoIcon : logo} />
        </Link>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <Stack direction="row" ref={ref => setPortalRootLeft(ref as HTMLDivElement)} />
          <Stack
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }}
            direction="row"
            ref={ref => setPortalRootCenter(ref as HTMLDivElement)}
          />
          <Stack direction="row" ref={ref => setPortalRootRight(ref as HTMLDivElement)} />
        </Stack>
        <Stack direction="row">
          <IconButton
            sx={{ color: 'white' }}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleOpen}
            TransitionComponent={Fade}
          >
            {user && (
              <>
                <Box mx={2} mb={2} mt={1}>
                  <Tooltip title={user.sub}>
                    <Typography>{user.email}</Typography>
                  </Tooltip>
                </Box>

                <Divider />
              </>
            )}

            <MenuItem>
              <FormGroup>
                <Tooltip title="Aufnehmen und Übertragen der Sitzung">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={recordSession}
                        onChange={e => setRecordSession(e.target.checked)}
                      />
                    }
                    label="Aufnehmen"
                  />
                </Tooltip>
              </FormGroup>
            </MenuItem>
            {appConfig.enableSnowEasterEgg && (
              <MenuItem>
                <FormGroup>
                  <Tooltip title="Schnee in der Scene anzeigen">
                    <FormControlLabel
                      control={
                        <Switch checked={showSnow} onChange={e => setShowSnow(e.target.checked)} />
                      }
                      label="Schnee"
                    />
                  </Tooltip>
                </FormGroup>
              </MenuItem>
            )}
            <MenuItem>
              <FormGroup>
                <Tooltip title="Scene Stats anzeigen (debugging)">
                  <FormControlLabel
                    control={
                      <Switch checked={showStats} onChange={e => setShowStats(e.target.checked)} />
                    }
                    label="Scene Stats"
                  />
                </Tooltip>
              </FormGroup>
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
