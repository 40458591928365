import { ReactElement } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useResultsInvalidation } from '@editorHooks'
import { getGlobalFireSettings } from '@queries'
import { saveGlobalFireSettings } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import GlobalFireSettings from './components/GlobalFireSettings'

const GlobalFireSettingsTab = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const invalidateResults = useResultsInvalidation()

  const { isFetching: isLoadingGlobalFireSettings, data: globalFireSettingsData } = useQuery(
    getGlobalFireSettings.getKey(projectId),
    () => getGlobalFireSettings.request(projectId),
    {
      onError: () =>
        enqueueSnackbar('Fehler beim Laden der globalen Heißbemessungs-Einstellungen', {
          variant: 'error',
        }),
      refetchOnMount: 'always',
    },
  ) as { isFetching: boolean; data?: GlobalFireSettings }

  const { isLoading: isSavingGlobalFireSettings, mutate: mutateGlobalFireSettings } = useMutation(
    (data: GlobalFireSettings) => saveGlobalFireSettings.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        enqueueSnackbar('Globale Heißbemessungs-Einstellungen erfolgreich gespeichert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(
            error,
            'Fehler beim Speichern der globalen Heißbemessungs-Einstellungen',
          ),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  return (
    <GlobalFireSettings
      isLoading={isLoadingGlobalFireSettings}
      isSaving={isSavingGlobalFireSettings}
      data={globalFireSettingsData}
      onSave={data => mutateGlobalFireSettings(data)}
    />
  )
}

export default GlobalFireSettingsTab
