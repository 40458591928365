import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'
import { Checkbox, Label, Select } from '@ui/forms'
import { Box } from '@ui/structure'
import { getGlobalFireSettings } from '@queries'

interface Props {
  disableInput?: boolean
}

const MemberHotDimensioningSettingsFormFields = ({ disableInput }: Props): ReactElement | null => {
  const { projectId }: { projectId?: string } = useParams()

  const { watch, setValue } = useFormContext()
  const isHotDimensioningEnabled = watch('hot_dimensioning_settings.make_hot_dimensioning_checks')

  const { data: globalFireSettings } = useQuery(getGlobalFireSettings.getKey(projectId), () =>
    getGlobalFireSettings.request(projectId),
  )

  return (
    <Box
      p={1}
      border={1}
      borderColor="grey.200"
      borderRadius={1}
      data-cy="member-hot-dimensioning-settings-fields-container"
    >
      <Typography variant="h6">Heißbemessung</Typography>

      <Stack direction="column" spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label>Heißbemessung durchführen</Label>
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              name="hot_dimensioning_settings.make_hot_dimensioning_checks"
              onChange={e => {
                if (!e) {
                  setValue('hot_dimensioning_settings.fire_duration', null)
                  setValue('hot_dimensioning_settings.fire_exposed_sides', null)
                } else if (e && globalFireSettings) {
                  setValue(
                    'hot_dimensioning_settings.fire_duration',
                    globalFireSettings.hot_dimensioning_settings.fire_duration,
                  )
                  setValue('hot_dimensioning_settings.fire_exposed_sides', {
                    top: true,
                    bottom: true,
                    left: true,
                    right: true,
                  })
                }
              }}
              disabled={disableInput}
              tooltip={
                (disableInput && {
                  text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                }) ||
                undefined
              }
            />
          </Grid>

          {isHotDimensioningEnabled && (
            <>
              <Grid item xs={12}>
                <Select
                  label="Feuerwiderstandsklasse"
                  name={`hot_dimensioning_settings.fire_duration`}
                  options={[
                    { value: 0, label: 'F0' },
                    { value: 1800, label: 'F30' },
                    { value: 3600, label: 'F60' },
                    { value: 5400, label: 'F90' },
                    { value: 7200, label: 'F120' },
                  ]}
                  disabled={disableInput}
                  tooltip={
                    (disableInput && {
                      text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                    }) ||
                    undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Brandbeanspruchte Seiten</Label>
              </Grid>
              <Grid item xs={3}>
                <Label>oben</Label>
              </Grid>
              <Grid item xs={3}>
                <Label>unten</Label>
              </Grid>
              <Grid item xs={3}>
                <Label>links</Label>
              </Grid>
              <Grid item xs={3}>
                <Label>rechts</Label>
              </Grid>
              <Grid item xs={3}>
                <Checkbox
                  name="hot_dimensioning_settings.fire_exposed_sides.top"
                  disabled={disableInput}
                  tooltip={
                    (disableInput && {
                      text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                    }) ||
                    undefined
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Checkbox
                  name="hot_dimensioning_settings.fire_exposed_sides.bottom"
                  disabled={disableInput}
                  tooltip={
                    (disableInput && {
                      text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                    }) ||
                    undefined
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Checkbox
                  name="hot_dimensioning_settings.fire_exposed_sides.left"
                  disabled={disableInput}
                  tooltip={
                    (disableInput && {
                      text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                    }) ||
                    undefined
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Checkbox
                  name="hot_dimensioning_settings.fire_exposed_sides.right"
                  disabled={disableInput}
                  tooltip={
                    (disableInput && {
                      text: 'Änderungen können nur in der Lokalen Ansicht vorgenommen werden',
                    }) ||
                    undefined
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </Box>
  )
}

export default MemberHotDimensioningSettingsFormFields
