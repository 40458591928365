import { ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import logo from '@img/modugen-full-green-logo.png'
import { Box, IconCard } from '@ui/structure'
import { useConfigStore } from '@stores'
import { cardContents } from './constants'
import { Logo } from './styles'

const Login = (): ReactElement => {
  const { loginWithRedirect, isLoading } = useAuth0()
  const theme = useTheme()
  const { auth0Audience, auth0Scopes } = useConfigStore()

  return (
    <Grid container sx={{ minHeight: '100vh' }}>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12} sx={({ spacing }) => ({ padding: spacing(3) })}>
            <Logo src={logo} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={({ spacing }) => ({
              padding: spacing(3),
              textAlign: 'center',
            })}
          >
            <Typography variant="h2">Modugen Statik</Typography>
            <Typography color={theme.palette.grey[700]} variant="h4">
              Der intelligente Planungsassistent für den Holzbau
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10} justifyContent="center">
            <Grid
              container
              spacing={3}
              sx={({ spacing }) => ({
                paddingTop: spacing(5),
              })}
            >
              {cardContents.map(({ iconSrc, title, text }, index) => (
                <Grid item xs={4} key={title}>
                  <IconCard iconSrc={iconSrc} title={`${index + 1}. ${title}`} text={text} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} sx={({ palette }) => ({ background: palette.grey[800] })}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Stack
            py={3}
            px={2}
            spacing={2}
            sx={({ palette, shape }) => ({
              background: palette.grey[700],
              borderRadius: shape.borderRadius,
            })}
          >
            <LoadingButton
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    audience: auth0Audience,
                    scope: auth0Scopes,
                  },
                })
              }
              loading={isLoading}
              variant="contained"
            >
              Login
            </LoadingButton>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Login
