import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { Stack } from '@mui/material'
import { SelectLoadable, Select, CentimeterField } from '@ui/forms'
import { getSteelCSShapes } from '@queries'
import { materialQueryFromMaterialType } from 'src/components/manager/element_cs/ElementCSFormFields/utils'
import { materialTypeOptions, usageClassOptions } from 'src/components/manager/element_cs/constants'

interface Props {
  elementType: ElementTypes
  isDisabled: boolean
}

const renameSWxxToCxxIfApplicable = (identifier: string): string => {
  // MDG 2214 - https://app.clickup.com/t/2590559/MDG-2214
  if (identifier.startsWith('SW')) {
    return identifier.replace('SW', 'C')
  } else {
    return identifier
  }
}

const FormFields = ({ elementType, isDisabled }: Props): ReactElement => {
  const materialType = useWatch({ name: 'crossSection.materialType' })

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <Select
          label="Materialtyp"
          name="crossSection.materialType"
          options={materialTypeOptions}
          data-cy="crosssection-material-type"
          disabled={isDisabled}
        />
        <SelectLoadable
          label="Material"
          name="crossSection.material"
          placeholder="Material auswählen"
          query={materialQueryFromMaterialType(materialType)}
          renderOptions={(data: Material[] = []) =>
            data.map(material => ({
              value: material,
              label: renameSWxxToCxxIfApplicable(material.identifier),
            }))
          }
          data-cy="crosssection-material"
          disabled={isDisabled}
        />
      </Stack>

      {(materialType === 'softwoodMaterial' || materialType === 'glulamMaterial') && (
        <>
          <Stack direction="row" spacing={1}>
            <CentimeterField
              tooltip={elementType === 'lintels' ? { text: 'Bestimmt Schichtdicke' } : undefined}
              label="QS-Breite"
              name="crossSection.shape.width"
              data-cy="crosssection-width-input"
              disabled={isDisabled}
            />
            <CentimeterField
              tooltip={elementType === 'rips' ? { text: 'Bestimmt Schichtdicke' } : undefined}
              label="QS-Höhe"
              name="crossSection.shape.height"
              data-cy="crosssection-height-input"
              disabled={isDisabled}
            />
            <Select
              label="Nutzungsklasse"
              name="crossSection.usage_class"
              options={usageClassOptions}
              data-cy="crosssection-usage-class-input"
              disabled={isDisabled}
            />
          </Stack>
        </>
      )}
      {materialType === 'steelMaterial' && (
        <Stack direction="row" spacing={1}>
          <SelectLoadable
            label="Stahl Querschnitt"
            name="crossSection.steelShape"
            placeholder="Querschnitt auswählen"
            query={getSteelCSShapes}
            renderOptions={(data: SteelCSShape[] = []) =>
              data.map(csShape => ({ value: csShape, label: csShape.profile }))
            }
            data-cy="crosssection-steel-shape-select"
            disabled={isDisabled}
          />
        </Stack>
      )}
    </Stack>
  )
}

export { FormFields, renameSWxxToCxxIfApplicable as remapMaterialIdentifier }
