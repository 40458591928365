import { ReactElement } from 'react'
import { ToggleButton } from '@ui/actions'
import { Wall } from '@ui/icons'

interface Props {
  onClick: () => void
  value: boolean
  hint?: string
  'data-cy'?: string
}

const WallSelect = ({ onClick, value, hint = '', 'data-cy': dataCy }: Props): ReactElement => {
  return (
    <ToggleButton
      hint={hint}
      value="snap"
      size="small"
      onClick={onClick}
      selected={value}
      inverted
      Icon={Wall}
      data-cy={dataCy}
    />
  )
}

export default WallSelect
