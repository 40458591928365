import { ReactElement } from 'react'
import BorderInner from '@mui/icons-material/BorderInner'
import { Stack, Switch, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ToggleButtonDropDown } from '@ui/actions'
import { useControlStore } from '@editorStores'

const DrawControls = (): ReactElement | null => {
  const snappingFeaturesEnabled = useControlStore(state => state.snappingFeaturesEnabled)

  const controlStore = useControlStore()
  const tapelineStore = useTapelineStore()

  const enableXYFeaturesInOrthographic = useCameraStore(
    state => state.enableXYFeaturesInOrthographic,
  )

  const snapToCornersAndEdges =
    controlStore.snapToCornersAndEdges && tapelineStore.snapToCornersAndEdges

  const snapOrthogonal = controlStore.snapOrthogonal && tapelineStore.snapOrthogonal
  const snapToTapelineCenters = controlStore.snapToTapelineCenters && tapelineStore.snapToCenters

  return (
    <ToggleButtonDropDown Icon={BorderInner} iconSize="small" extendTo="right">
      {snappingFeaturesEnabled.snapToCornersAndEdges && (
        <Stack direction="row" alignItems="center">
          <Switch
            disabled={!enableXYFeaturesInOrthographic}
            checked={snapToCornersAndEdges}
            onChange={event => {
              controlStore.setSnapToCornersAndEdges(event.target.checked)
              tapelineStore.setSnapToCornersAndEdges(event.target.checked)
            }}
            size="small"
          />
          <Typography sx={{ p: 1 }} noWrap>
            an Elementen ausrichten
          </Typography>
        </Stack>
      )}

      {snappingFeaturesEnabled.snapOrthogonal && (
        <Stack direction="row" alignItems="center">
          <Switch
            disabled={!enableXYFeaturesInOrthographic}
            checked={snapOrthogonal}
            onChange={event => {
              controlStore.setSnapOrthogonal(event.target.checked)
              tapelineStore.setSnapOrthogonal(event.target.checked)
            }}
            size="small"
          />
          <Typography sx={{ p: 1 }} noWrap>
            orthogonal an Elementen ausrichten
          </Typography>
        </Stack>
      )}

      {snappingFeaturesEnabled.snapToAngle && (
        <Stack direction="row" alignItems="center">
          <Switch
            checked={controlStore.snapToAngles && tapelineStore.snapToAngles}
            onChange={event => {
              controlStore.setSnapToAngles(event.target.checked)
              tapelineStore.setSnapToAngles(event.target.checked)
            }}
            size="small"
          />
          <Typography sx={{ p: 1 }} noWrap>
            an Winkel ausrichten
          </Typography>
        </Stack>
      )}
      {snappingFeaturesEnabled.snapToTapelineCenters && (
        <Stack direction="row" alignItems="center">
          <Switch
            checked={snapToTapelineCenters}
            onChange={event => {
              controlStore.setSnapToTapelineCenters(event.target.checked)
              tapelineStore.setSnapToCenters(event.target.checked)
            }}
            size="small"
          />
          <Typography sx={{ p: 1 }} noWrap>
            an Mittelpunkten von Maßlinien ausrichten
          </Typography>
        </Stack>
      )}
    </ToggleButtonDropDown>
  )
}

export default DrawControls
