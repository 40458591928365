import { ReactElement, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useModelStore } from '@editorStores'
import { downLoadFile } from '@utils'
import { getMultiStoreyExportDocument } from '@mutations'
import ExportMultiSelectItem from '../ExportMultiSelectItem'

interface Props {
  filename: string
  type: BlobType
  path: string
  params: Record<string, string | number | boolean>
  extension?: string
  'data-cy'?: string
}

const storeyToLabel = (storey: string) => {
  if (storey === '0') return 'EG'
  else if (storey === 'ROOF') return 'Dach'
  else if (storey === 'Dach') return 'Dach'
  else return `${storey}. OG`
}

const CombinedPositionPlanItem = ({
  filename,
  type,
  path,
  params,
  extension,
}: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId } = useParams()

  const availableStoreys = useModelStore(state => state.availableStoreys)
  const project = useModelStore(state => state.project)

  const sortedStoreys = useMemo(() => sortBy([...availableStoreys]), [availableStoreys])

  const availableStoreysOptions = useMemo(
    () =>
      sortedStoreys.map(storey => ({
        label: storeyToLabel(storey),
        value: storey === 'Dach' ? 'ROOF' : storey,
      })),
    [sortedStoreys],
  )

  const [storeys, setStoreys] = useState(['ROOF'])

  const { isLoading, mutate } = useMutation(
    async (storeys: Array<string>) => {
      const data = await getMultiStoreyExportDocument.request(
        projectId as string,
        path,
        storeys,
        params,
      )
      const storeysFileNamePart = storeys
        .sort()
        .map(storey => storeyToLabel(storey))
        .join('+')
      const fullFileName = `${filename}-${storeysFileNamePart} - ${
        project.name || projectId
      }.${extension}`
      return {
        data,
        type: type,
        filename: fullFileName,
      }
    },

    {
      onSuccess: async ({ data, filename, type }) => {
        if (!data) {
          enqueueSnackbar('Ergebnisse noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(data, type as BlobType, filename)
      },
      onError: (e: Error) => {
        enqueueSnackbar(e?.message || 'Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  return (
    <ExportMultiSelectItem
      title="Dach + weitere"
      isLoading={isLoading}
      onClick={() => mutate(storeys)}
      options={availableStoreysOptions}
      selectedOptions={storeys}
      // @ts-ignore
      handleChange={event => {
        const {
          target: { value },
        } = event
        setStoreys(value)
      }}
    />
  )
}

export default CombinedPositionPlanItem
