import { postRequest } from '../utils/request'

export const cornerConnectionProposal = {
  getKey: (id: string) => ['cornerConnectionProposal', id],
  request: async (
    projectId: string,
    supportGuid: string,
    wallGuid: string,
  ): Promise<CornerConnectionProposal> => {
    const { data: result } = await postRequest<unknown>({
      url: `/projects/${projectId}/corner-connection`,
      data: {
        support_guid: supportGuid,
        wall_guid: wallGuid,
      },
    })

    return result as CornerConnectionProposal
  },
}

export const cornerConnectionProposalWithInterval = {
  getKey: (id: string) => ['cornerConnectionProposalWithInterval', id],
  request: async (
    projectId: string,
    supportGuid: string,
    wallGuid: string,
    left?: Interval,
    right?: Interval,
  ): Promise<CornerConnectionProposal> => {
    const { data: result } = await postRequest<unknown>({
      url: `/projects/${projectId}/corner-connection`,
      data: {
        support_guid: supportGuid,
        wall_guid: wallGuid,
        left_interval: left,
        right_interval: right,
      },
    })

    return result as CornerConnectionProposal
  },
}
