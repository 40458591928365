import { ReactNode } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { LoadingIndicator } from '@ui/feedback'
import { tabConfig } from './constants'

type Tabs = keyof typeof tabConfig

interface Props {
  isLoading?: boolean
  assemblyManager: ReactNode
  connectionManager: ReactNode
  componentManager: ReactNode
  activeTab?: Tabs
  setActiveTab: (tab: Tabs) => void
}

const ManagerTabs = ({
  isLoading = false,
  assemblyManager,
  connectionManager,
  componentManager,
  activeTab,
  setActiveTab,
}: Props) => {
  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <TabContext value={activeTab || tabConfig.assemblies.value}>
      <TabList onChange={(_, value) => setActiveTab(value)} centered>
        <Tab
          {...tabConfig.assemblies}
          sx={{ backgroundColor: '#fff', borderTopLeftRadius: 8 }}
          data-cy="tab-assemblies"
        />
        <Tab
          {...tabConfig.connections}
          sx={{ backgroundColor: '#fff' }}
          data-cy="tab-connections"
        />
        <Tab
          {...tabConfig.components}
          sx={{ backgroundColor: '#fff', borderTopRightRadius: 8 }}
          data-cy="tab-components"
        />
      </TabList>
      <TabPanel value={tabConfig.assemblies.value}>{assemblyManager}</TabPanel>
      <TabPanel value={tabConfig.connections.value}>{connectionManager}</TabPanel>
      <TabPanel value={tabConfig.components.value}>{componentManager}</TabPanel>
    </TabContext>
  )
}

export default ManagerTabs
