import { ReactElement } from 'react'
import { LoadingIndicator } from '@ui/feedback'
import useGlobalLoadsQueryParams from '../../../../hooks/useGlobalLoadsQueryParams'
import WindLoadsForm from '../WindLoadsForm'

interface Props {
  data?: {
    dimensions: BuildingDimensionsData
    baseParameters: WindLoadParameters
    outerWallAssignment: WindLoadWallAssignment
    roofAssignment: WindLoadRoofAssignment
    results: WindloadResults
  }
  isLoading: boolean
  isSaving: boolean
  onSave: (data: WindLoadsFormValues) => void

  onSaveManualWindLoads: (data: WindloadResults) => void
  isLoadingManualWindLoads: boolean
  isSavingManualWindLoads: boolean
  ignoredSlabs?: string[]
}

const WindLoads = ({
  data,
  isLoading,
  isSaving,
  onSave,
  onSaveManualWindLoads,
  isLoadingManualWindLoads,
  isSavingManualWindLoads,
  ignoredSlabs = [],
}: Props): ReactElement => {
  const {
    params: { activeRoofAssignmentTab },
    actions: { setActiveWindAngle, setBuildingDimension },
  } = useGlobalLoadsQueryParams()

  if (isLoading || !data) return <LoadingIndicator />

  return (
    <>
      <WindLoadsForm
        id="windloads-form"
        onSubmit={onSave}
        defaultValues={data}
        baseParameterActions={{
          onDimensionFocus: setBuildingDimension,
          onDimensionBlur: () => setBuildingDimension(null),
        }}
        wallAssignmentParamsActions={{
          activeWindAngle: activeRoofAssignmentTab,
          setWindAngle: setActiveWindAngle,
        }}
        isLoading={isLoading}
        isSaving={isSaving}
        onSubmitManualWindLoads={onSaveManualWindLoads}
        isLoadingManualWindLoads={isLoadingManualWindLoads}
        isSavingManualWindLoads={isSavingManualWindLoads}
        ignoredSlabs={ignoredSlabs}
      />
    </>
  )
}

export default WindLoads
