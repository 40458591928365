import { ReactElement } from 'react'
import { useDropzone, Accept, FileRejection } from 'react-dropzone'
import { useFormContext, useController } from 'react-hook-form'
import { UploadFile } from '@mui/icons-material'
import { Stack, Typography, Button } from '@mui/material'
import { Box } from '@ui/structure'
import FormControl from '../FormControl'

interface Props {
  name: string
  label?: string
  accept?: Accept
}

const UploadField = ({ accept = { 'application/json': [] }, name, label }: Props): ReactElement => {
  const { control, setError, setValue, resetField, watch } = useFormContext()
  const {
    fieldState: { error },
  } = useController({ name, control })
  const value = watch(name)

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    resetField(name)

    if (fileRejections?.length) {
      setError(name, { type: 'custom', message: 'Falscher Dateityp oder zu viele Dateien' })
      return
    }

    setValue(name, acceptedFiles[0])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles: 1,
    onDrop,
  })

  return (
    <FormControl label={label} error={error?.message}>
      <Box
        sx={{
          padding: 3,
          border: theme => `1px dashed ${theme.palette.grey[isDragActive ? 800 : 400]}`,
          borderRadius: 1,
          bgcolor: 'grey.100',
          cursor: 'pointer',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <Stack spacing={2} alignItems="center">
          <Box
            sx={{
              position: 'relative',
              textAlign: 'center',
              visibility: value ? 'hidden' : 'visible',
            }}
          >
            <UploadFile
              sx={{
                marginTop: 0,
                width: theme => theme.spacing(8),
                height: theme => theme.spacing(8),
                color: 'primary.main',
              }}
            />

            <Typography textAlign="center">
              Hier klicken oder Datei per <br /> drag & drop ablegen
            </Typography>
          </Box>

          {value && (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)',
                textAlign: 'center',
              }}
            >
              <Typography fontWeight="bold" fontStyle="italic" marginBottom={1}>
                {value.name}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={event => {
                  event.stopPropagation()
                  resetField(name)
                }}
              >
                Datei entfernen
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </FormControl>
  )
}

export default UploadField
