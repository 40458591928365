import { ReactElement, useMemo } from 'react'
import { Grid } from '@mui/material'
import { useModelStore, useResultsStore } from '@editorStores'
import { useElementTypes } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import { AssemblyAssignmentForm } from 'src/components/pages/Editor/pages/AssemblySelection/components'

interface Props {
  element: null | undefined | string
}

const elementTypesWithAssemblyForm = [
  'outer_walls',
  'inner_walls',
  'slabs',
  'roof_slabs',
  'vertical_slabs',
  'vertical_roof_slabs',
  'standard_rips',
  'slab_beams',
  'roof_slab_beams',
] as ElementTypes[]

const elementTypesWithCrossSectionForm = [
  'rips',
  'standard_rips',
  'lintels',
  'beams',
  'columns',
  'purlins',
] as ElementTypes[]

const getElementAssemblyType = (elementType: ElementTypes): ElementTypes => {
  if (elementType === 'vertical_roof_slabs') return 'roof_slabs'
  else if (elementType === 'vertical_slabs') return 'slabs'
  else return elementType
}

export const SystemForm = ({ element }: Props): ReactElement => {
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)
  const model = useModelStore(state => state.model)
  const standardRipChecks = useResultsStore(state => state.standardRipChecks)

  // Transformations
  const positionToElementWithAssembly = useMemo(() => {
    const positionToWall = [...rips, ...lintels].reduce((result, element) => {
      result[element.position_guid] = element.wall_guid
      return result
    }, {} as Record<string, string>)
    const slabBeamToSlab = [...model.vertical_slabs, ...model.vertical_roof_slabs].reduce(
      (result, element) => {
        result[element.beam.guid] = element.beam.slab_guid
        return result
      },
      {} as Record<string, string>,
    )
    const standardRipToWall =
      standardRipChecks &&
      standardRipChecks.reduce((result, check) => {
        result[check.standard_rip_member.guid] = check.wall_guid
        return result
      }, {} as Record<string, string>)
    return {
      ...positionToWall,
      ...slabBeamToSlab,
      ...standardRipToWall,
    }
  }, [model.vertical_slabs, model.vertical_roof_slabs, rips, lintels, standardRipChecks])

  const elementTypesWithoutStandardRips = useElementTypes()
  const elementTypesWithStandardRips = useMemo(() => {
    const reducedStandardRips = standardRipChecks
      ? standardRipChecks.reduce((collector, element) => {
          collector[element.standard_rip_member.guid] = 'standard_rips'
          return collector
        }, {} as Record<string, ElementTypes | undefined>)
      : {}
    return { ...elementTypesWithoutStandardRips, ...reducedStandardRips }
  }, [standardRipChecks, elementTypesWithoutStandardRips])
  const elementType = element && elementTypesWithStandardRips[element]

  const isElementWithAssemblyForm =
    element && elementTypesWithAssemblyForm.includes(elementType as ElementTypes)
  const elementForAssemblyForm = (element &&
    (element in positionToElementWithAssembly
      ? positionToElementWithAssembly[element]
      : element)) as string
  const elementWithAssemblyElementType = elementTypesWithStandardRips[
    elementForAssemblyForm
  ] as ElementTypes
  const elementTypeForAssemblyForm = getElementAssemblyType(elementWithAssemblyElementType)

  const isElementWithCrossSectionForm =
    element && elementTypesWithCrossSectionForm.includes(elementType as ElementTypes)
  const elementForCrossSectionForm = element as string

  return (
    <>
      {isElementWithCrossSectionForm && !isElementWithAssemblyForm && (
        <>
          <SingleElementCSForm
            selectedElement={elementForCrossSectionForm}
            elementType={elementType as ElementTypes}
            invalidateResults={false}
          />
        </>
      )}
      {isElementWithCrossSectionForm && isElementWithAssemblyForm && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <AssemblyAssignmentForm
                elementType={elementTypeForAssemblyForm as ElementTypes}
                selectedElements={[elementForAssemblyForm]}
                showSelectedElementsNumber={false}
              />
            </Grid>
            <Grid item xs={6}>
              <SingleElementCSForm
                selectedElement={elementForCrossSectionForm}
                elementType={elementType as ElementTypes}
                invalidateResults={false}
              />
            </Grid>
          </Grid>
        </>
      )}
      {!isElementWithCrossSectionForm && isElementWithAssemblyForm && (
        <>
          <AssemblyAssignmentForm
            elementType={elementTypeForAssemblyForm as ElementTypes}
            selectedElements={[elementForAssemblyForm]}
            showSelectedElementsNumber={false}
          />
        </>
      )}
    </>
  )
}
