import { ReactElement } from 'react'
import { Typography, Grid, InputAdornment } from '@mui/material'
import { TextField, UnitConvertedField } from '@ui/forms'

interface Props {
  fieldPath: string
  formEnabled: boolean
}

const SlabFormFields = ({ fieldPath, formEnabled }: Props): ReactElement => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="button">Hauptrichtung</Typography>
          <UnitConvertedField
            name={`${fieldPath}.horizontal_force_main_direction`}
            conversionFactor={1000}
            unitLabel="kN"
            label="Kraft"
            disabled={!formEnabled}
          />
          <TextField
            name={`${fieldPath}.eccentricity_main_direction`}
            label="Exzentrizität"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            disabled={!formEnabled}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="button">Nebenrichtung</Typography>

          <UnitConvertedField
            name={`${fieldPath}.horizontal_force_secondary_direction`}
            conversionFactor={1000}
            unitLabel="kN"
            label="Kraft"
            disabled={!formEnabled}
          />
          <TextField
            name={`${fieldPath}.eccentricity_secondary_direction`}
            label="Exzentrizität"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            disabled={!formEnabled}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SlabFormFields
