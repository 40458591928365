import {
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

const shortcuts3D = [
  { keys: '0, 1, ..., "r"', explanation: 'Stockwerke filtern' },
  { keys: 'd', explanation: 'Decken filtern' },
  { keys: 'o', explanation: '2D <-> 3D wechseln' },
  { keys: 'CTRL + (0, 1, ..., "r")', explanation: 'zu aktivem Stockwerk in 2D wechseln' },
]

const shortcuts2D = [
  { keys: '0, 1, ..., "r"', explanation: 'Stockwerke filtern' },
  { keys: 'd', explanation: 'Decken filtern' },
  { keys: 'o', explanation: '2D <-> 3D wechseln' },
  { keys: 'CTRL + (0, 1, ..., "r")', explanation: 'aktives Stockwerk wechseln' },
]

export const ShortcutInfo = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography align="center" variant="h6">
        Shortcut info
      </Typography>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" flexGrow={1} width="50%">
          <Typography align="center" variant="h6">
            3D Mode
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {shortcuts3D.map((shortcut, index) => (
                  <TableRow key={index}>
                    <TableCell>{shortcut.keys}</TableCell>
                    <TableCell>{shortcut.explanation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack direction="column" flexGrow={1} width="50%">
          <Typography align="center" variant="h6">
            2D Mode
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {shortcuts2D.map((shortcut, index) => (
                  <TableRow key={index}>
                    <TableCell>{shortcut.keys}</TableCell>
                    <TableCell>{shortcut.explanation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  )
}
