import { useEffect, useMemo } from 'react'
import { flatten } from 'lodash-es'
import { useQueryParams, ArrayParam } from 'use-query-params'
import { useElementSelectionStore } from '../stores/elementSelectionStore'

const useHighlightFromParams = (params: string[], condition = true) => {
  const setSelectedStandaloneIds = useElementSelectionStore(state => state.setSelectedStandaloneIds)
  const [values] = useQueryParams(
    params.reduce((acc, param) => ({ ...acc, [param]: ArrayParam }), {}),
  )

  // In general use-query-params will trigger lots of renderings and hence will
  // introduce loop below, as react does a shallow-comparision on strings we can
  // use this as a workaround to only trigger the useEffect below when the ids
  // really change and not only the array that useQueryParams provides,
  const idsStrings = useMemo(() => {
    const ids: string[] = flatten(Object.values(values))
    return JSON.stringify(ids)
  }, [values])

  useEffect(() => {
    if (!condition) return

    const ids: string[] = JSON.parse(idsStrings)

    if (ids?.length) setSelectedStandaloneIds([...ids] as string[])
    else setSelectedStandaloneIds([])

    return () => setSelectedStandaloneIds([])
  }, [idsStrings, condition, setSelectedStandaloneIds])
}

export default useHighlightFromParams
