import { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Form, mergeDefaults, TextField, UnitConvertedField } from '@ui/forms'
import { useResultsInvalidation } from '@editorHooks'
import {
  getSimplifiedSeismicModelConfig,
  getSimplifiedSeismicModelInputs,
  getSimplifiedSeismicResults,
  getWindLoadSettings,
} from '@queries'
import { postSimplifiedSeismicConfig } from '@mutations'
import useGlobalLoadsQueryParams from 'src/components/pages/Editor/pages/GlobalLoads/hooks/useGlobalLoadsQueryParams'
import { buildErrorMessage } from 'src/constants/errors'
import useStoreysForSeismic from '../../hooks/useStoreysForSeismic'
import SeismicBaseParameters from './components/BaseParameters'
import { seismicModelConfig } from './schema'

const SimplifiedSeismicConfigForm = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()

  const {
    actions: { setBuildingDimension },
  } = useGlobalLoadsQueryParams()

  const queryClient = useQueryClient()

  const storeys = useStoreysForSeismic()

  const invalidateResultsQueries = useResultsInvalidation()

  const { data: windLoadSettings } = useQuery({
    queryKey: getWindLoadSettings.getKey(projectId),
    queryFn: () => getWindLoadSettings.request(projectId),
    onError: () => enqueueSnackbar('Fehler beim Laden der Dimensionen'),
    refetchOnMount: true,
  })

  const { data: seismicModelConfigData } = useQuery({
    queryKey: getSimplifiedSeismicModelConfig.getKey(projectId),
    queryFn: () => getSimplifiedSeismicModelConfig.request(projectId),
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim laden der Erdbebenkonfiguration'), {
        variant: 'error',
      })
    },
    refetchOnMount: true,
  })

  const { isLoading: isSaving, mutate } = useMutation(
    (data: SeismicSettings) => postSimplifiedSeismicConfig.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResultsQueries(projectId as string)
        queryClient.invalidateQueries(getSimplifiedSeismicModelConfig.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicModelInputs.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicResults.getKey(projectId))
        enqueueSnackbar(
          'Erdbebenkonfiguration erfolgreich gespeichert und Modelldaten berechnet.',
          { variant: 'success' },
        )
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Erdbebenkonfiguration'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const defaultValues = useMemo(() => {
    const schemaDefault = seismicModelConfig.getDefault()
    if (windLoadSettings?.building_dimensions !== undefined) {
      const defaultsInitial = mergeDefaults(
        {
          building_dimensions: new Array(storeys.length).fill({
            x: windLoadSettings?.building_dimensions.depth,
            y: windLoadSettings?.building_dimensions.width,
          }),
        },
        schemaDefault,
      )
      const defaultCurrent = mergeDefaults(seismicModelConfigData, defaultsInitial)
      return defaultCurrent
    } else {
      return mergeDefaults(seismicModelConfigData, schemaDefault)
    }
  }, [seismicModelConfigData, windLoadSettings])

  const StoreyHeader = ({ storey }: { storey: number }): ReactElement => {
    const { watch } = useFormContext()

    const xDimension = watch(`building_dimensions.${storey}.x`)
    const yDimension = watch(`building_dimensions.${storey}.y`)
    const phiValue = watch(`phi_values.${storey}`)

    if (xDimension === undefined || yDimension === undefined || phiValue === undefined) {
      return (
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{storey === 0 ? 'EG' : `${storey}. Stock`}</Typography>
        </AccordionSummary>
      )
    }

    return (
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {storey === 0 ? 'EG' : `${storey}. Stock`} - (
          {Number(xDimension).toLocaleString('de-DE', { minimumFractionDigits: 2 })} |{' '}
          {Number(yDimension).toLocaleString('de-DE', { minimumFractionDigits: 2 })}) - φ:{phiValue}
        </Typography>
      </AccordionSummary>
    )
  }

  const ErrorRenderer = () => {
    const { formState } = useFormContext()
    // eslint-disable-next-line no-console
    console.log(formState.errors)

    return <></>
  }

  return (
    <Form
      validationSchema={seismicModelConfig}
      defaultValues={defaultValues}
      onSubmit={data => {
        mutate(data)
      }}
      enableReinitialize
    >
      <>
        <Grid container spacing={0} paddingTop={1} paddingBottom={1}>
          <Grid item paddingBottom={1} xs={12}>
            <Typography variant="h6" textAlign="center">
              Erdbebenkonfiguration
            </Typography>
            <Typography mb={1} color="red">
              Stelle sicher, dass dein Tragwerk (Aussteiffung und tragende Decken) fertig
              konfiguriert ist, da dies Einfluss auf die Ersatzkraftermittlung nimmt.
            </Typography>
            <ErrorRenderer />
          </Grid>

          <SeismicBaseParameters />

          {storeys.map(storey => (
            <Grid item xs={12} key={`accordion-${storey}`} spacing={0}>
              <Accordion>
                <StoreyHeader storey={storey} />
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Länge (X)"
                        name={`building_dimensions.${storey}.x`}
                        type="number"
                        InputProps={{
                          onFocus: () => setBuildingDimension('length-x'),
                          onBlur: () => setBuildingDimension(null),
                          endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        }}
                        key={`x-dimension-${storey}`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Breite (Y)"
                        name={`building_dimensions.${storey}.y`}
                        type="number"
                        InputProps={{
                          onFocus: () => setBuildingDimension('length-y'),
                          onBlur: () => setBuildingDimension(null),
                          endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        }}
                        key={`y-dimension-${storey}`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="φ"
                        name={`phi_values.${storey}`}
                        type="number"
                        key={`phi-value-${storey}`}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={4}>
            <UnitConvertedField
              label="Nutzlast"
              name="additional_slab_live_load"
              type="number"
              unitLabel="kN/m^2"
            />
          </Grid>

          <Grid item xs={4}>
            <UnitConvertedField
              label="Schneelast"
              name="additional_roof_snow_load"
              type="number"
              unitLabel="kN/m^2"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type="number"
              label={
                <span>
                  ψ<sub>2</sub> Schnee
                </span>
              }
              name="psi_2_snow"
            />
          </Grid>

          <Grid item xs={8}>
            <UnitConvertedField
              label="Trennwandzuschlag (Erdbeben)"
              tooltip={{
                text: 'Zusätzliche Trennwandlast, für die Massenermittlung Erdbeben. Wird nicht in der Tragwerksmessung verwendet. Der Trennwandzuschlag aus der Stockwerksdefinition wird hier nicht verwendet. Die Wichten aller Wände werden bereits für die Massenermittlung berücksichtigt, entsprechend hiermit vorsichtig umgehen.',
              }}
              name="additional_separating_wall_load"
              type="number"
              unitLabel={
                <span>
                  kN/m<sup>2</sup>
                </span>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <SaveButton
              loading={isSaving}
              type="submit"
              data-cy="save-simplified-seismic-config-btn"
            >
              Speichern und Modellwerte ermitteln
            </SaveButton>
          </Grid>
        </Grid>
      </>
    </Form>
  )
}

export default SimplifiedSeismicConfigForm
