import { ReactElement } from 'react'
import { Form } from '@ui/forms'
import PlateLayerFormFields from './PlateLayerFormFields'
import { plateLayerSchema } from './schema'
import { layerToFormState } from './utils'

const TimberFrameLayerForm = ({ id, onSubmit, defaultValues }: LayerFormProps): ReactElement => {
  return (
    <Form
      id={id}
      validationSchema={plateLayerSchema}
      defaultValues={layerToFormState(defaultValues as PlateLayer) || plateLayerSchema.getDefault()}
      onSubmit={values => onSubmit(values.layer)}
    >
      <PlateLayerFormFields />
    </Form>
  )
}

export default TimberFrameLayerForm
