import { ReactElement, useMemo } from 'react'
import { Box } from '@mui/material'
import { useResultsStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'
import FormBase from './components/FormBase'

interface Props {
  anchorGuid: string
  wallGuid: string
  initialData: CornerConnectionProposal

  biAnchorGuid?: string
  biWallGuid?: string
  biInitialData?: CornerConnectionProposal

  fetchWithInterval: (biWall: boolean) => void
  isLoadingWithInterval: boolean

  onExpand: () => void
  onExpandBi: () => void

  disableAccept?: boolean
  onAccept: (formula: string) => void
  onAcceptBi: (formula: string) => void
}

const CornerConnectionProposalForm = ({
  anchorGuid,
  wallGuid,

  initialData,

  biAnchorGuid,
  biWallGuid,
  biInitialData,

  onExpand,
  onExpandBi,

  fetchWithInterval,
  isLoadingWithInterval,

  disableAccept = false,
  onAccept,
  onAcceptBi,
}: Props): ReactElement => {
  const guidToElement = useGuidToElement()
  const wallLength = useMemo(() => {
    const wall = guidToElement[wallGuid] as ShapeObject
    const wallStart = wall.shape.points[0]
    const wallEnd = wall.shape.points[1]
    return wallStart.distanceTo(wallEnd)
  }, [wallGuid, guidToElement])

  const biWallLength = useMemo(() => {
    if (!biWallGuid) return

    const wall = guidToElement[biWallGuid] as ShapeObject
    const wallStart = wall.shape.points[0]
    const wallEnd = wall.shape.points[1]
    return wallStart.distanceTo(wallEnd)
  }, [biWallGuid, guidToElement])

  const collectionLeft = useMemo(() => {
    if (!initialData.left_interval) return

    return wallLength * (initialData.left_interval.upper - initialData.left_interval.lower)
  }, [wallLength, initialData])

  const collectionRight = useMemo(() => {
    if (!initialData.right_interval) return

    return wallLength * (initialData.right_interval.upper - initialData.right_interval.lower)
  }, [wallLength, initialData])

  const biCollectionLeft = useMemo(() => {
    if (!biInitialData?.left_interval || !biWallLength) return

    return biWallLength * (biInitialData.left_interval.upper - biInitialData.left_interval.lower)
  }, [biWallLength, biInitialData])

  const biCollectionRight = useMemo(() => {
    if (!biInitialData?.right_interval || !biWallLength) return

    return biWallLength * (biInitialData.right_interval.upper - biInitialData.right_interval.lower)
  }, [biWallLength, biInitialData])

  const selectedProposalAnchor = useResultsStore(state => state.selectedProposalAnchor)
  const setSelectedProposalAnchor = useResultsStore(state => state.setSelectedProposalAnchor)

  return (
    <Box component="div" m={2}>
      <FormBase
        expanded={selectedProposalAnchor === anchorGuid}
        onExpand={expanded => {
          if (expanded) {
            setSelectedProposalAnchor(anchorGuid)
            onExpand()
          } else setSelectedProposalAnchor(null)
        }}
        onFetchNew={() => fetchWithInterval(false)}
        wallGuid={wallGuid}
        anchorGuid={anchorGuid}
        collectionLeft={collectionLeft}
        collectionRight={collectionRight}
        wallLength={wallLength}
        supportPosition={initialData.support_position}
        isLoadingNew={isLoadingWithInterval}
        onAccept={onAccept}
        resultingForce={initialData.resulting_force}
        resultingFormula={initialData.formula}
        disableAccept={disableAccept}
      />
      {biAnchorGuid && biInitialData && biWallLength && biWallGuid && (
        <FormBase
          expanded={selectedProposalAnchor === biAnchorGuid}
          onExpand={expanded => {
            if (expanded) {
              setSelectedProposalAnchor(biAnchorGuid)
              onExpandBi()
            } else setSelectedProposalAnchor(null)
          }}
          onFetchNew={() => fetchWithInterval(true)}
          wallGuid={biWallGuid}
          anchorGuid={biAnchorGuid}
          collectionLeft={biCollectionLeft}
          collectionRight={biCollectionRight}
          wallLength={biWallLength}
          supportPosition={biInitialData.support_position}
          isLoadingNew={isLoadingWithInterval}
          onAccept={onAcceptBi}
          resultingForce={biInitialData.resulting_force}
          resultingFormula={biInitialData.formula}
          disableAccept={disableAccept}
          biDirection
        />
      )}
    </Box>
  )
}

export default CornerConnectionProposalForm
