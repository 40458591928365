import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const useFormFieldChange = (fieldName: string, callback: () => void) => {
  const { watch } = useFormContext()

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === fieldName && type === 'change') callback()
    })
    return () => subscription.unsubscribe()
  }, [callback, fieldName, watch])
}

export default useFormFieldChange
