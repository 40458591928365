import { ReactElement, useCallback, useMemo } from 'react'
import {
  createLineBasicMaterialMemoized,
  createMeshStandardMaterialMemoized,
  getShapeEdges,
  getShapeMatrixTransform,
  getShapeObject,
} from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { ThreeEvent } from '@react-three/fiber'
import { useSceneStore } from 'src/components/pages/Editor/stores/sceneStore'
import { extrudeMember } from './utils'

interface Props {
  data: PositionMember
  outlines?: boolean
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  isSelected?: boolean
  isHighlighted?: boolean
  visible?: boolean
}

const defaultPositionThickness = 0.15

const PositionMemberMeshElement = ({
  data,
  outlines = true,
  onClick,
  isSelected = false,
  isHighlighted = false,
  visible = true,
}: Props): ReactElement => {
  const theme = useTheme()

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[data.guid], [data.guid]),
  ) as ColorRepresentation | undefined

  const points = extrudeMember(data.member, defaultPositionThickness)

  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, [], matrixTransform),
    [points, matrixTransform],
  )

  const edges = useMemo(() => getShapeEdges(shapeObject, defaultPositionThickness), [shapeObject])

  const material = useMemo(
    () =>
      createMeshStandardMaterialMemoized({
        side: DoubleSide,
        color: isHighlighted
          ? theme.scenePalette.selection
          : mappedColor || theme.scenePalette.elements3d.member,
        transparent: true,
      }),
    [theme, isHighlighted, mappedColor],
  )

  const outlinesMaterial = useMemo(
    () =>
      createLineBasicMaterialMemoized({
        color: isSelected ? theme.scenePalette.highlightDark : theme.scenePalette.outlines,
      }),
    [theme, isSelected],
  )

  return (
    <BasicMesh
      name={data.guid}
      matrix={matrixTransform}
      matrixAutoUpdate={false}
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
      userData={{
        guid: data.guid,
        elementType: 'members',
        positionType: data.position_type,
      }}
      visible={visible}
      material={material}
    >
      <extrudeGeometry
        args={[
          shapeObject,
          {
            depth: defaultPositionThickness,
            bevelEnabled: false,
          },
        ]}
      />

      {outlines && (
        <lineSegments
          layers={sceneConfig.R3FNonSelectableObjectLayer}
          geometry={edges}
          renderOrder={100}
          visible={visible}
          material={outlinesMaterial}
        ></lineSegments>
      )}
    </BasicMesh>
  )
}

export default PositionMemberMeshElement
