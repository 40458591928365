import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { Link } from '@mui/material'

const BackLink = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <Link
      onClick={() => navigate('/')}
      sx={{
        // @ts-ignore
        fontSize: ({ typography }) => typography.body2,
        display: 'flex',

        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <ChevronLeft
        fontSize="small"
        sx={{
          display: 'flex',
          marginLeft: ({ spacing }) => spacing(1),
        }}
      />
      <span>Zurück</span>
    </Link>
  )
}

export default BackLink
