import { ReactElement, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { dropRight, last } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Cancel, Visibility } from '@mui/icons-material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ThreeEvent } from '@react-three/fiber'
import { ToggleButton, ToggleButtonGroup } from '@ui/actions'
import { useControlStore, useElementSelectionStore } from '@editorStores'
import { useModelClickListeners, useTypeInteraction } from '@editorHooks'

const VisibilitySelector = (): ReactElement => {
  const actionMode = useControlStore(state => state.actionMode)
  const setActionMode = useControlStore(state => state.setActionMode)
  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)
  const setAllElementsVisible = useControlStore(state => state.setAllElementsVisible)
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)

  const [history, setHistory] = useState<string[]>([])

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)

  const { enqueueSnackbar } = useSnackbar()

  const isHideMode = actionMode === 'hide'

  const hideElement = ({ object: { name: id } }: ThreeEvent<MouseEvent>) => {
    if (selectedStandAloneIds.has(id)) {
      return enqueueSnackbar(
        'Deaktivieren Sie dieses Element, bevor Sie versuchen, es auszublenden',
        {
          variant: 'warning',
        },
      )
    }

    addHiddenElementIds([id])
    setHistory([...history, id])
  }

  useTypeInteraction('all', isHideMode)

  useModelClickListeners(hideElement, [actionMode, history], isHideMode, true)

  // show last hidden element
  useHotkeys(
    'ctrl+z',
    () => {
      const lastId = last(history)
      if (lastId) {
        removeHiddenElementIds([lastId])
        setHistory(dropRight(history))
      }
    },
    { enabled: !isTapelineDrawing && actionMode === 'hide', preventDefault: true },
    [history],
  )

  return (
    <ToggleButtonGroup>
      <ToggleButton
        hint="Element ausblenden"
        onClick={() => setActionMode(isHideMode ? null : 'hide')}
        selected={actionMode === 'hide'}
        Icon={Visibility}
        data-cy="btn-hide"
      />
      <ToggleButton
        hint="Elemente einblenden"
        onClick={() => {
          setActionMode(null)
          setAllElementsVisible()
          setHistory([])
        }}
        selected={false}
        Icon={Cancel}
        data-cy="btn-hide-reset"
      />
    </ToggleButtonGroup>
  )
}

export default VisibilitySelector
