import { ReactElement } from 'react'
import { PhotoSizeSelectSmall } from '@mui/icons-material'
import { useMarqueeSelectionStore } from '@modugen/scene/lib/controllers/MarqueeSelectionController/marqueeSelectionStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ToggleButton } from '@ui/actions'

const SelectionControls = (): ReactElement => {
  const { isActive } = useMarqueeSelectionStore()
  const isTapelineActive = useTapelineStore(state => state.isActive)

  return (
    <ToggleButton
      value="snap"
      size="small"
      hint="Bereich selektieren. Halten Sie Strg/Meta gedrückt und wählen Sie den Bereich des Modells, den Sie auswählen wollen"
      selected={isActive}
      disabled={isTapelineActive}
      Icon={PhotoSizeSelectSmall}
    />
  )
}

export default SelectionControls
