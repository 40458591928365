import { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import { useFilterState } from '../../../hooks/useFilterState'

const NeutralModeFilters = (): ReactElement => {
  const { showRips, showLintels } = useFilterState()
  const toggleTypeVisibility = useModelStore(state => state.toggleTypeVisibility)

  const visibility = useStructuralPlanningStore(state => state.structuralPlanningElementVisibility)
  const changeVisibility = useStructuralPlanningStore(
    state => state.changeStructuralPlanningElementVisibility,
  )

  return (
    <>
      <SwitchLabeled
        checked={showLintels}
        onChange={() => toggleTypeVisibility('lintels' as ElementTypes)}
        label="Fensterstürze"
        data-cy="lintels-visibility-switch"
      />
      <SwitchLabeled
        checked={showRips}
        onChange={() => toggleTypeVisibility('rips' as ElementTypes)}
        label="Rippen"
        data-cy="rips-visibility-switch"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.pointLoads}
        onChange={() => changeVisibility('pointLoads', !visibility.pointLoads)}
        label="Punktlasten"
        data-cy="point-loads-visibility-switch"
      />
      <SwitchLabeled
        checked={visibility.lineLoads}
        onChange={() => changeVisibility('lineLoads', !visibility.lineLoads)}
        label="Linienlasten"
        data-cy="line-loads-visibility-switch"
      />
      <SwitchLabeled
        checked={visibility.areaLoads}
        onChange={() => changeVisibility('areaLoads', !visibility.areaLoads)}
        label="Flächenlasten"
        data-cy="area-loads-visibility-switch"
      />
    </>
  )
}

export default NeutralModeFilters
