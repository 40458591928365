import { FC, PropsWithChildren } from 'react'
import Handsontable from 'handsontable'

type Props = {
  'hot-renderer': unknown
}

type HotRendererProps = {
  TD?: HTMLTableCellElement
  row?: number
  col?: number
  prop?: string | number
  value?: Handsontable.CellValue
  cellProperties?: Handsontable.CellProperties
  isRenderer?: boolean
}

export const CellRounder: FC<Props> = (props: PropsWithChildren<Props> & HotRendererProps) => {
  const value = props.value

  const roundedValue = value || value === 0 ? Number(value).toFixed(2) : null

  return <>{roundedValue}</>
}

export const CellColourerAndRounder: FC<Props> = (
  props: PropsWithChildren<Props> & HotRendererProps,
) => {
  const value = props.value

  if (!props.TD) return <>{value}</>
  if (Number(value) < 0) props.TD.style.background = '#CEC'
  if (Number(value) > 0) props.TD.style.background = '#ECC'

  const roundedValue = value || value === 0 ? Number(value).toFixed(2) : null
  return <>{roundedValue}</>
}
