import { ReactElement, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useElementLabel } from '@editorHooks'
import StiffeningElementForm from './StiffeningElementForm'

interface Props {
  intervals: StiffeningSegment[]
  onSave: () => void
  isLoading: boolean

  selectedStiffeningElement?: string
  setlectStiffeningElement: (guid: string) => void
}

const StiffeningElementList = ({
  intervals,
  onSave,
  isLoading,
  selectedStiffeningElement,
  setlectStiffeningElement,
}: Props): ReactElement => {
  const getElementLabel = useElementLabel()

  // TODO: In general this side effect should be avoided here. We should set
  // this when the element is clicked and a wall is selected. I think we should
  // implement this once the query params are replaced by the store.
  useEffect(() => {
    if (!selectedStiffeningElement && intervals.length) {
      setlectStiffeningElement(intervals[0].guid as string)
    }
  }, [intervals, selectedStiffeningElement, setlectStiffeningElement])

  return (
    <>
      <Box>
        {intervals.map(({ guid }) => (
          <Accordion
            key={guid}
            expanded={guid === selectedStiffeningElement}
            onChange={(_, expanded) => {
              if (expanded) setlectStiffeningElement(guid as string)
            }}
          >
            <AccordionSummary
              key={guid}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={guid}
            >
              <Typography variant="body2" data-cy="stiffening-element-label">
                Aussteifendes Element {getElementLabel(guid as string)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StiffeningElementForm
                FORM_ID={String(guid).split('-').join('')}
                onSave={onSave}
                stiffeningElementGuid={guid as string}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {!!intervals.length && (
        <Box display="flex" justifyContent="end" marginY={2}>
          <SaveButton
            type="submit"
            data-cy="btn-save"
            form={String(selectedStiffeningElement).split('-').join('')}
            loading={isLoading}
          >
            Speichern
          </SaveButton>
        </Box>
      )}
    </>
  )
}

export default StiffeningElementList
