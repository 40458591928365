import { ReactElement } from 'react'
import { useCornerConnectionAdditionalLoadProposalState } from '@resultsHooks'
import { isClose } from '@resultsUtils'
import { toNumber } from 'lodash-es'
import { Stack } from '@mui/material'
import { TextField } from '@ui/forms'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  relSupportPosition: number
  wallLength: number
  biDirection?: boolean
}

const FormFields = ({
  relSupportPosition,
  wallLength,
  biDirection = false,
}: Props): ReactElement => {
  const { changeProposal, clearProposalInterval } = useCornerConnectionAdditionalLoadProposalState()

  useStoreSubscription({
    writeCallback: collectionLeft => {
      if (collectionLeft === '0') {
        clearProposalInterval('left', biDirection)
        return
      }

      const val = toNumber(collectionLeft)
      const upper = relSupportPosition
      const lower = upper - val / wallLength

      changeProposal(lower, upper, 'left', biDirection)
    },
    fieldName: 'collectionLeft',
    compareValues: true,
  })

  useStoreSubscription({
    writeCallback: collectionRight => {
      if (collectionRight === '0') {
        clearProposalInterval('right', biDirection)
        return
      }

      const val = toNumber(collectionRight)
      const lower = toNumber(relSupportPosition)
      const upper = lower + val / wallLength

      changeProposal(lower, upper, 'right', biDirection)
    },
    fieldName: 'collectionRight',
    compareValues: true,
  })

  const isSupportAtStart = isClose(toNumber(relSupportPosition) * wallLength, 0, 0.01)
  const isSupportAtEnd = isClose(toNumber(relSupportPosition), wallLength, 0.01)

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Stack direction="row" alignItems="center" flex={1}>
        <TextField
          label="Bereich 1"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="collectionLeft"
          unit="m"
          inputProps={{ decimalScale: 2 }}
          disabled={isSupportAtStart}
          tooltip={
            isSupportAtStart
              ? {
                  text: 'Support ist am Start der Wand, deswegen ist dieser Bereich abgeschaltet',
                }
              : { text: 'Auf 0 setzen um diesen Bereich nicht zu berücksichtigen' }
          }
        />
      </Stack>

      <Stack direction="row" alignItems="center" flex={1}>
        <TextField
          label="Bereich 2"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="collectionRight"
          unit="m"
          inputProps={{ decimalScale: 2 }}
          disabled={isSupportAtEnd}
          tooltip={
            isSupportAtEnd
              ? {
                  text: 'Support ist am Ende der Wand, deswegen ist dieser Bereich abgeschaltet',
                }
              : { text: 'Auf 0 setzen um diesen Bereich nicht zu berücksichtigen' }
          }
        />
      </Stack>
    </Stack>
  )
}

export default FormFields
