import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { useResultsQueryParams } from '@resultsHooks'
import { filter, min } from 'lodash-es'
import { Warning } from '@mui/icons-material'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { LoadingIndicator } from '@ui/feedback'
import { CentimeterField, Select, TextField } from '@ui/forms'
import {
  KC90TextIcon,
  LintelWidthTextIcon,
  RipHeightTextIcon,
  RipWidthTextIcon,
  SupportLengthTextIcon,
  SupportWidthTextIcon,
  UtilizationC90TextIcon,
} from '@ui/icons/misc'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { getCalcChecksForElement, getDefaultCheckSettingsForElement } from '@queries'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { convertUnit } from 'src/utils/units'
import { getSupportsOfElement } from '../../utils'
import { getDefaultFormValues as getDefaultFormValuesCompressionSettingsForm } from '../LintelCompressionSettingsForm/utils'
import { renderOverhangFromCS, renderUtilization } from './utils'

const CellWithRightBorder = styled(TableCell)(({ theme }) => ({
  borderRightWidth: 2,
  borderRightColor: theme.palette.grey[300],
  borderRightStyle: 'solid',
}))

const renderRipHeightAndLintelWidthCompatibility = (
  ripCS: CrossSection,
  lintelCS: CrossSection,
  supportGeometry: SupportGeometry,
) => {
  const ripHeight = ripCS.shape.height
  const lintelWidth = lintelCS.shape.width
  const minWidth = min([ripHeight, lintelWidth])
  if (!minWidth) {
    return <Typography>{'...'}</Typography>
  }
  const supportGeometryWidthIsSmallerThanMinWidth = supportGeometry.width < minWidth
  const supportGeometryWidthIsHigherThanMinWidth = supportGeometry.width > minWidth

  const supportGeometryWidthCm = convertUnit(supportGeometry.width, '0', 'm', 'cm')
  const minWidthCm = convertUnit(minWidth, '0', 'm', 'cm')

  let tooltipText = ''
  if (supportGeometryWidthIsSmallerThanMinWidth) {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) ist kleiner als die Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  } else if (supportGeometryWidthIsHigherThanMinWidth) {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) ist größer als die Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  } else {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) entspricht der Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  }
  const showWarning =
    supportGeometryWidthIsSmallerThanMinWidth || supportGeometryWidthIsHigherThanMinWidth

  return (
    <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipText }} />}>
      <Typography color={showWarning ? 'error' : 'inherit'} alignItems="center">
        {showWarning ? (
          <>
            <Warning sx={{ verticalAlign: 'middle' }} />
            {`!= ${minWidthCm} cm`}
          </>
        ) : (
          <Typography alignItems="center">OK</Typography>
        )}
      </Typography>
    </Tooltip>
  )
}

const checkSettingsAreSteel = (checkSettings: SettingsOnMember) => {
  return checkSettings.setting_type === 'steel-column' || checkSettings.setting_type === 'other'
}

const crossSectionIsSteel = (crossSection: CrossSection) => {
  return crossSection.material.kind === 'Steel'
}

export interface BundleItem {
  check: SteelCompressionCheck | SupportCompressionStructuralCheck
  targetCrossSection: ElementCSAssignment
  supportConfig: SupportGeometry
}

const FormFields = () => {
  const {
    params: { selectedCheckPosition },
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()
  const { projectId } = useParams()

  // form state
  const { setValue } = useFormContext()
  const formBundles: BundleItem[] = useWatch({ name: 'formBundles' })
  const crossSectionFormData: CrossSectionFormDataType = useWatch({ name: 'crossSection' })
  const elementGuid = useWatch({ name: 'elementGuid' })
  // derive values from form state
  const newCS = {
    material: crossSectionFormData.material,
    shape:
      crossSectionFormData.materialType === 'steelMaterial'
        ? crossSectionFormData.steelShape
        : crossSectionFormData.shape,
    usage_class: crossSectionFormData.usage_class,
  } as CrossSection

  const checkSettings: SettingsOnMember = useWatch({ name: 'checkSettings' })

  const getLabel = useElementLabel()
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const elementsSupportingSelected = verticalTransmissionGraph
    ? getSupportsOfElement(elementGuid, verticalTransmissionGraph)
    : []

  const requiresNewCheckSettings = !(
    checkSettingsAreSteel(checkSettings) === crossSectionIsSteel(newCS)
  )

  const queryParams = {
    elementGuid: elementGuid,
    newCS: newCS,
  }

  const { isLoading } = useQuery(
    // just use as many dependencies as possible to ensure that the query is invalidated when the form state changes
    [
      'getDefaultCheckSettingsForElement',
      queryParams,
      checkSettings,
      formBundles,
      crossSectionFormData,
    ],
    () => {
      if (requiresNewCheckSettings) {
        // execute this sequentially
        return getDefaultCheckSettingsForElement
          .request(projectId as string, elementGuid, newCS)
          .then(
            (
              getDefaultCheckSettingsForElementResponse: GetDefaultCheckSettingsForElementResponse,
            ) => {
              return getCalcChecksForElement.request(
                projectId as string,
                queryParams.elementGuid,
                getDefaultCheckSettingsForElementResponse.new_settings,
                newCS,
              )
            },
          )
          .then((csSuggestion: CrossSectionSuggestion) => {
            return {
              new_settings: csSuggestion.settings,
              checks: csSuggestion.checks,
            }
          })
      } else {
        return Promise.resolve({})
      }
    },
    {
      onSuccess: (data: { new_settings?: SettingsOnMember; checks?: CombinedPositionCheck[] }) => {
        if (requiresNewCheckSettings && data.new_settings && data.checks) {
          setValue('checkSettings', data.new_settings)
          setValue('checks', data.checks)
          const supportCompressionChecks = filter(data.checks, check =>
            ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
          ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
          const newformBundles = getDefaultFormValuesCompressionSettingsForm(
            elementGuidToCrossSection,
            data.new_settings,
            supportCompressionChecks,
            elementsSupportingSelected,
          )
          setValue('formBundles', newformBundles.formBundles)
        }
        // update form bundles
      },
    },
  )

  const lintelCSIsSteel = newCS.material.kind === 'Steel'
  const apEditDisabled = lintelCSIsSteel
  const disableTable = isLoading && requiresNewCheckSettings

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh', width: '900px', maxWidth: '900px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <CellWithRightBorder align="center" colSpan={3}></CellWithRightBorder>
            <CellWithRightBorder align="center" colSpan={3}>
              darunterliegendes Bauteil
            </CellWithRightBorder>
            <CellWithRightBorder align="center" colSpan={1}>
              Sturz
            </CellWithRightBorder>
            <TableCell align="center" colSpan={3}>
              Einstellungen Auflagerpressung
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>
              <UtilizationC90TextIcon />
            </TableCell>
            <CellWithRightBorder>Pos</CellWithRightBorder>
            <TableCell align="center">Bauteil</TableCell>
            <TableCell align="center">Material</TableCell>
            <CellWithRightBorder align="center">QS Rippe</CellWithRightBorder>
            <CellWithRightBorder align="center">QS Sturz</CellWithRightBorder>
            <TableCell align="center">Geometrie Auflager</TableCell>
            <TableCell align="center" sx={{ width: '100px' }}>
              Überstand
            </TableCell>
            <TableCell align="center">
              <KC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        {!disableTable ? (
          <TableBody>
            {formBundles.map(({ check, targetCrossSection }, index) => {
              const supportConfig = formBundles?.[index]?.supportConfig
              const ripCS = targetCrossSection?.element_cs
              const ripCSIsSteel = ripCS?.material.kind === 'Steel'
              return (
                <TableRow
                  key={check.relative_position}
                  onClick={() => setSelectedCheckPosition(check.relative_position)}
                  selected={selectedCheckPosition === check.relative_position}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{renderUtilization(check.max_utilization)}</TableCell>
                  <CellWithRightBorder>{check.relative_position.toFixed(2)}</CellWithRightBorder>
                  <TableCell>
                    {targetCrossSection ? getLabel(targetCrossSection.element_guid) : 'n/a'}
                  </TableCell>
                  <TableCell>
                    {targetCrossSection && (
                      <TextField
                        name={`formBundles[${index}].targetCrossSection.element_cs.material.identifier`}
                        disabled={true}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            padding: '0 4px',
                          },
                          '& input': {
                            padding: '4px 8px',
                          },
                        }}
                      />
                    )}
                  </TableCell>
                  <CellWithRightBorder>
                    {targetCrossSection && (
                      <Stack direction="column" spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <RipWidthTextIcon color="blue" />
                          <CentimeterField
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                padding: '0 4px',
                              },
                              '& input': {
                                padding: '4px 8px',
                              },
                            }}
                            name={`formBundles[${index}].targetCrossSection.element_cs.shape.width`}
                            data-cy={`txt-cs-width-${index}`}
                            disabled={ripCSIsSteel}
                          />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <RipHeightTextIcon color="blue" />
                          <CentimeterField
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  padding: '0 4px',
                                },
                                '& input': {
                                  padding: '4px 8px',
                                },
                              },
                            }}
                            name={`formBundles[${index}].targetCrossSection.element_cs.shape.height`}
                            data-cy={`txt-cs-height-${index}`}
                            disabled={ripCSIsSteel}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </CellWithRightBorder>
                  <CellWithRightBorder>
                    {lintelCSIsSteel ? (
                      <Typography align="center">n/a</Typography>
                    ) : (
                      <Stack direction="column" spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                          {'...'}
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <LintelWidthTextIcon color="orange" />
                          <CentimeterField
                            name={`crossSection.shape.width`}
                            data-cy={`lintel-cs-width`}
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                padding: '0 4px',
                              },
                              '& input': {
                                padding: '4px 8px',
                              },
                            }}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </CellWithRightBorder>
                  <TableCell>
                    {apEditDisabled ? (
                      <Typography align="center">n/a</Typography>
                    ) : (
                      <Stack direction="column" spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <SupportLengthTextIcon />
                          <CentimeterField
                            name={`formBundles[${index}].supportConfig.length`}
                            data-cy={`txt-config-length-${index}`}
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                padding: '0 4px',
                              },
                              '& input': {
                                padding: '4px 8px',
                              },
                            }}
                          />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <SupportWidthTextIcon />
                          <CentimeterField
                            name={`formBundles[${index}].supportConfig.width`}
                            data-cy={`txt-config-width-${index}`}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  padding: '0 4px',
                                },
                                '& input': {
                                  padding: '4px 8px',
                                },
                              },
                            }}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="column" spacing={1}>
                      <Typography>
                        {apEditDisabled ? 'n/a' : renderOverhangFromCS(ripCS, supportConfig)}
                      </Typography>
                      <Typography>
                        {renderRipHeightAndLintelWidthCompatibility(ripCS, newCS, supportConfig)}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {apEditDisabled ? (
                      <Typography>n/a</Typography>
                    ) : (
                      <Select
                        size="small"
                        name={`formBundles[${index}].supportConfig.k_c_90`}
                        options={[
                          { value: 1.0, label: '1.0' },
                          { value: 1.25, label: '1.25' },
                          { value: 1.5, label: '1.5' },
                          { value: 1.75, label: '1.75' },
                        ]}
                        sx={{
                          '& .MuiSelect-select': {
                            padding: '4px 8px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '1px',
                            },
                          },
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        ) : (
          <TableBody>
            <LoadingIndicator />
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default FormFields
