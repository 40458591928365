import { ReactElement, ReactNode, useEffect } from 'react'
import { Vector3, Object3D, LinearSRGBColorSpace } from 'three'
import { useTheme, ThemeProvider } from '@mui/material/styles'
import { ModuGenScene } from '@modugen/scene/lib'

interface SceneProps {
  children: ReactNode
}

const Scene = ({ children }: SceneProps): ReactElement => {
  const theme = useTheme()

  useEffect(() => {
    Object3D.DEFAULT_UP = new Vector3(0, 0, 1)
  }, [])

  return (
    <ModuGenScene
      // @ts-expect-error
      canvasProps={{
        legacy: true,
        onCreated: state => {
          state.gl.outputColorSpace = LinearSRGBColorSpace
        },
      }}
    >
      {/* cannot share context with rest of application */}
      <ThemeProvider theme={theme}>
        <ambientLight intensity={1 * Math.PI} />
        <pointLight position={[15, 10, 15]} intensity={0.35 * Math.PI} />
        <pointLight position={[-15, -10, 15]} intensity={0.35 * Math.PI} />
        {children}
      </ThemeProvider>
    </ModuGenScene>
  )
}

export default Scene
