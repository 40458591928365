import { ReactElement } from 'react'
import { find } from 'lodash-es'
import { Grid, InputAdornment } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { RelativePositionField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  guid: string
  length: number
}

const FormFields = ({ guid, length }: Props): ReactElement => {
  const updateLintel = useModelStore(state => state.updateLintel)

  useStoreSubscription({
    writeCallback: ({ relative_start, relative_end, wall_guid }) => {
      const lintels = useModelStore.getState().model.lintels
      const lintel = find(lintels, { position_guid: guid })

      const walls = useModelStore.getState().model.walls
      const wall = find(walls, { guid: wall_guid }) as ShapeObject
      const wallDirection = wall.shape.points[0].directionTo(wall.shape.points[1])

      const startAtBase = wall.shape.points[0].addScaledVector(
        wallDirection,
        relative_start * length,
      )
      const endAtBase = wall.shape.points[0].addScaledVector(wallDirection, relative_end * length)

      const start = new ImmutableVector3(startAtBase.x, startAtBase.y, lintel?.start.z)
      const end = new ImmutableVector3(endAtBase.x, endAtBase.y, lintel?.start.z)

      updateLintel({
        position_guid: guid,
        start,
        end,
      })
    },
  })

  return (
    <Grid container spacing={2}>
      <RelativePositionField
        label="Start Position"
        length={length}
        size="small"
        sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
        name="relative_start"
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        data-cy="input-relative-start"
      />
      <RelativePositionField
        label="End position"
        length={length}
        size="small"
        sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
        name="relative_end"
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        data-cy="input-relative-end"
      />
    </Grid>
  )
}

export default FormFields
