import { ReactElement } from 'react'
import { Tab, TabProps } from '@mui/material'

interface Props extends TabProps {
  tabColor?: string
}

const ColoredTab = ({ tabColor, ...props }: Props): ReactElement => {
  return (
    <Tab
      {...props}
      sx={{
        color: tabColor,
        '&.Mui-selected': {
          color: tabColor,
        },
      }}
    />
  )
}

export default ColoredTab
