import { ReactElement, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { uniqBy, filter } from 'lodash-es'
import { Grid } from '@mui/material'
import { SelectLoadable } from '@ui/forms'
import { getCLTCrossSections } from '@queries'

const CLTLayerFormFields = (): ReactElement => {
  const { watch, setValue } = useFormContext()
  const producerWatch = watch('producer')
  const cltWatch = watch('layer.clt')

  const renderProducerOptions = useCallback((data: CLTCrossSection[]) => {
    const values = data.map(({ producer }) => ({
      value: producer.trim(),
      label: producer,
    }))

    return uniqBy(values, 'value')
  }, [])

  const renderLayerOptions = useCallback(
    (data: CLTCrossSection[]) => {
      const valuesByProducer = filter(data, ['producer', producerWatch])

      return valuesByProducer.map(layer => ({
        value: layer,
        label: layer.unique_name,
      }))
    },
    [producerWatch],
  )

  useEffect(() => {
    if (cltWatch) setValue('layer.unique_name', cltWatch.unique_name)
  }, [cltWatch])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectLoadable
          label="Produzent"
          name="producer"
          query={getCLTCrossSections}
          renderOptions={renderProducerOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectLoadable
          label="Produkt"
          name="layer.clt"
          disabled={!producerWatch}
          query={getCLTCrossSections}
          renderOptions={renderLayerOptions}
        />
      </Grid>
    </Grid>
  )
}

export default CLTLayerFormFields
