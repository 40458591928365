import { useEffect, useMemo } from 'react'
import { getProblematicElements } from '@structuralPlanningUtils'
import { useTheme } from '@mui/material'
import { useElementLoadStore, useStructuralPlanningStore } from '@editorStores'
import { useGuidToDomain, usePositionGuidToWallGuid } from '@editorHooks'
import useElementTypes from '../../../hooks/useElementTypes'
import { useSceneStore } from '../../../stores/sceneStore'

const useProblemViewColoring = (enabled = true) => {
  const theme = useTheme()

  const setGuidToColorMapping = useSceneStore(state => state.setGuidToColorMapping)
  const clearGuidToColorMapping = useSceneStore(state => state.clearGuidToColorMapping)

  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )
  const loadsPerElement = useElementLoadStore(state => state.loads)

  const elementTypes = useElementTypes()

  const positionGuidToWallGuid = usePositionGuidToWallGuid()

  const guidToDomain = useGuidToDomain()

  const problematicElements = useMemo(
    () =>
      Object.fromEntries(
        getProblematicElements(
          verticalTransmissionGraph,
          elementTypes,
          positionGuidToWallGuid,
          guidToDomain,
          loadsPerElement,
        ).element_guids.map(guid => [guid, theme.scenePalette.invalid as string]),
      ),
    [
      verticalTransmissionGraph,
      elementTypes,
      positionGuidToWallGuid,
      guidToDomain,
      loadsPerElement,
      theme.scenePalette.invalid,
    ],
  )

  useEffect(() => {
    if (enabled) setGuidToColorMapping(problematicElements)
    else clearGuidToColorMapping()

    return () => clearGuidToColorMapping()
  }, [problematicElements, enabled, setGuidToColorMapping, clearGuidToColorMapping])
}

export default useProblemViewColoring
