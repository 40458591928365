import { ReactElement } from 'react'
import { Grid, InputAdornment, Stack } from '@mui/material'
import { MillimeterField, Select, UnitConvertedField } from '@ui/forms'

const AssemblyVibrationCheckSettingsFormFields = (): ReactElement | null => {
  return (
    <Stack direction="column" spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            label="Anforderungen"
            name="vibration_check_settings.requirement"
            options={[
              {
                value: 'HighDemands',
                label: 'Hohe Anforderung (Decken zwischen unterschiedlichen Nutzungseinheiten) ',
              },
              {
                value: 'LowerDemands',
                label: 'Mittlere Anforderung (Decken innerhalb einer Nutzungseinheit) ',
              },
              {
                value: 'NoDemands',
                label: 'Keine Anforderung',
              },
            ]}
          />
          <Grid item xs={12}>
            <Select
              label="Estrich"
              name="vibration_check_settings.screed_data.screed"
              options={[
                {
                  value: 'NoScreed',
                  label: 'Kein Estrich',
                },
                {
                  value: 'HeavyScreed',
                  label: 'Nassestrich',
                },
                {
                  value: 'LightScreed',
                  label: 'Trockenestrich',
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Schüttung"
            name="vibration_check_settings.screed_data.fill"
            options={[
              {
                value: 'NoFill',
                label: 'Keine Schüttung',
              },
              {
                value: 'HeavyFill',
                label: 'Schwere Schüttung (>= 60 kg/m²)',
              },
              {
                value: 'LightFill',
                label: 'Leichte Schüttung',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <MillimeterField
            name="vibration_check_settings.screed_height"
            label="Estrichhöhe"
            size="small"
            numeralFormatter="0" // 0 decimals
          />
        </Grid>
        <Grid item xs={12}>
          <UnitConvertedField
            name="vibration_check_settings.screed_e_modul"
            label="E-Modul Estrich"
            conversionFactor={1000000}
            InputProps={{
              endAdornment: <InputAdornment position="end">N/mm²</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default AssemblyVibrationCheckSettingsFormFields
