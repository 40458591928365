import { ReactElement } from 'react'
import { useModelStore } from '@editorStores'
import useStructuralPlanningQueryParams from '../../../pages/StructuralPlanning/hooks/useStructuralPlanningQueryParams'
import StoreySelectBase from './StoreySelectBase'

const SelectableDropDown = (): ReactElement => {
  const visibleStoreys = useModelStore(state => state.visibleStoreys)
  const availableStoreys = useModelStore(state => state.availableStoreys)
  const toggleAllStoreysVisibility = useModelStore(state => state.toggleAllStoreysVisibility)
  const toggleSingleStoreyVisibility = useModelStore(state => state.toggleSingleStoreyVisibility)

  const {
    params: { storey },
    actions: { setStorey },
  } = useStructuralPlanningQueryParams()

  return (
    <StoreySelectBase
      visibleStoreys={visibleStoreys}
      availableStoreys={availableStoreys}
      toggleAll={toggleAllStoreysVisibility}
      toggleSingle={toggleSingleStoreyVisibility}
      clickMain={setStorey}
      selectedStorey={storey || undefined}
    />
  )
}

export default SelectableDropDown
