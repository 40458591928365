import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import { Stack, Switch, Typography } from '@mui/material'
import { useResultsInvalidation } from '@editorHooks'
import { getModel } from '@queries'
import { updateVerticalSlabMetadata } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'

interface Props {
  element: VerticalSlab
  setSlabStale: (slab: ShapeObject) => void
}

const MetadataForm = ({ element, setSlabStale }: Props) => {
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()
  const invalidateResults = useResultsInvalidation()
  const metadata = element?.metadata

  const { mutateAsync: updateMetadata, isLoading: isUpdating } = useMutation(
    async (updatedMetadata: VerticalSlabMetadata) => {
      await updateVerticalSlabMetadata.request(projectId, element.guid, updatedMetadata)
      return updatedMetadata
    },
    {
      onSuccess: async (updatedMetadata: VerticalSlabMetadata) => {
        // This is necessary, otherwise, due to the useElementHistory hook,
        // the change gets overwritten once a different slab is selected.
        const updatedElement = {
          ...element,
          metadata: {
            ...updatedMetadata,
          },
        } as VerticalSlab
        setSlabStale(updatedElement)
        await client.invalidateQueries(getModel.getKey(projectId as string))
        invalidateResults(projectId as string)
        enqueueSnackbar('Einstellungen erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        const errorMessage = buildErrorMessage(error, 'Error updating metadata')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const handleToggle = async (event: { target: { checked: boolean } }) => {
    if (metadata) {
      const updatedMetadata = {
        ...metadata,
        use_global_live_loads: event.target.checked, // Toggle the value
      } as VerticalSlabMetadata

      await updateMetadata(updatedMetadata)
    }
  }

  return metadata ? (
    <>
      <Stack spacing={2} direction="row" alignItems={'center'}>
        <Switch
          checked={metadata.use_global_live_loads}
          onChange={handleToggle} // Trigger the mutation on toggle
          disabled={isUpdating} // Optionally disable the switch while updating
        />
        <Typography>Globale Nutzlasten ansetzen</Typography>
      </Stack>
    </>
  ) : (
    <></>
  )
}

export { MetadataForm }
