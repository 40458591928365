import { ReactElement, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useMaxUtilizationOver1, useResultsQueryParams } from '@resultsHooks'
import { maxUtilizationToMemberPositionBundle } from '@resultsUtils'
import { filter, find, some } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Calculate } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material'
import { Info } from '@ui/feedback'
import { ColoredTab } from '@ui/navigation'
import { Box } from '@ui/structure'
import { useModelStore, useResultsStore } from '@editorStores'
import { downLoadFile } from '@utils'
import { getPositionGroupingExport } from '@mutations'
import { MaxSegmentUtilizationList, GroupingsTab } from '../'
import StandardRipCategoryGroupingList from '../StandardRipCategoryGroupingList'
import { tabConfig, WallSubTab as WallSubTabType } from './constants'
import { useSegmentUtilizations } from './hooks/useSegmentUtilizations'

const WallSubTab = (): ReactElement => {
  const { palette } = useTheme()
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const project = useModelStore(state => state.project)

  const {
    params: { wallSubTab, selectedStiffeningSegment, selectedElement },
    actions: { setWallSubTab, selectStiffeningSegment },
  } = useResultsQueryParams()

  const allUtilizations = useSegmentUtilizations()

  const wallRipsPositionGrouping = useResultsStore(state => state.wallRipsPositionGrouping)
  const wallLintelsPositionGrouping = useResultsStore(state => state.wallLintelsPositionGrouping)
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const standardRipChecks = useResultsStore(state => state.standardRipChecks)
  const settings = useResultsStore(state => state.memberCheckSettings)
  const designForces = useResultsStore(state => state.designForces)

  // MEMOS

  const wallRipsPositionGroupingWithUtilization = useMemo(
    () =>
      wallRipsPositionGrouping && structuralChecks
        ? maxUtilizationToMemberPositionBundle(wallRipsPositionGrouping, structuralChecks)
        : [],
    [wallRipsPositionGrouping, structuralChecks],
  )

  const standardRipsPositionGrouping = useMemo(() => {
    if (!standardRipChecks) return []
    const bundles = standardRipChecks.map(ripCheck => {
      return {
        representative_position: ripCheck.standard_rip_member.guid,
        representative_for: [],
        exported: false,
      } as MemberPositionBundle
    })
    return bundles
  }, [standardRipChecks])

  const standardRipPositionsWithUtilization = useMemo(() => {
    if (!standardRipsPositionGrouping) return []
    if (!standardRipChecks) return []

    const checks = standardRipChecks.flatMap(ripCheck => ripCheck.standard_rip_checks)

    return maxUtilizationToMemberPositionBundle(standardRipsPositionGrouping, checks)
  }, [standardRipChecks, standardRipsPositionGrouping])

  const wallLintelsPositionGroupingWithUtilization = useMemo(
    () =>
      wallLintelsPositionGrouping && structuralChecks
        ? maxUtilizationToMemberPositionBundle(wallLintelsPositionGrouping, structuralChecks)
        : [],
    [wallLintelsPositionGrouping, structuralChecks],
  )

  const { mutate: downloadExportDocument, isLoading: isDownloadingExportDocument } = useMutation(
    async (type: PositionGroupingType) => {
      const data = await getPositionGroupingExport.request(projectId as string, type)
      return {
        data,
        filename: `Bauteilliste ${type === 'wall-rips' ? 'Wandrippen' : 'Fensterstürze'} - ${
          project.name || projectId
        }.xlsx`,
      }
    },
    {
      onSuccess: async ({ data, filename }) => {
        if (!data) {
          enqueueSnackbar('Daten noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          filename,
        )
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  const wallStiffeningUtilizationOver1 = useMemo(
    () => some(allUtilizations, util => util.maxUtilization > 1),
    [allUtilizations],
  )
  const wallRipsUtilizationOver1 = useMaxUtilizationOver1(wallRipsPositionGroupingWithUtilization)
  const wallLintelsUtilizationOver1 = useMaxUtilizationOver1(
    wallLintelsPositionGroupingWithUtilization,
  )
  const standardRipsUtilizationOver1 = useMaxUtilizationOver1(standardRipPositionsWithUtilization)

  const checksOfPosition = useMemo(
    () =>
      structuralChecks
        ? filter(structuralChecks, { element_guid: selectedElement || undefined })
        : [],
    [structuralChecks, selectedElement],
  )

  const settingsOnMember: SettingsOnMember | null = useMemo(
    () => find(settings, { element_guid: selectedElement || undefined }) || null,
    [settings, selectedElement],
  )

  const selectedElementDesignForces = useMemo(
    () => find(designForces, ['element_guid', selectedElement]),
    [designForces, selectedElement],
  )

  return (
    <Box marginX={-2} mt={-2}>
      <TabContext value={wallSubTab || 'wall-rips'}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList
            onChange={(e: unknown, v: WallSubTabType) => setWallSubTab(v)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <ColoredTab
              value={tabConfig['wall-rips'].value}
              label={tabConfig['wall-rips'].label}
              data-cy="tab-wall-rips"
              tabColor={wallRipsUtilizationOver1 ? `${palette.error.main}` : undefined}
            />
            <ColoredTab
              value={tabConfig['wall-lintels'].value}
              label={tabConfig['wall-lintels'].label}
              data-cy="tab-wall-lintels"
              tabColor={wallLintelsUtilizationOver1 ? `${palette.error.main}` : undefined}
            />
            <ColoredTab
              value={tabConfig.stiffening.value}
              label={tabConfig.stiffening.label}
              data-cy="tab-stiffening"
              tabColor={wallStiffeningUtilizationOver1 ? `${palette.error.main}` : undefined}
            />
            <ColoredTab
              value={tabConfig['standard-rips'].value}
              label={tabConfig['standard-rips'].label}
              data-cy="tab-standard-rips"
              tabColor={standardRipsUtilizationOver1 ? `${palette.error.main}` : undefined}
            />
          </TabList>
        </Box>

        <TabPanel value={tabConfig['wall-lintels'].value}>
          {wallLintelsPositionGrouping ? (
            <GroupingsTab
              grouping={wallLintelsPositionGroupingWithUtilization}
              isDownloadingExportDocument={isDownloadingExportDocument}
              onClickDownloadExportDocument={() => downloadExportDocument('wall-lintels')}
              positionType="wall-lintels"
              checksOfPosition={checksOfPosition}
              settingsOnMember={settingsOnMember}
              designForces={selectedElementDesignForces?.design_support_forces}
            />
          ) : (
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={tabConfig['wall-rips'].value}>
          {wallRipsPositionGrouping ? (
            <GroupingsTab
              grouping={wallRipsPositionGroupingWithUtilization}
              isDownloadingExportDocument={isDownloadingExportDocument}
              onClickDownloadExportDocument={() => downloadExportDocument('wall-rips')}
              positionType="wall-rips"
              checksOfPosition={checksOfPosition}
              settingsOnMember={settingsOnMember}
              designForces={selectedElementDesignForces?.design_support_forces}
            />
          ) : (
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={tabConfig.stiffening.value}>
          {allUtilizations.length ? (
            <MaxSegmentUtilizationList
              util={allUtilizations}
              onSelect={selectStiffeningSegment}
              selectedGuid={selectedStiffeningSegment || undefined}
            />
          ) : (
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={tabConfig['standard-rips'].value}>
          {standardRipsPositionGrouping ? (
            <>
              <StandardRipCategoryGroupingList bundles={standardRipPositionsWithUtilization} />
            </>
          ) : (
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default WallSubTab
