import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { sortPointsCounterClockwise } from '@editorUtils'
import { useTapelineSnapTargets } from '@scene'
import { pointToPolygonArea } from '@structuralPlanningUtils'
import { find } from 'lodash-es'
import { Plane, Vector2 } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import {
  DrawControllerRef,
  DrawController,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { roofStoreyKey, useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'
import convexHull from 'src/components/pages/Editor/utils/convexHull'
import getPlaneLineIntersection from 'src/components/pages/Editor/utils/getPlaneLineIntersection'
import { useModelSnapTargets } from '../../hooks'

interface Props {
  resetSelectedElement: () => void
}

/**
 * @deprecated not used right now
 * @param param0
 * @returns
 */
const FreeformVerticalRoofDrawer = ({ resetSelectedElement }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const model = useModelStore(state => state.model)

  const drawControllerRef = useRef<DrawControllerRef>(null)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)

  const snapTargets = useModelSnapTargets({
    xyOnly: true,
    elementTypeSnapCornerConfig: {
      vertical_roof_slabs: false,
      roof_slabs: true,
    },
  })
  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const addVerticalRoofSlab = useModelStore(state => state.addVerticalRoofSlab)
  const updateVerticalRoofSlab = useModelStore(state => state.updateVerticalRoof)
  const getVerticalRoofSlab = useModelStore(state => state.getVerticalRoofSlab)
  const rejectLocalElements = useModelStore(state => state.rejectLocalElements)

  const drawTargetGuids = useMemo(
    () => [...model.roof_slabs, ...model.slabs].map(({ guid }) => guid),
    [model],
  )
  const verticalRoofSlabGuids = useMemo(
    () => model.vertical_roof_slabs.map(({ guid }) => guid),
    [model],
  )

  const drawStartElement = useRef<null | string>(null)

  const [drawingActive, setDrawingActive] = useState(false)

  // state for showing slab preview
  const [tempSlabGuid, setTempSlabGuid] = useState<string | null>(null)
  const [slabPoints, setSlabPoints] = useState([] as ImmutableVector3[])

  // EVENTS

  const onDrawEnd = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState

    if (drawPoint && drawTarget) {
      const newPoint = new ImmutableVector3(drawPoint.x, drawPoint.y, drawPoint.z)
      const targetGuid = drawTarget.object.name
      const projectionTarget = find(
        [...model.roof_slabs, ...model.vertical_roof_slabs, ...model.slabs],
        ['guid', targetGuid],
      ) as ShapeObject
      const pointsV = projectionTarget.shape.points
      const originalPlane = new Plane().setFromCoplanarPoints(
        pointsV[0].v,
        pointsV[1].v,
        pointsV[2].v,
      )
      const newPointProjected = getPlaneLineIntersection(
        originalPlane,
        new Vector2(newPoint.x, newPoint.y),
      ) as ImmutableVector3
      const newPointOnProjectionTarget = pointToPolygonArea(newPointProjected, pointsV)
      const tempVerticalRoofSlab = tempSlabGuid && getVerticalRoofSlab(tempSlabGuid)
      const tempVerticalSlabExists = !!tempVerticalRoofSlab
      if (tempSlabGuid && tempVerticalSlabExists) {
        if (tempVerticalRoofSlab.is_local) {
          // As we did draw in 2d we need to project the points to the original roof slab
          const newPoints = [...slabPoints, newPointOnProjectionTarget]
          const pointsCVXHull = convexHull(newPoints)
          const pointsCCWSorted = sortPointsCounterClockwise(pointsCVXHull)

          // save new point
          setSlabPoints(pointsCCWSorted)

          updateVerticalRoofSlab({
            guid: tempSlabGuid,
            shape: { points: pointsCCWSorted, shape_type: 'BaseAxisXYFreeform' },
          })
        }
      } else {
        const newPoints = [newPointOnProjectionTarget]
        setSlabPoints(newPoints)

        const verticalRoofSlab: ShapeObject = {
          guid: uuid(),
          shape: { points: newPoints, shape_type: 'BaseAxisXYFreeform' },
          storey: roofStoreyKey,
          type: 'vertical_roof_slabs' as ElementTypes,
          is_local: true,
        }
        setTempSlabGuid(verticalRoofSlab.guid)
        addVerticalRoofSlab(verticalRoofSlab)
        setActiveElement(verticalRoofSlab.guid)
      }
    }
  }

  useStructuralPlanningDrawerEsc(() => {
    drawControllerRef.current?.abortDrawing()

    drawStartElement.current = null
    setDrawingActive(false)
    // discard temp slab state

    setTempSlabGuid(null)
    setSlabPoints([])
    rejectLocalElements()
    resetSelectedElement()
  }, drawingActive)

  useEffect(() => {
    // on mount
    return () => {
      // on unmount
      setDrawingActive(false)
      // discard temp slab state
      setTempSlabGuid(null)
      setSlabPoints([])
      rejectLocalElements()
    }
  }, [])

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        enableIndicator
        snapToCornersAndEdges={snapToCornersAndEdges}
        orthoSnap={snapOrthogonal}
        snapToAngles={false}
        indicatorType="crosshair"
        color={scenePalette.elements3d.vertical_slabs as string}
        additionalSnapTargets={[...snapTargets, ...tapelineSnapTargets, ...tapelineCenterTargets]}
        onDrawEnd={onDrawEnd}
        snapAngle={5}
        xyOnly
        isValidDrawTarget={object =>
          drawTargetGuids.includes(object.name) || verticalRoofSlabGuids.includes(object.name)
        }
      />
    </>
  )
}

export default FreeformVerticalRoofDrawer
