import { ReactElement, ReactNode, useMemo } from 'react'
import { SelectedElementProblemsList, VerticalTransmitterList } from '@structuralPlanningComponents'
import { find } from 'lodash-es'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box } from '@ui/structure'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { rectangularShapeTabs } from '../../tabs'

interface Props {
  selectedElement: string
  children?: ReactNode
  tab?: ReactNode
  initialTabValue?: string
}

const RoofFormBase = ({ children, tab, initialTabValue, selectedElement }: Props): ReactElement => {
  const {
    modes: { isDrawMode },
    params: { verticalTransmitter, activeTab },
    actions: { setVerticalTransmitter, setActiveTab },
  } = useStructuralPlanningQueryParams()

  const selectedTab = useMemo(() => {
    if (activeTab && find(Object.entries(rectangularShapeTabs), ([, e]) => e.tab === activeTab))
      return activeTab

    return initialTabValue || rectangularShapeTabs.verticalTransmission.tab
  }, [activeTab, initialTabValue])

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setActiveTab(value)} scrollButtons="auto">
            {tab}
            <Tab
              value={rectangularShapeTabs.verticalTransmission.tab}
              label={rectangularShapeTabs.verticalTransmission.label}
            />
          </TabList>
        </Box>
        {children}
        <TabPanel value={rectangularShapeTabs.verticalTransmission.tab}>
          <VerticalTransmitterList
            selectedElement={selectedElement}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={(guid, elementGuid) =>
              setVerticalTransmitter(guid, elementGuid, isDrawMode)
            }
          />
          <SelectedElementProblemsList selectedElement={selectedElement} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default RoofFormBase
