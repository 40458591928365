import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { filter, find } from 'lodash-es'
import { Stack, Typography } from '@mui/material'
import { LoadingIndicator } from '@ui/feedback'
import { LayoutDrawer as Layout, Drawer } from '@ui/structure'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import { useTypeInteraction, useHighlightFromParams, useBlockScene } from '@editorHooks'
import { SceneControlled, FilterPortal, WidgetPortal } from '@editorComponents'
import { useQueriesLoading } from 'src/state/hooks'
import {
  modelDrawOptions,
  structureDrawOptions,
} from '../../components/Tools/EditElementSelect/constants'
import {
  Toolbar,
  OrthographicControls,
  ProblemViewControls,
  StructuralPlanningDrawer,
} from './components'
import { NeutralModeFilters, StiffeningModeFilters, WallModeFilters } from './components/Filters'
import LoadModeFilters from './components/Filters/LoadModeFilters'
import StructuralPlanningScene from './components/StructuralPlanningScene'
import {
  useStructuralPlanningQueryParams,
  useStructuralPlanningQueries,
  useStructuralPlanningEsc,
} from './hooks'

const StructuralPlanning = (): ReactElement => {
  const {
    params: { mode, selectedStiffeningElements, storey },
    actions: { setStorey },
    modes: {
      isStiffeningSelectionMode: isSelectionMode,

      isWallMode,
      isStiffeningMode,
      isNeutralMode,
      isLoadMode,
    },
  } = useStructuralPlanningQueryParams()

  useStructuralPlanningEsc()
  // STATE

  const problemViewActive = useStructuralPlanningStore(state => state.problemViewActive)
  const setProblemViewActive = useStructuralPlanningStore(state => state.setProblemViewActive)

  // QUERIES

  const queryResults = useStructuralPlanningQueries()
  const isLoading = useQueriesLoading(queryResults)

  // ACTIONS

  useTypeInteraction('none', isSelectionMode)

  useHighlightFromParams(['elements'], !selectedStiffeningElements?.length)

  const Filters = useMemo(() => {
    if (isNeutralMode) return <NeutralModeFilters />
    if (isWallMode) return <WallModeFilters />
    if (isStiffeningMode) return <StiffeningModeFilters />
    if (isLoadMode) return <LoadModeFilters />

    return <></>
  }, [isWallMode, isStiffeningMode, isNeutralMode, isLoadMode])

  useBlockScene(isLoading)

  const removeRip = useModelStore(state => state.removeRip)

  // remove all unsaved elements if mode changes
  // TODO: implement it the same way for columns, beams etc.

  const removeUnsavedElementsFromStore = useCallback(
    (mode: StructuralPlanningModes) => {
      if (mode !== 'wall-rip') {
        const rips = useModelStore.getState().model.rips
        const localRips = filter(rips, { is_local: true })
        localRips.map(rip => removeRip(rip.position_guid))
      }
    },
    [removeRip],
  )

  useEffect(() => {
    removeUnsavedElementsFromStore(mode as StructuralPlanningModes)

    return () => {
      removeUnsavedElementsFromStore(mode as StructuralPlanningModes)
    }
  }, [mode, removeUnsavedElementsFromStore])

  const modeLabel = useMemo(() => {
    const allOptions = [...modelDrawOptions, ...structureDrawOptions]
    const option = find(allOptions, el => el.value === mode)
    return option?.label
  }, [mode])

  return (
    <>
      <WidgetPortal>
        <Stack direction="row" spacing={1}>
          <OrthographicControls
            isOrthographic={!!storey}
            setIsOrthographic={ortho => {
              if (ortho) {
                setStorey('0')
              } else setStorey(null)
            }}
          />
          <ProblemViewControls
            isActive={problemViewActive}
            setIsActive={() => setProblemViewActive(!problemViewActive)}
          />
        </Stack>
      </WidgetPortal>
      <FilterPortal>{Filters}</FilterPortal>
      <Layout>
        <SceneControlled addOns={<Toolbar />}>
          <StructuralPlanningScene />
        </SceneControlled>
        <Drawer>
          {isLoading ? (
            <LoadingIndicator data-cy="loading-structural-planning" />
          ) : (
            <>
              {modeLabel && (
                <Typography variant="h4" m={2} data-cy="mode-label">
                  {modeLabel}
                </Typography>
              )}
              <StructuralPlanningDrawer />
            </>
          )}
        </Drawer>
      </Layout>
    </>
  )
}

export default StructuralPlanning
