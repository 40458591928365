import { ReactElement, ReactNode, useEffect } from 'react'
import produce from 'immer'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface Props {
  children: ReactNode
}

interface EditElementStoreInitialState {
  activeElement: string | null
  activeOpening: string | null
  isDrawingRip: boolean
  isDrawingLintel: boolean

  // used for roof extending
  activeRoofPointIndex: number | null

  drawSlabOnElement: boolean
  drawRoofOnElement: boolean
}
interface EditElementStoreType extends EditElementStoreInitialState {
  setActiveElement: (slabGuid: string | null) => void
  setActiveOpening: (openingGuid: string | null) => void
  clear: () => void
  setIsDrawingRip: (isDrawing: boolean) => void
  setIsDrawingLintel: (isDrawing: boolean) => void
  setActiveRoofPointIndex: (index: number | null) => void
  setDrawSlabOnElement: (drawSlabOnElement: boolean) => void
  setDrawRoofOnElement: (drawRoofOnElement: boolean) => void
}

const initialState: EditElementStoreInitialState = {
  activeElement: null,
  activeOpening: null,
  isDrawingRip: false,
  isDrawingLintel: false,
  activeRoofPointIndex: null,

  drawSlabOnElement: false,
  drawRoofOnElement: true,
}

const createInitialState = (): EditElementStoreInitialState => ({
  ...initialState,
})

const createStore = () =>
  create<EditElementStoreType>(
    combine(createInitialState(), set => ({
      clear: () => set(createInitialState()),

      setActiveElement: (slabGuid: string | null) => {
        set(
          produce(state => {
            state.activeElement = slabGuid
          }),
        )
      },

      setActiveOpening: (openingGuid: string | null) => {
        set(
          produce(state => {
            state.activeOpening = openingGuid
          }),
        )
      },

      setIsDrawingRip: (isDrawingRip: boolean) => {
        set({ isDrawingRip })
      },

      setIsDrawingLintel: (isDrawingLintel: boolean) => {
        set({ isDrawingLintel })
      },

      setActiveRoofPointIndex: (activeRoofPointIndex: number | null) => {
        set({ activeRoofPointIndex })
      },

      setDrawSlabOnElement: (drawSlabOnElement: boolean) => set({ drawSlabOnElement }),

      setDrawRoofOnElement: (drawRoofOnElement: boolean) => set({ drawRoofOnElement }),
    })),
  )

const useEditElementStore = createStore()

const EditElementStoreProvider = ({ children }: Props): ReactElement => {
  const clear = useEditElementStore(state => state.clear)

  useEffect(() => {
    return () => clear()
  }, [])

  return <>{children}</>
}

export { EditElementStoreProvider, useEditElementStore }
