import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useElementHistory } from '@hooks'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TypeOf } from 'yup'
import { Button, Stack } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Form } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { useStructuralPlanningFormEsc } from '@structuralPlanningHooks'
import { getModel } from '@queries'
import { updateSlab as updateSlabQuery } from '@mutations'
import { slabSchema } from '../../schema'
import FormFields from '../FormFields'

interface Props {
  selectedElement: string
  onClose: () => void
}

const SlabFormBase = ({ selectedElement, onClose }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const slabs = useModelStore(state => state.model.slabs)
  const updateSlab = useModelStore(state => state.updateSlab)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const slab = useMemo(
    () => (selectedElement ? find(slabs, { guid: selectedElement }) : undefined),
    [slabs, selectedElement],
  ) as ShapeObject

  const defaultValues = useMemo(() => {
    return {
      ...slabSchema.getDefault(),
      guid: slab?.guid,
      points: slab?.shape.points.map(p => ({ x: p.x, y: p.y, z: p.z })),
    }
  }, [slab])

  useStructuralPlanningFormEsc(() => {
    onClose()
  }, !!selectedElement)

  // HISTORY

  const { setStale: setSlabStale } = useElementHistory({
    element: slab,
    getCurrentElement: () => {
      const slabs = useModelStore.getState().model.slabs
      return find(slabs, { guid: selectedElement }) as ShapeObject
    },
    removeElement: () => {
      // do nothing
    },
    resetElement: slab => updateSlab(slab),
    dependencies: [selectedElement],
  })

  // MUTATIONS

  const { mutateAsync, isLoading } = useMutation(
    (data: TypeOf<typeof slabSchema>) =>
      updateSlabQuery.request(projectId as string, data.guid, data.points),
    {
      onSuccess: async () => {
        invalidateResults(projectId as string)

        setSlabStale(slab)
        queryClient.invalidateQueries(getModel.getKey(projectId))

        enqueueSnackbar('Dachfläche erfolgreich gespeichert', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Speichern der Dachfläche', { variant: 'error' })
      },
    },
  )

  return (
    <>
      <Form
        key={selectedElement}
        onSubmit={data => mutateAsync(data)}
        defaultValues={defaultValues}
        validationSchema={slabSchema}
        enableReinitialize
      >
        {selectedElement && (
          <FormFields selectedElement={selectedElement} slabs={slabs} updateSlab={updateSlab} />
        )}
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setActiveElement(null)}>
            Auswahl aufheben
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <SaveButton
            type="submit"
            loading={isLoading}
            fullWidth
            disabled={false}
            data-cy="btn-submit"
          >
            Speichern
          </SaveButton>
        </Stack>
      </Form>
    </>
  )
}

export default SlabFormBase
