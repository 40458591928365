import { Warning } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { convertUnit } from 'src/utils/units'
import { TargetGuidAndRelativePosition } from '../../utils'
import { BundleItem } from './formFields'

export const renderAsCM = (value: number, numeralFormatter = '0.[0]', showUnit = true) => {
  const internalValue = 'm'
  const displayUnit = 'cm'
  const convertedValueAsString = convertUnit(value, numeralFormatter, internalValue, displayUnit)
  const renderValue = `${convertedValueAsString}${showUnit ? displayUnit : ''}`
  return renderValue
}

export const calculateOverhangLintelToRip = (
  supportConfig: SupportGeometry | undefined,
  ripCS: CrossSection | undefined,
): number | undefined => {
  const lSupportConfig = supportConfig?.length
  if (ripCS?.material.kind === 'Steel') {
    // Breite parallel zum Flansch im Fall der Sturz - Rippe konstellation
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  } else {
    // its a wood cross section
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  }
}

export const renderOverhangFromCS = (
  ripCS: CrossSection,
  supportGeometry: SupportGeometry,
  showUnit = true,
) => {
  const overhang = calculateOverhangLintelToRip(supportGeometry, ripCS)
  return renderOverhang(overhang, showUnit)
}

export const renderOverhang = (value: number | undefined, showUnit = true) => {
  if (value === undefined) return 'n/a'

  const overhangCM = renderAsCM(value, '0', showUnit)
  const isOutOfRange = value < 0 || value > 0.06

  return (
    <Typography color={isOutOfRange ? 'error' : 'inherit'} alignItems="center">
      {isOutOfRange && (
        <Tooltip title="Überstand außerhalb des gewöhnlichen Wertebereichs des EC 5">
          <Warning fontSize="small" sx={{ verticalAlign: 'middle' }} />
        </Tooltip>
      )}
      {overhangCM}
    </Typography>
  )
}

export const renderUtilization = (utilization: number | undefined) => {
  if (!utilization) return 'n. a.'
  const roundedValue = utilization.toFixed(2)
  const isOverUtilized = utilization > 1

  return (
    <Typography
      sx={{
        color: isOverUtilized ? 'red' : 'inherit',
        fontWeight: isOverUtilized ? 'bold' : 'inherit',
      }}
    >
      {roundedValue}
    </Typography>
  )
}

export const getDefaultFormValues = (
  elementGuidToCrossSection: Record<string, ElementCSAssignment>,
  checkSettings: SettingsOnMember,
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
  elementsSupportingSelected: TargetGuidAndRelativePosition[],
) => {
  let formBundles: BundleItem[] = []
  if (checkSettings.setting_type !== 'steel-column' && checkSettings.setting_type !== 'other') {
    formBundles = supportCompressionChecks.map(check => {
      const supportConfig = checkSettings.support_configs.find(
        config => Number(config.relative_position) === check.relative_position,
      )

      if (!supportConfig) {
        throw new Error('Support configuration not found')
      }

      const targetGuid = elementsSupportingSelected.find(
        ({ relativePosition }) => Number(relativePosition) === check.relative_position,
      )?.guid

      const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : undefined

      if (!targetCrossSection) {
        throw new Error('Target cross section not found')
      }

      return {
        check,
        supportConfig,
        targetCrossSection,
      }
    })
  }

  return { formBundles, checkSettings }
}
