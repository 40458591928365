import { ReactElement } from 'react'
import Add from '@mui/icons-material/Add'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface Props extends LoadingButtonProps {
  children: string
}

const AddButton = ({ children, ...props }: Props): ReactElement => (
  <LoadingButton variant="contained" color="primary" startIcon={<Add />} {...props}>
    {children}
  </LoadingButton>
)

export default AddButton
