import { ReactElement, useMemo, useState } from 'react'
import { UseMutationResult } from 'react-query'
import { isFunction } from 'lodash-es'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { SelectableButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { assemblySchema } from '../AssemblyForm/schema'
import AssemblyListItem from '../AssemblyListItem'
import CreateDialog from '../CreateAssemblyDialog'
import EditAssemblyDialog from '../EditAssemblyDialog'
import { assemblyTypes, assemblyKinds } from '../constants'

interface Props {
  assemblies: Assembly[]
  assignments?: AssemblyAssignment[]
  onDelete: (assembly: Assembly) => void
  onSelect?: (category: AssemblyTypes, assembly: Assembly, isSelected: boolean) => void
  onDownload: (assembly: Assembly) => Promise<void>
  selectedAssemblies?: { [key: string]: SelectedAssembly }
  onStore?: (assembly: Assembly) => Promise<CLTAssembly | TimberFrameAssembly>
  createMutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
  editMutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
}

type AssembliesByType = {
  [key in AssemblyTypes]?: Assembly[]
}

const AssemblyList = ({
  assemblies,
  assignments = [],
  onDelete,
  onSelect,
  onDownload,
  onStore,
  selectedAssemblies,
  createMutation,
  editMutation,
}: Props): ReactElement => {
  const [createValues, setCreateValues] = useState<Assembly | null>(null)
  const [editValues, setEditValues] = useState<Assembly | null>(null)

  const assemblyKindOptions = useMemo(() => {
    return assemblyKinds.map(({ label, type }) => ({ label, value: type }))
  }, [])

  const assembliesByType = useMemo(
    () =>
      (assemblies || []).reduce<AssembliesByType>((result, assembly) => {
        const { usage_type } = assembly

        result[usage_type] = [...(result[usage_type] || []), assembly]

        return result
      }, {}),
    [assemblies],
  )

  return (
    <>
      <Box>
        {assemblyTypes.map(({ label, usage_type }) => {
          const assemblies = assembliesByType[usage_type] || []
          const hasAssemblies = !!assemblies.length
          const SelectButton = (
            <SelectableButton
              onClick={({ value: kind }: { value: AssemblyKind }) =>
                setCreateValues({
                  ...assemblySchema.getDefault(),
                  usage_type,
                  kind,
                })
              }
              options={assemblyKindOptions}
              buttonProps={{
                variant: 'outlined',
                color: 'primary',
                size: 'small',
                sx: {
                  alignSelf: 'center',
                },
              }}
              data-cy={`${usage_type}-select`}
            >
              anlegen
            </SelectableButton>
          )

          return (
            <Box mb={3} key={usage_type}>
              <Box display="flex" mb={1} justifyContent="space-between">
                <Typography variant="h6" mb={1}>
                  {label} {`(${assemblies.length})`}
                </Typography>
                {hasAssemblies && SelectButton}
              </Box>
              {!hasAssemblies && (
                <Paper>
                  <Box padding={3} textAlign="center">
                    <Typography>Sie haben noch keine Aufbauten für {label} angelegt</Typography>
                    <Box mt={2}>{SelectButton}</Box>
                  </Box>
                </Paper>
              )}
              {hasAssemblies && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {onSelect && <TableCell sx={{ width: 50 }}>Standard</TableCell>}
                        <TableCell>Illustration</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Kurzbez.</TableCell>
                        {onSelect && <TableCell align="center">Nutzungen</TableCell>}
                        <TableCell align="right">Aktionen</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assemblies.map((assembly, index) => {
                        const { usage_type, guid } = assembly
                        const isStandard =
                          selectedAssemblies && selectedAssemblies[usage_type]?.standard === guid

                        const assignedElements = assignments
                          .filter(assignment => assignment.assembly_guid === assembly.guid)
                          .map(assignment => assignment.element_guid)

                        return (
                          <AssemblyListItem
                            key={assembly.guid}
                            assembly={assembly}
                            assignedElements={assignedElements}
                            onSelect={
                              isFunction(onSelect)
                                ? () => onSelect(usage_type, assembly, !isStandard)
                                : undefined
                            }
                            onDelete={() => onDelete(assembly)}
                            onDownload={() => onDownload(assembly)}
                            onDuplicate={() => setCreateValues(assembly)}
                            onEdit={() => setEditValues(assembly)}
                            onStore={isFunction(onStore) ? () => onStore(assembly) : undefined}
                            isStandard={isStandard}
                            data-cy={`${usage_type}-list-item-${index}`}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )
        })}
      </Box>
      {createValues && (
        <CreateDialog
          mutation={createMutation}
          open
          onClose={() => setCreateValues(null)}
          assembly={createValues}
        />
      )}
      {editValues && (
        <EditAssemblyDialog
          mutation={editMutation}
          open
          onClose={() => setEditValues(null)}
          assembly={editValues}
        />
      )}
    </>
  )
}

export default AssemblyList
