import { Fragment, ReactElement, useMemo, useState } from 'react'
import convert from 'convert'
import numeral from 'numeral'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import LoadCaseAbbreviation from 'src/components/pages/Editor/pages/Results/components/MemberChecksTable/components/LoadCaseAbbreviation'

interface Props {
  supportForce: DesignSupportForce
}

const SupportTableRow = ({ supportForce }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  const designSupportForce = useMemo(
    () => numeral(convert(supportForce.design_force, 'newton').to('kilonewton')).format('0,0.00a'),
    [supportForce],
  )

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={-1}
        sx={{ '& > .MuiTableCell-root': { borderBottom: 0, paddingY: 0 } }}
      >
        <TableCell sx={{ paddingLeft: 0 }}>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ paddingLeft: 0 }}>
          <Typography>{supportForce.support_name}</Typography>
        </TableCell>

        <TableCell>{designSupportForce === 'NaN' ? '0' : designSupportForce} kN</TableCell>

        <TableCell>{supportForce.relative_position.toFixed(2)}</TableCell>
      </TableRow>

      <TableRow sx={{ '& > .MuiTableCell-root': { mb: 20 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box>
              <Grid container mt={0} ml={0}>
                {supportForce.lcc?.data && supportForce.lcc?.data && (
                  <Typography>
                    {supportForce.lcc?.data.map(([v1, v2, loadCase], i) => {
                      return (
                        <Fragment key={i}>
                          {v1}*{v2}*
                          <LoadCaseAbbreviation combination={loadCase} />
                          {i !== supportForce.lcc?.data.length - 1 && ' + '}
                        </Fragment>
                      )
                    })}
                  </Typography>
                )}
              </Grid>
              <Box mt={2}>
                <Typography>
                  k<sub>mod</sub>: {supportForce.k_mod.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default SupportTableRow
