import { useEffect } from 'react'
import { usePostHog } from 'posthog-js/react'

/**
 * This will use posthogds "register" method in order to enhance events send to
 * posthog with the custom properties (e.g. like project id)
 * @param properties
 */
const useAddPosthogEventProperties = (properties: Record<string, unknown>) => {
  const posthog = usePostHog()

  useEffect(() => {
    posthog.register(properties)
  }, [posthog, properties])
}

export default useAddPosthogEventProperties
