import { ReactElement, useState } from 'react'
import { getPolygonNormal } from '@scene'
import { find } from 'lodash-es'
import { ChevronLeft } from '@mui/icons-material'
import {
  TextField,
  Grid,
  InputAdornment,
  Stack,
  IconButton,
  MenuItem,
  ListSubheader,
  Select,
} from '@mui/material'
import { FormControl, NumberInput } from '@ui/forms'
import { useModelClickListeners } from '@editorHooks'
import { roofAreaTypes, roofAreaTypeConfig } from '../../constants'

const HipRoofFields = ({
  setConfigField,
  config,
  errors,
  disabled = false,
}: RoofFormFieldProps): ReactElement => {
  const [alphaSelected, setAlphaSelected] = useState<number>(0)

  useModelClickListeners(event => {
    const normal = getPolygonNormal(event.object.userData.points)
    const elevationAngle = (Math.acos(normal.z) * 180) / Math.PI
    setAlphaSelected(Math.round(elevationAngle))
  })

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormControl label="Alpha maßgebend" error={errors.alpha}>
          <TextField
            name="test"
            disabled={disabled}
            size="small"
            value={config.alpha}
            // @ts-ignore
            onChange={event => setConfigField('alpha', event.target.value)}
            data-cy="input-alpha"
            InputProps={{
              endAdornment: <InputAdornment position="end">grad</InputAdornment>,
              inputProps: {
                allowNegative: false,
              },
              // eslint-disable-next-line
              inputComponent: NumberInput as any,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          label="Alpha ausgewählt"
          tooltip={{ text: 'Berechneter Wert des ausgewählten Elements' }}
        >
          <Stack direction="row">
            <IconButton
              disabled={disabled}
              onClick={() => setConfigField('alpha', alphaSelected)}
              sx={{
                alignSelf: 'center',
              }}
              data-cy="btn-alpha-selected"
            >
              <ChevronLeft fontSize="small" />
            </IconButton>
            <TextField
              type="number"
              size="small"
              value={alphaSelected}
              disabled
              data-cy="input-alpha-selected"
              InputProps={{
                endAdornment: <InputAdornment position="end">grad</InputAdornment>,
              }}
            />
          </Stack>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl label="Angströmte Fläche">
          <Select
            disabled={disabled}
            value={`${config.isShortSide ? 'short' : 'long'}-${config.flowArea}`}
            onChange={event => {
              const [side, type] = event.target.value.split('-')
              const isShortSide = side === 'short'

              setConfigField('isShortSide', isShortSide)
              setConfigField('flowArea', type as RoofAreaTypes)
            }}
            renderValue={value => {
              const [side, type] = value.split('-')
              const isShortSide = side === 'short'
              const label = find(roofAreaTypeConfig, ['value', type])?.label

              return `${label} - ${isShortSide ? 'kurze' : 'lange'} Seite`
            }}
            size="small"
            fullWidth
            defaultValue=""
          >
            <ListSubheader
              sx={{
                background: ({ palette }) => palette.grey[100],
                lineHeight: '24px',
                fontWeight: 600,
                paddingY: theme => theme.spacing(1),
                borderBottom: '1px solid',
                borderTop: '1px solid',
                borderColor: ({ palette }) => palette.grey[500],
                color: ({ palette }) => palette.text.primary,
              }}
            >
              Kurze Seite
            </ListSubheader>
            {roofAreaTypes.map(type => (
              <MenuItem key={`short-${type}`} value={`short-${type}`}>
                {find(roofAreaTypeConfig, ['value', type])?.label}
              </MenuItem>
            ))}
            <ListSubheader
              sx={{
                background: ({ palette }) => palette.grey[100],
                lineHeight: '24px',
                paddingY: theme => theme.spacing(1),
                fontWeight: 600,
                borderBottom: '1px solid',
                borderTop: '1px solid',
                borderColor: ({ palette }) => palette.grey[500],
                color: ({ palette }) => palette.text.primary,
              }}
            >
              Lange Seite
            </ListSubheader>
            {roofAreaTypes.map(type => (
              <MenuItem key={`long-${type}`} value={`long-${type}`}>
                {find(roofAreaTypeConfig, ['value', type])?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default HipRoofFields
