import { ReactElement, useMemo, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { findRelativeSnapValue } from '@scene'
import Decimal from 'decimal.js'
import { useDebouncedCallback } from 'use-debounce'
import {
  TextField as MuiTextField,
  InputProps,
  OutlinedTextFieldProps,
  InputAdornment,
} from '@mui/material'
import { setDecimalConfig } from 'src/utils/decimal'
import FormControl from '../FormControl'
import NumberInput from '../NumberInput'

setDecimalConfig()

const snapWithDecimal = (
  valueAsDecimal: Decimal,
  length: Decimal,
  snapValues?: SnapValues[],
): Decimal => {
  const lengthAsNumber = Number(length.toString())
  const valueAsNumber = Number(valueAsDecimal.toString())
  const useSnapping = !!snapValues
  if (useSnapping) {
    const snappedValue = findRelativeSnapValue(valueAsNumber, snapValues, lengthAsNumber)
    const correctedValueAsDecimal = snappedValue
      ? new Decimal(snappedValue.absolutePosition).div(length)
      : valueAsDecimal
    return correctedValueAsDecimal
  } else {
    const correctedValueAsDecimal = valueAsDecimal
    return correctedValueAsDecimal
  }
}

const newValueOnChange = (eventValue: string, lengthAsDecimal: Decimal): string => {
  // consider case that the text field is empty
  if (eventValue.length) {
    const valueAsDecimal = new Decimal(eventValue).div(lengthAsDecimal)
    const valueAsString = valueAsDecimal.toString()
    return valueAsString
  }
  return eventValue
}

interface Props extends Omit<OutlinedTextFieldProps, 'variant' | 'type'> {
  name: string
  placeholder?: string
  label?: string
  inputLabel?: string
  tooltip?: TooltipContents
  InputProps?: InputProps
  length: number
  snapValues?: SnapValues[]
  'data-cy'?: string
}

const RelativePositionField = ({
  name,
  rows = 1,
  multiline = false,
  autoFocus = false,
  placeholder = '',
  disabled = false,
  label = '',
  inputLabel,
  InputProps = {},
  snapValues,
  tooltip,
  length,
  'data-cy': dataCy,
  ...textFieldProps
}: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({ name, control })
  // internal value is stored as a string
  const [internalValue, setInternalValue] = useState(value)
  const lengthAsDecimal = new Decimal(length)

  const onChangeDebounced = useDebouncedCallback(
    (valueAsDecimal: Decimal) => {
      const correctedValueAsDecimal = snapWithDecimal(valueAsDecimal, lengthAsDecimal, snapValues)
      const correctedValueAsString = correctedValueAsDecimal.toString()
      onChange(correctedValueAsString)
      setInternalValue(correctedValueAsString)
    },
    snapValues ? 500 : 100,
  )

  const displayValue = useMemo(() => {
    return !internalValue ? internalValue : Number(internalValue) * length
  }, [internalValue, length])

  return (
    <FormControl
      data-cy={dataCy}
      label={!inputLabel ? label : undefined}
      error={error?.message}
      tooltip={tooltip}
    >
      <MuiTextField
        variant="outlined"
        onChange={event => {
          const eventValue = event.target.value
          // consider case that the text field is empty
          const newValueAsString = newValueOnChange(eventValue, lengthAsDecimal)
          if (newValueAsString.length) {
            const newValueAsDecimal = new Decimal(newValueAsString)
            setInternalValue(newValueAsString)
            onChangeDebounced(newValueAsDecimal)
          }
        }}
        inputProps={{
          step: 0.1,
        }}
        onBlur={onBlur}
        value={displayValue}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={fieldName}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        size="small"
        InputProps={{
          // eslint-disable-next-line
          inputComponent: NumberInput as any,
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
          ...InputProps,
        }}
        label={inputLabel}
        data-cy={dataCy}
        {...textFieldProps}
      />
    </FormControl>
  )
}

export { RelativePositionField, snapWithDecimal, newValueOnChange }
