import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { getInnerForcesPlot } from '@mutations'
import { buildErrorMessage } from 'src/constants'

interface Props {
  position: string
}

const InnerForcesPlot = ({ position }: Props) => {
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [expanded, setExpanded] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>('')

  const { mutate: retrieveInnerForcesPlot, isLoading } = useMutation(
    (position: string) => getInnerForcesPlot.request(projectId as string, position),
    {
      onSuccess: (blob: Blob) => {
        const url = URL.createObjectURL(blob)
        setImageUrl(url)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Abrufen des Plots'), {
          variant: 'error',
        })
      },
    },
  )

  // Clean up URL when component unmounts
  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl)
      }
    }
  }, [imageUrl])

  useEffect(() => {
    if (expanded && !imageUrl) {
      retrieveInnerForcesPlot(position)
    }
  }, [expanded, position, retrieveInnerForcesPlot, imageUrl])

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      data-cy="inner-forces-plot-accordion"
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        aria-controls="inner-forces-content"
        id="inner-forces-header"
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant="h6" align="center" sx={{ width: '100%' }}>
          Bemessungsschnittgrößen
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <Stack sx={{ width: '100%' }}>
            <Typography>Bemessungsschnittgrößen (Umhüllende)</Typography>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : imageUrl ? (
              <img src={imageUrl} alt="Inner Forces Plot" style={{ width: '100%' }} />
            ) : null}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default InnerForcesPlot
