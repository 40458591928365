import { useCallback, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Button } from '@mui/material'
import { useControlStore } from '@editorStores'

const useSelectionMode = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const toggleSelectionMode = useControlStore(state => state.toggleSelectionMode)
  const selectionMessage = useControlStore(state => state.selectionMessage)
  const selectionModeKey = useControlStore(state => state.selectionModeKey)

  useEffect(() => {
    if (selectionMessage) {
      enqueueSnackbar(selectionMessage, {
        key: selectionMessage,
        variant: 'info',
        persist: true,
        preventDuplicate: true,
        action: () => (
          <span
            style={{
              color: '#fff',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={() => toggleSelectionMode()}
            >
              Abbrechen
            </Button>
          </span>
        ),
      })
    }

    return () => closeSnackbar(selectionMessage as string)
  }, [selectionMessage])

  const unsetSelectionMode = useCallback(() => toggleSelectionMode(), [toggleSelectionMode])

  return {
    selectionModeKey,
    isSelectionMode: !!selectionMessage,
    setSelectionMode: toggleSelectionMode,
    unsetSelectionMode,
  }
}

export default useSelectionMode
