import { ReactElement, useEffect, useMemo, useState } from 'react'
import { SimpleShape } from '@scene'
import { filter, find } from 'lodash-es'
import { useTheme } from '@mui/material'
import { DraggableRectangle } from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangle'
import { HighlightableRectangle } from '@modugen/scene/lib/components/HighlightableRectangle/HighlightableRectangle'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useOrthoCamPosition } from '@modugen/scene/lib/hooks/useOrthoCamPosition'
import { RectangleXYZ } from '@modugen/scene/lib/types'
import { getCenterMultiple, getFlatShapeNormal } from '@modugen/scene/lib/utils'
import { useControlStore, useModelStore } from '@editorStores'

interface Props {
  wallGuid: string

  selectedOpenings?: string[]
  onClickOpening?: (guid: string) => void
}

const OrthographicWall = ({
  wallGuid,
  selectedOpenings = [],
  onClickOpening,
}: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const { walls } = useModelStore(state => state.model)
  const setInvisible = useModelStore(state => state.setInvisible)

  const [hoveredOpening, setHoveredOpening] = useState<null | string>(null)

  const setOrthographicDrawMode = useControlStore(state => state.setOrthographicDrawMode)
  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  // MEMOS

  const wall = useMemo(() => find(walls, { guid: wallGuid }), [walls]) as ShapeObject

  const wallNormal = useMemo(() => getFlatShapeNormal(wall.shape.points as RectangleXYZ), [wall])

  const openingsInSelectedWall = useMemo(
    () =>
      wall &&
      wall.openings &&
      Object.fromEntries(
        wall.openings.map(opening => {
          return [
            opening.guid,
            {
              is_local: opening.is_local,
              points: opening.shape.points,
            },
          ]
        }),
      ),
    [wallGuid, wall],
  )

  const wallOpenings = filter(wall.openings, opening => !opening.is_local).map(
    opening => opening.shape.points,
  )

  const wallShape = wall?.shape.points as RectangleXYZ

  const target = useMemo(() => getCenterMultiple(wallShape), [wallShape])
  const normal = useMemo(() => getFlatShapeNormal(wallShape), [wallShape])

  // EFFECTS

  useEffect(() => {
    setOrthographicDrawMode(true)
    setIsOrthographic(true)
    setInvisible(true)

    return () => {
      setOrthographicDrawMode(false)
      setIsOrthographic(false)
      setInvisible(false)
    }
  }, [])

  useOrthoCamPosition(target, normal, { zoom: 70 })

  return (
    <>
      <SimpleShape
        points={wall?.shape.points}
        color={scenePalette.elements3d.walls}
        openings={wallOpenings}
        name="wall"
        userData={{
          type: 'wall',
        }}
      />

      {openingsInSelectedWall &&
        Object.entries(openingsInSelectedWall).map(([openingGuid, opening]) => (
          <>
            {opening.is_local ? (
              <DraggableRectangle
                key={`${openingGuid}-local`}
                color="blue"
                points={opening.points as RectangleXYZ}
                rectangleProps={{
                  offset: {
                    direction: wallNormal,
                    distance: 0.5,
                  },
                }}
              />
            ) : (
              <HighlightableRectangle
                key={openingGuid}
                color={scenePalette.elements3d.openings as string}
                points={opening.points as RectangleXYZ}
                hoverable
                onHover={hovered => setHoveredOpening(hovered ? openingGuid : null)}
                outlines={hoveredOpening === openingGuid || selectedOpenings.includes(openingGuid)}
                onClick={() => onClickOpening?.(openingGuid)}
                name="opening"
                boundingColor={scenePalette.elements3d.openings as string}
                boundingWidth={2}
                cursor="pointer"
              />
            )}
          </>
        ))}
    </>
  )
}

export default OrthographicWall
