import { ReactElement } from 'react'
import { Box, useTheme } from '@mui/material'

interface Props {
  size?: number
  variant: 'enabled' | 'disabled'
  dataCy?: string
}

const ShearCheckIndicator = ({
  size = 10,
  variant,
  dataCy = 'shear-check-indicator',
}: Props): ReactElement => {
  const { palette } = useTheme()

  return (
    <Box
      borderRadius={size}
      width={size}
      height={size}
      sx={{ backgroundColor: variant === 'enabled' ? palette.success.main : palette.grey[400] }}
      data-cy={`${dataCy}-${variant}`}
    />
  )
}

export default ShearCheckIndicator
