import { ReactElement, useEffect, useMemo } from 'react'
import produce from 'immer'
import { find, remove } from 'lodash-es'
import { Divider, Stack } from '@mui/material'
import { Form } from '@ui/forms'
import { Box } from '@ui/structure'
import { useResultsStore } from '@editorStores'
import { useSelectionMode } from '@editorHooks'
import FormFields from './components/FormFields'
import LoadsSmoothing from './components/LoadsSmoothing'
import SubmitButton from './components/SubmitButton'
import { schema } from './schema'

interface Props {
  onSave: (data: MemberPositionBundle[]) => void
  isLoading?: boolean

  isLoadingPreview: boolean
  onCalculatePreview: (memberSettings: MemberSmoothingSettings) => void

  position: string
  settings: MemberPositionBundle[]

  allowLoadsSmoothing: boolean

  onClickPosition: (guid: string) => void
}

const ExportSettingsForm = ({
  settings,
  onSave,
  position,
  isLoading,
  allowLoadsSmoothing,
  isLoadingPreview,
  onCalculatePreview,
  onClickPosition,
}: Props): ReactElement => {
  const setPreviewLoads = useResultsStore(state => state.setPreviewLoads)

  const defaultValues = useMemo(() => {
    const newInterval = {
      lower: 0,
      upper: 1,
    }

    const selectedBundle = find(settings, ['representative_position', position])
    if (!selectedBundle) {
      // in case the position is not yet part of a bundle and is therefore
      // already represented by another bundle we need to remove it from the
      // other bundle and crete a new bundle with this position
      const settingsWithNewBundle = produce(settings, state => {
        for (let i = 0; i < state.length; i++) {
          remove(state[i].representative_for, el => el === position)
        }

        state.push({
          representative_position: position,
          representative_for: [],
          exported: false,
        })
      })

      return {
        bundles: settingsWithNewBundle,
        new_interval: newInterval,
      }
    }

    return { bundles: settings, new_interval: newInterval }
  }, [settings, position])

  const { unsetSelectionMode } = useSelectionMode()

  const setIntermediateBundle = useResultsStore(state => state.setIntermediateBundle)

  const storeSubscription = useMemo<StoreSubscription>(
    () => ({
      writeCallback: ({ bundles }: { bundles: MemberPositionBundle[] }) => {
        const bundle = find(bundles, bundle => bundle.representative_position === position)
        if (bundle) setIntermediateBundle(bundle)
      },
    }),
    [position, setIntermediateBundle],
  )

  useEffect(
    () => () => {
      unsetSelectionMode()
      setIntermediateBundle(null)
    },
    [],
  )

  return (
    <Form
      key={position}
      defaultValues={defaultValues}
      validationSchema={schema}
      onSubmit={({ bundles }, event) => {
        // @ts-ignore
        if (event.nativeEvent.submitter.name === 'preview') {
          const settings = find(bundles as MemberPositionBundle[], [
            'representative_position',
            position,
          ])

          if (settings && settings.smoothing_settings)
            onCalculatePreview(settings.smoothing_settings)
        } else {
          unsetSelectionMode()
          onSave(bundles)
        }
      }}
      storeSubscription={storeSubscription}
      enableReinitialize
    >
      <Stack direction="column" spacing={2}>
        <FormFields element={position} />

        {allowLoadsSmoothing && (
          <>
            <Divider />
            <LoadsSmoothing
              isLoadingPreview={isLoadingPreview}
              onClickResetPreview={() => setPreviewLoads(null)}
              position={position}
              onClickPosition={onClickPosition}
            />
          </>
        )}
      </Stack>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <SubmitButton isLoading={isLoading || false} />
      </Box>
    </Form>
  )
}

export default ExportSettingsForm
