import { ReactElement, useMemo } from 'react'
import { getPolygonNormal, getShapeMatrixTransform, getShapeObject } from '@scene'
import { DoubleSide } from 'three'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { getCenterMultiple } from '@modugen/scene/lib/utils'
import { useUIStore } from '@stores'
import { theme } from 'src/styles'
import Label from '../Label'

interface Props {
  load: AreaLoad
  onClick?: () => void
  isActive?: boolean
  label: string
}

const AreaLoadMeshElement = ({ load, onClick, isActive = false, label }: Props): ReactElement => {
  // load will be negative, hence we need to invert it here so the extrusion is
  // working
  const loadThickness = (load.force.z / 1000) * 0.5 * -1

  const points = load.area_of_attack.points
  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, [], matrixTransform),
    [points, matrixTransform],
  )

  const norm = useMemo(() => getPolygonNormal(points), [points])

  const labelPosition = useMemo(
    () => getCenterMultiple(points).addScaledVector(norm, loadThickness),
    [points, norm, loadThickness],
  )

  const showHtmlSceneLabels = useUIStore(state => state.showHtmlSceneLabels)

  return (
    <>
      <BasicMesh
        cursor="pointer"
        onClick={onClick}
        // why disabling matrixAutoUpdate should be fine:
        // https://github.com/pmndrs/react-three-fiber/issues/635#issuecomment-682439509
        matrix={matrixTransform}
        matrixAutoUpdate={false}
      >
        <extrudeGeometry
          args={[
            shapeObject,
            {
              depth: loadThickness,
              bevelEnabled: false,
            },
          ]}
        />

        <meshStandardMaterial
          side={DoubleSide}
          color={theme.palette.grey[800]}
          polygonOffset={true}
          polygonOffsetUnits={-100 + 1 * 10}
          opacity={0.4}
          transparent={true}
        />
      </BasicMesh>
      <Label
        highlighted={isActive}
        onClick={onClick}
        label={label}
        position={labelPosition.v}
        visible={showHtmlSceneLabels}
      />
    </>
  )
}

export default AreaLoadMeshElement
