import { ReactElement, ReactNode } from 'react'
import { DialogTitle, DialogContent, Dialog, DialogActions, DialogProps } from '@mui/material'

type ReducedDialogProps = Omit<DialogProps, 'open'>

interface Props {
  open: boolean
  onClose: () => void
  title?: string
  children: [ReactNode, ReactNode]
  showActions?: boolean
  dialogProps?: ReducedDialogProps
}

const DialogBase = ({
  children,
  open,
  onClose,
  title,
  showActions = true,
  dialogProps = {},
}: Props): ReactElement => {
  const Content = children[0]
  const Actions = children[1]

  return (
    <Dialog {...dialogProps} open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{Content}</DialogContent>
      {showActions && <DialogActions>{Actions}</DialogActions>}
    </Dialog>
  )
}

export default DialogBase
