import { ReactElement } from 'react'
import { Grid2, Stack, Typography } from '@mui/material'
import { InfoTooltip } from '@ui/feedback'
import { Checkbox, Label } from '@ui/forms'
import { useFormFieldChange } from '@ui/hooks'

const reducePointLoadsName = 'shear_check_settings.reduce_point_loads_close_to_supports'
const reduceShearForceName = 'shear_check_settings.reduce_shear_force'

interface Props {
  checkboxDisabled?: boolean
  onChange?: () => void
  showHintAsTooltip?: boolean
}

const ShearCheckSettingsFormFields = ({
  checkboxDisabled = false,
  showHintAsTooltip = false,

  onChange,
}: Props): ReactElement | null => {
  useFormFieldChange(reducePointLoadsName, () => onChange?.())
  useFormFieldChange(reduceShearForceName, () => onChange?.())

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1} mb={2}>
        <Typography align="center" mb={2}>
          Querkraftabminderung
        </Typography>
        {showHintAsTooltip && (
          <InfoTooltip
            text="Die hier eingestellte Querkraftabminderung wird für die kalte UND die heiße Bemessung
          angewendet, falls die Heißbemessung aktiviert ist."
          />
        )}
      </Stack>
      {!showHintAsTooltip && (
        <Typography mb={2} variant="body2" color="text.secondary">
          Die hier eingestellte Querkraftabminderung wird für die kalte UND die heiße Bemessung
          angewendet, falls die Heißbemessung aktiviert ist.
        </Typography>
      )}
      <Grid2 container spacing={2}>
        <Grid2 size={11}>
          <Stack direction="row">
            <Label>Auflagernahe Punktlasten abmindern </Label>
            <InfoTooltip
              text="der Querkraftanteil aus Punktlasten innerhalb des Abstands h zu Auflagern bleibt unberücksichtigt"
              secondLineText={
                'nicht erlaubt, wenn Ausklinkung am Auflager.' +
                (checkboxDisabled
                  ? ' Änderungen können nur in der Lokalen Ansicht vorgenommen werden'
                  : '')
              }
            />
          </Stack>
        </Grid2>

        <Grid2 size={1} display="flex" justifyContent="flex-end">
          <Checkbox
            name="shear_check_settings.reduce_point_loads_close_to_supports"
            disabled={checkboxDisabled}
            sx={{ padding: 0 }}
            data-cy="shear-check-reduce-point-loads-checkbox"
          />
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 size={11}>
          <Stack direction="row">
            <Label>Bemessungsquerkraft im Abstand h vom Auflager</Label>
            <InfoTooltip
              text="die Bemessungsquerkraft wird im Abstand h zu Auflagern bestimmt anstatt in der rechnerischen Auflagerlinie"
              secondLineText={
                'nur erlaubt, wenn Querkräfte an Oberseite des Trägers angreifen (z. B. nicht bei anschließenden Schwalbenschwänzen).' +
                (checkboxDisabled
                  ? ' Änderungen können nur in der Lokalen Ansicht vorgenommen werden'
                  : '')
              }
            />
          </Stack>
        </Grid2>
        <Grid2 size={1} display="flex" justifyContent="flex-end">
          <Checkbox
            name="shear_check_settings.reduce_shear_force"
            disabled={checkboxDisabled}
            sx={{ padding: 0 }}
          />
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default ShearCheckSettingsFormFields
