import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { submitApproval, submitDisapproval } from './mutations'

const SendItForm = () => {
  const { projectId } = useParams()
  const [openApproval, setOpenApproval] = useState(false)
  const [openDisapproval, setOpenDisapproval] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const { mutate: handleApproval } = useMutation(
    () =>
      submitApproval.request(projectId as string, {
        name,
        email,
      }),
    {
      onSuccess: async () => {
        setConfirmation(
          `Danke ${name}. Wir werden dir die Freigabe bestätigen und mit den nächsten Schritten beginnen.`,
        )
        setOpenApproval(false)
      },
    },
  )
  const { mutate: handleDisapproval } = useMutation(
    () =>
      submitDisapproval.request(projectId as string, {
        name,
        email,
        message,
      }),
    {
      onSuccess: async () => {
        setConfirmation(
          `Danke ${name} für deine Rückmeldung. Wir werden uns schnellstmöglich um die Änderungen kümmern.`,
        )
        setOpenDisapproval(false)
      },
    },
  )
  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
      <Typography variant="h6" align="center">
        Tragwerk freigeben?
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenApproval(true)}
          style={{ flex: 1 }}
        >
          Freigeben
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenDisapproval(true)}
          style={{ flex: 1 }}
        >
          Ablehnen
        </Button>
      </Stack>
      <Dialog open={openApproval} onClose={() => setOpenApproval(false)}>
        <DialogTitle>Tragwerk - Freigabe erteilen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenApproval(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => handleApproval()} color="primary">
            Absenden
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDisapproval} onClose={() => setOpenDisapproval(false)}>
        <DialogTitle>Tragwerk - Freigabe ablehnen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Deine Beschwerde an die ModuGen Herde. Hinterlasse einen Kommentar und wir sind bald für dich da."
            type="text"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDisapproval(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => handleDisapproval()} color="primary">
            Absenden
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!confirmation} onClose={() => setConfirmation('')}>
        <DialogContent>
          <DialogContentText>{confirmation}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmation('')} color="primary">
            GOT IT
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
export default SendItForm
