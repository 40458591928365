import { ReactElement, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import { findIndex, find, cloneDeep } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import { useElementLoadStore } from '@editorStores'
import { useElementType } from '@editorHooks'
import { useStructuralPlanningQueryParams } from '../../../hooks'

interface Methods {
  onAdd: () => void
  onRemove: (guid: string) => void
  onCopy: (guid: string) => void
  loads: ElementLoad[]
}

interface Props {
  children: ({ onAdd, onRemove, onCopy, loads }: Methods) => ReactElement
}

const AreaLoadFieldArray = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { loadType, selectedElements },
    actions: { setDrawAreaLoadElement, selectLoad: setLoad },
  } = useStructuralPlanningQueryParams()
  const { remove, fields } = useFieldArray({
    name: loadType as string,
  })
  const addLoad = useElementLoadStore(state => state.addLoadPerElement)
  const removeLoad = useElementLoadStore(state => state.removeLoad)
  const loads = fields as unknown as ElementLoad[]
  const selectedElementType = useElementType(selectedElements?.[0])

  const onAdd = useCallback(() => {
    const selectedElement = selectedElements?.[0]

    if (!selectedElement) return

    if (selectedElementType !== 'vertical_slabs' && selectedElementType !== 'vertical_roof_slabs') {
      enqueueSnackbar(
        'Flächenlasten können nur auf Deckenpositionen und Dachpositionen aufgeprägt werden',
        { variant: 'info' },
      )
      return
    }

    setDrawAreaLoadElement(selectedElement)
  }, [enqueueSnackbar, selectedElementType, selectedElements, setDrawAreaLoadElement])

  const onRemove = (guid: string) => {
    const index = findIndex(fields, ['guid', guid])
    remove(index)
    removeLoad(guid)
  }

  const onCopy = (guid: string) => {
    const load = find(loads, { guid }) as ElementLoad
    const newLoad = {
      ...cloneDeep(load),
      guid: uuid(),
    }
    addLoad(newLoad)
    setLoad(newLoad as ElementLoad)
  }

  return <>{children({ onAdd, onRemove, onCopy, loads })}</>
}

export default AreaLoadFieldArray
