import { ReactElement } from 'react'
import { Grid, Divider } from '@mui/material'
import { Form } from '@ui/forms'
import { Box } from '@ui/structure'
import GeneralFields from './GeneralFields'
import LayerList from './LayerList'
import { assemblySchema } from './schema'

interface Props {
  id: string
  defaultValues: Assembly
  onSubmit: (values: Assembly) => void
}

const AssemblyForm = ({ defaultValues, id, onSubmit }: Props): ReactElement => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      validationSchema={assemblySchema}
      defaultValues={{
        ...assemblySchema.getDefault(),
        ...defaultValues,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={3} display="flex">
          <Box pr={2} pb={2}>
            <GeneralFields />
          </Box>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={9}>
          <LayerList />
        </Grid>
      </Grid>
    </Form>
  )
}

export default AssemblyForm
