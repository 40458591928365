import { ReactElement, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import produce from 'immer'
import { find, findIndex, invert, every, isNull } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Typography, Stack, Button } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Form, Autocomplete, ErrorFieldBase, Label } from '@ui/forms'
import { Box } from '@ui/structure'
import { useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import {
  getProjectAssemblies,
  getAssemblyAssignment,
  getElementCrossSectionAssignment,
} from '@queries'
import { editProjectAssembly, createProjectAssembly, editAssemblyAssignment } from '@mutations'
import { CreateDialog, EditAssemblyDialog, assemblySchema } from 'src/components/manager/assemblies'
import { buildErrorMessage } from 'src/constants/errors'
import { usageToElementType } from '../../constants'

interface Props {
  selectedElements: string[]
  elementType: ElementTypes
  onClose?: () => void
  showSelectedElementsNumber?: boolean
}

const AssemblyAssignmentForm = ({
  onClose,
  selectedElements,
  elementType,
  showSelectedElementsNumber = true,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [createValues, setCreateValues] = useState<Assembly | null>(null)
  const [editValues, setEditValues] = useState<Assembly | null>(null)

  const invalidateResults = useResultsInvalidation()

  const assemblies = useSystemManagerStore(state => state.assemblies)
  const assemblyAssignment = useSystemManagerStore(state => state.assemblyAssignment)
  const assemblyOptions = useMemo(() => {
    return assemblies.reduce<
      {
        value: string
        label: string
      }[]
    >((acc, assembly) => {
      const type = usageToElementType[assembly.usage_type]
      // only show assemblies for currently selected element type
      if (type !== elementType) return acc

      return [
        ...acc,
        {
          value: assembly.guid,
          label: assembly.name,
        },
      ]
    }, [])
  }, [assemblies, elementType])

  const selectedAssemblyGuid = useMemo(() => {
    const assemblyGuids = selectedElements.map(
      elementGuid => find(assemblyAssignment, ['element_guid', elementGuid])?.assembly_guid,
    )
    const isSameAssembly = every(assemblyGuids, (value, i, array) => value === array[0])

    return isSameAssembly ? assemblyGuids[0] : null
  }, [assemblyOptions, assemblyAssignment, selectedElements])

  const handleSubmit = async ({ selectedAssemblyGuid }: { selectedAssemblyGuid: string }) => {
    const updatedAssignment = produce(assemblyAssignment, draftAssemblyAssignment => {
      selectedElements.forEach(elementGuid => {
        const index = findIndex(draftAssemblyAssignment, ['element_guid', elementGuid])

        if (index === -1) return

        draftAssemblyAssignment[index] = {
          ...draftAssemblyAssignment[index],
          assembly_guid: selectedAssemblyGuid,
        }
      })
    })

    await mutateAsync(updatedAssignment)
  }

  const { mutateAsync } = useMutation(
    (data: AssemblyAssignment[]) => editAssemblyAssignment.request(projectId as string, data),
    {
      onSuccess: async data => {
        queryClient.setQueryData(getAssemblyAssignment.getKey(projectId), () => data)
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Aufbauzuweisung erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Aufbauzuweisung'), {
          variant: 'error',
        })
      },
    },
  )

  const createMutation = useMutation(
    (data: Assembly) => createProjectAssembly.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectAssemblies.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Aufbau erfolgreich angelegt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
          variant: 'error',
        })
      },
    },
  )

  const editMutation = useMutation(
    (data: Assembly) => editProjectAssembly.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectAssemblies.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Aufbau erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{ selectedAssemblyGuid }}
        key={selectedElements.join()}
        data-cy={'form-' + elementType + '-assembly-assignment'}
      >
        {({ formState: { isDirty, isSubmitting } }) => (
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
            {showSelectedElementsNumber ? (
              <>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  Ausgewählt: {selectedElements.length}
                </Typography>
                <Typography mb={1}>
                  Weisen Sie den ausgewählten Elementen einen Aufbau zu
                </Typography>
              </>
            ) : (
              <Label>Aufbau</Label>
            )}
            <Autocomplete
              name="selectedAssemblyGuid"
              options={assemblyOptions}
              onEdit={({ value }) => setEditValues(find(assemblies, ['guid', value]) || null)}
              onAdd={() =>
                setCreateValues({
                  ...assemblySchema.getDefault(),
                  kind: assemblySchema.getDefault().kind as AssemblyKind,
                  usage_type: invert(usageToElementType)[elementType] as AssemblyTypes,
                })
              }
              onAddLabel="Neuen Aufbau anlegen"
            />
            {isNull(selectedAssemblyGuid) && (
              <Box mt={1}>
                <ErrorFieldBase
                  severity="warning"
                  message="Elemente haben unterschiedliche Aufbauten"
                />
              </Box>
            )}
            <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
              <SaveButton
                loading={isSubmitting}
                disabled={!isDirty}
                size="small"
                fullWidth
                type="submit"
              >
                Speichern
              </SaveButton>
              {onClose && (
                <Button onClick={onClose} size="small" variant="outlined" fullWidth>
                  Auswahl aufheben
                </Button>
              )}
            </Stack>
          </Box>
        )}
      </Form>
      {createValues && (
        <CreateDialog
          mutation={createMutation}
          open
          onClose={() => setCreateValues(null)}
          assembly={createValues}
        />
      )}
      {editValues && (
        <EditAssemblyDialog
          mutation={editMutation}
          open
          onClose={() => setEditValues(null)}
          assembly={editValues}
        />
      )}
    </>
  )
}

export default AssemblyAssignmentForm
