import { ReactElement } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { AddButton } from '@ui/actions'
import { useResultsInvalidation } from '@editorHooks'
import { getModel, getStiffeningIntervals, getStiffeningProposal } from '@queries'
import { buildErrorMessage } from 'src/constants/errors'
import { alignWalls } from 'src/state/mutations/alignWalls'
import { addRoof } from 'src/state/mutations/model'

const WallAlignmentForm = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const invalidateResults = useResultsInvalidation()
  const queryClient = useQueryClient()

  const { mutate: startAlignWalls, isLoading: isAligningWalls } = useMutation(
    () => {
      return alignWalls.request(projectId as string)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getModel.getKey(projectId))
        await queryClient.invalidateQueries(getStiffeningProposal.getKey(projectId))
        await queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId))

        invalidateResults(projectId as string)
        enqueueSnackbar('Wände erfolgreich ausgerichtet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Ausrichten der Wände'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutate: createNewRoofSlab, isLoading: isCreatingNewRoofSlab } = useMutation(
    () => {
      return addRoof.request(projectId as string)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getModel.getKey(projectId))

        invalidateResults(projectId as string)
        enqueueSnackbar('Dachfläche erfolgreich hinzugefügt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim hinzufügen der Dachfläche'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <>
      <Stack direction="column" justifyContent="end" spacing={1} mt={1}>
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <AddButton
            variant="contained"
            loading={isCreatingNewRoofSlab}
            onClick={() => createNewRoofSlab()}
            fullWidth
            disabled={false}
            data-cy="btn-submit-create-new-roof-slab"
          >
            Neue Dachfläche anlegen
          </AddButton>
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <LoadingButton
            variant="contained"
            loading={isAligningWalls}
            onClick={() => startAlignWalls()}
            fullWidth
            disabled={false}
            data-cy="btn-submit-align-walls"
          >
            Wände neu ausrichten
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  )
}

export default WallAlignmentForm
