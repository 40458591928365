import { ReactElement, ReactNode, useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { Scene, ModelMesh, DomainMesh, elementTypes, Snowfall } from '@scene'
import { last } from 'lodash-es'
import { Perf } from 'r3f-perf'
import { Euler, AxesHelper, Sprite, SpriteMaterial, CanvasTexture } from 'three'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { NavbarPortalLeft, NavbarPortalRight } from '@ui/navigation'
import { Box } from '@ui/structure'
import { useAppStore } from '@stores'
import {
  useControlStore,
  useModelStore,
  useElementSelectionStore,
  createInteractableByType,
  useSystemManagerStore,
} from '@editorStores'
import {
  useModelClickListeners,
  useModelHoverListeners,
  useHiddenElements,
  useCameraRotationAroundModel,
} from '@editorHooks'
import { appConfig } from 'src/constants'
import { ControlsLeft, ControlsRight } from '../Controls'

interface Props {
  children?: ReactNode
  addOns?: ReactNode
}

const SceneControlled = ({ children, addOns }: Props): ReactElement => {
  const gridEnabled = useControlStore(state => state.gridEnabled)
  const axesEnabled = useControlStore(state => state.axesEnabled)
  const isSceneBlocked = useControlStore(state => state.isSceneBlocked)

  const showSnow = useAppStore(state => state.showSnow)
  const showStats = useAppStore(state => state.showStats)

  const model = useModelStore(state => state.model)

  const domains = useModelStore(state => state.domains)
  const visibleDomains = useModelStore(state => state.visibleDomains)
  const hiddenElements = useHiddenElements()
  const interactableByTypeConfigs = useModelStore(state => state.interactableByTypeConfigs)

  const blockingElements = useModelStore(state => state.blockingElements)
  const translucent = useModelStore(state => state.translucent)
  const invisible = useModelStore(state => state.invisible)
  const isTapelineActive = useTapelineStore(state => state.isActive)
  const highlightedSecondaryIds = useElementSelectionStore(state => state.highlightedSecondaryIds)
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)
  const highlightedIds = useElementSelectionStore(state => state.highlightedIds)
  const selectionEnabled = useElementSelectionStore(state => state.selectionEnabled)

  const onModelClick = useModelClickListeners()
  const [onPointerOver, onPointerLeave] = useModelHoverListeners()

  // MEMOS

  const interactableByType = useMemo(
    () =>
      last(interactableByTypeConfigs)?.interactableByType || createInteractableByType(elementTypes),
    [interactableByTypeConfigs],
  )

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const guidToCrossSection = useMemo(() => {
    const elements = elementCrossSectionAssignment.map(assignment => ({
      ...assignment,
      shape: assignment.element_cs.shape,
    }))

    return {
      ...mapValueKey(elements, 'element_guid'),
    }
  }, [elementCrossSectionAssignment])

  useCameraRotationAroundModel(model)

  const createTextSprite = (text: string, color: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = 256
    canvas.height = 256

    if (context) {
      context.font = 'Bold 60px Arial'
      context.fillStyle = color
      context.textAlign = 'center'
      context.fillText(text, 128, 128)
    }

    const texture = new CanvasTexture(canvas)
    const spriteMaterial = new SpriteMaterial({ map: texture })
    const sprite = new Sprite(spriteMaterial)
    sprite.scale.set(2, 2, 2)

    return sprite
  }

  const AxesWithLabels = () => {
    const axesHelper = new AxesHelper(10)

    const xLabel = createTextSprite('X', '#ff0000')
    const yLabel = createTextSprite('Y', '#000000')
    const zLabel = createTextSprite('Z', '#0000ff')

    xLabel.position.set(3, -1, 0)
    yLabel.position.set(-1, 3, 0)
    zLabel.position.set(0, 0, 10)

    axesHelper.add(xLabel)
    axesHelper.add(yLabel)
    axesHelper.add(zLabel)

    return <primitive object={axesHelper} />
  }

  return (
    <Box position="relative" flexGrow={1} alignSelf="stretch" overflow="hidden" height="100%">
      {isSceneBlocked && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          sx={{
            background: 'black',
            opacity: 0.1,
            zIndex: 1,
          }}
          data-cy="scene-blocker"
        />
      )}
      {addOns}
      <Scene>
        {gridEnabled && <gridHelper args={[200, 400]} rotation={new Euler(1.5708)} />}
        {axesEnabled && <AxesWithLabels />}
        {!invisible && (
          <>
            <ModelMesh
              model={model}
              onClick={onModelClick}
              translucent={translucent}
              invisible={invisible}
              interactableByType={interactableByType}
              blockingElements={blockingElements}
              hiddenElements={hiddenElements}
              noPointerInteractions={isTapelineActive}
              selectedElementIds={selectedStandAloneIds}
              highlightedIds={highlightedIds}
              highlightedSecondaryIds={highlightedSecondaryIds}
              onPointerOver={onPointerOver}
              onPointerLeave={onPointerLeave}
              applyThickness
              guidToCrossSection={guidToCrossSection}
            />
            <DomainMesh
              domains={domains}
              domainGuids={visibleDomains}
              hiddenElements={hiddenElements}
            />
          </>
        )}

        {appConfig.enableSnowEasterEgg && showSnow && <Snowfall />}

        {showStats && <Perf position="top-left" />}

        {children}
      </Scene>
      <NavbarPortalLeft>
        <ControlsLeft selectionControls={selectionEnabled} />
      </NavbarPortalLeft>
      <NavbarPortalRight>
        <ControlsRight />
      </NavbarPortalRight>
    </Box>
  )
}

export default SceneControlled
