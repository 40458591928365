import { ReactElement } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useModelStore } from '@editorStores'
import { downLoadFile } from '@utils'
import { computeExportDocumentTask, getExportDocument } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import ExportItem from '../ExportItem'

interface Props {
  title: string
  apiDocumentName: string
  type: BlobType
  filename: string
  params?: Record<string, string | number | boolean>
  disabled?: boolean
  extension?: string
  'data-cy'?: string
}

const DownloadDocumentItemWithTask = ({
  title,
  apiDocumentName,
  type,
  filename,
  params,
  disabled = false,
  extension = 'txt',
  'data-cy': dataCy,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const project = useModelStore(state => state.project)

  const { enqueueSnackbar } = useSnackbar()

  const { mutate: downloadExportDocument, isLoading } = useMutation(
    async ({ type, filename }: { type: BlobType; filename: string }) => {
      const taskPath = apiDocumentName
      const documentPath = `document/${apiDocumentName}`
      await computeExportDocumentTask.request(projectId as string, taskPath, params)
      const data = await getExportDocument.request(projectId as string, documentPath, params)
      return {
        data,
        type,
        filename: `${filename} - ${project.name || projectId}.${extension}`,
      }
    },
    {
      onSuccess: async ({ data, type, filename }) => {
        if (!data) {
          enqueueSnackbar('Ergebnisse noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(data, type, filename)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Abrufen des Dokuments'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <ExportItem
      isLoading={isLoading}
      onClick={() => downloadExportDocument({ type, filename })}
      title={title}
      disabled={disabled}
      data-cy={dataCy}
    />
  )
}

export default DownloadDocumentItemWithTask
