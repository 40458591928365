import { ReactElement, ReactNode } from 'react'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'
import { StoreReInitializer } from 'src/state/utils'
import { validate } from '../middleware'
import { createInitialState } from '../utils'

interface Props {
  children: ReactNode
  elementLabels: ElementLabel[]
  positionLabels: PositionLabel[]
}

interface ServerState {
  elementLabels: ElementLabel[]
  positionLabels: PositionLabel[]
}

interface LabelsStoreInitialState {
  elementLabels: ElementLabel[]
  positionLabels: PositionLabel[]
}

const initialState: LabelsStoreInitialState = {
  elementLabels: [],
  positionLabels: [],
}

interface LabelsStoreStoreType extends LabelsStoreInitialState {
  setElementLabels: (elementLabels: ElementLabel[]) => void
  setPositionLabels: (positionLabels: PositionLabel[]) => void
}

const createStore = (serverState: ServerState) => {
  return create(
    validate(
      combine(createInitialState(serverState, initialState), set => ({
        setElementLabels: (elementLabels: ElementLabel[]) => set({ elementLabels }),
        setPositionLabels: (positionLabels: PositionLabel[]) => set({ positionLabels }),
      })),
    ),
  )
}

const { Provider, useStore: useLabelsStore, useStoreApi } = createContext<LabelsStoreStoreType>()

const LabelsStoreProvider = ({ children, ...props }: Props): ReactElement => (
  <Provider createStore={() => createStore(props)}>
    <StoreReInitializer data={props} storeApi={useStoreApi} />
    {children}
  </Provider>
)

export { LabelsStoreProvider, useLabelsStore }
