import { ReactElement, useRef, useState, useEffect } from 'react'
import * as React from 'react'
import { getPolygonNormal, SimpleShape, TargetPlane } from '@scene'
import { equalizeClosePolygonEdgesHeight, pointToPolygonArea } from '@structuralPlanningUtils'
import { some } from 'lodash-es'
import { Object3D } from 'three'
import { useTheme } from '@mui/material'
import {
  DraggableRectangle,
  DraggableRectangleRef,
} from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangle'
import { RectangleHandles } from '@modugen/scene/lib/components/DraggableRectangle/types'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useControlStore } from '@editorStores'
import { useTypeInteraction } from '@editorHooks'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'
import useRoofDrawingTargets from './hooks'
import { prepareRoofShapeFromPoints } from './utils'

interface CurrentDrawState {
  isCreating?: boolean
  rectangleRef?: React.RefObject<DraggableRectangleRef>
  widthAxis?: ImmutableVector3
  heightAxis?: ImmutableVector3
  drawTargetGuid?: string
  originalRoofPoints?: ImmutableVector3[]
}

enum RectangleElements {
  Line = 'RectangleLine',
  Handle = 'RectangleHandle',
}

interface Props {
  onRoofDrawStart?: () => void
  onRoofDrawEnd?: () => void
  resetMode: () => void

  onElementDrawn: (target: string, points: ImmutableVector3[]) => void
  type: 'vertical_roof_slabs' | 'vertical_slabs'
  drawElements: Array<ElementTypes>
  clipPoints?: boolean
}

const minShapeSize = 0.1
const zOffset = 0.01
const drawTargetName = 'draw-target'

const RectangularShapeDrawer = ({
  onRoofDrawStart,
  onRoofDrawEnd,

  onElementDrawn,
  type,
  drawElements,
  clipPoints = false,
}: Props): ReactElement => {
  const setSnappingFeaturesEnabled = useControlStore(state => state.setSnappingFeaturesEnabled)
  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const setIsDrawingActive = useControlStore(state => state.setIsDrawingActive)

  const setAdditionalSnapTargets = useTapelineStore(state => state.setAdditionalSnapTargets)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const [enableIndicator, setEnableIndicator] = useState(true)

  const { scenePalette } = useTheme()
  const drawControllerRef = useRef<DrawControllerRef>(null)
  const currentDrawState = useRef<CurrentDrawState>({})
  const rectangleRef = useRef<DraggableRectangleRef>(null)

  const [drawTarget, setDrawTarget] = useState<{
    guid: string
    points: ImmutableVector3[]
  } | null>()

  const targets = useRoofDrawingTargets()

  // EFFECTS

  useEffect(() => {
    setIsDrawingActive(true)

    return () => setIsDrawingActive(false)
  }, [])

  useEffect(() => {
    if (drawTarget && !isTapelineActive) {
      setSnappingFeaturesEnabled({
        snapOrthogonal: false,
        snapToCornersAndEdges: true,
        snapToAngle: false,
        snapToTapelineCenters: false,
      })
    }

    return () => {
      setSnappingFeaturesEnabled({
        snapOrthogonal: true,
        snapToCornersAndEdges: true,
        snapToAngle: true,
        snapToTapelineCenters: true,
      })
    }
  }, [drawTarget, isTapelineActive])

  useEffect(() => {
    setAdditionalSnapTargets(targets)

    return () => {
      setAdditionalSnapTargets(undefined)
    }
  }, [targets])

  // EVENTS/CALLBACKS

  const isValidDrawTarget = (object: Object3D) => {
    if (drawTarget) {
      return object.name === drawTargetName
    }

    const { current: tscurr } = currentDrawState

    const isSameTarget = object.name === tscurr.drawTargetGuid

    if (tscurr.isCreating && !isSameTarget) return false

    if (!drawElements.includes(object.userData?.elementType)) return false

    return true
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState
    const { current: tscurr } = currentDrawState

    setEnableIndicator(!!drawTarget)
    if (drawPoint && drawTarget && tscurr.isCreating && tscurr.rectangleRef?.current) {
      tscurr.rectangleRef.current.updateActiveHandle(
        new ImmutableVector3(drawPoint.x, drawPoint.y, drawPoint.z + zOffset),
        tscurr.widthAxis as ImmutableVector3,
        tscurr.heightAxis as ImmutableVector3,
      )
    }
  }

  const onDrawStart = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState

    if (drawPoint && drawTarget && rectangleRef.current && !currentDrawState.current.rectangleRef) {
      const guid = drawTarget.object.name
      const { points } = drawTarget.object.userData as { points: ImmutableVector3[] }

      const p1 = points[0]
      const p2 = points[1]
      const normal = getPolygonNormal(points)
      const widthAxis = p2.sub(p1).normalize()
      const heightAxis = normal.cross(widthAxis)

      currentDrawState.current = {
        isCreating: true,
        rectangleRef: rectangleRef,
        widthAxis,
        heightAxis,
        drawTargetGuid: drawTarget.object.name,
        originalRoofPoints: points,
      }

      rectangleRef.current.setHandleAndStartDragging(
        RectangleHandles.End,
        new ImmutableVector3(drawPoint.x, drawPoint.y, drawPoint.z + zOffset),
      )
      setDrawTarget({
        guid,
        points,
      })
      onRoofDrawStart?.()
    }
  }

  const onDrawEnd = () => {
    setDrawTarget(null)

    const { current: tscurr } = currentDrawState

    if (
      tscurr.isCreating &&
      tscurr.rectangleRef?.current &&
      // check for active handle to avoid processing mouse up events that were
      // not related to a draw action
      tscurr.rectangleRef?.current.activeHandle &&
      drawTarget
    ) {
      const points = tscurr.rectangleRef.current
        .stopDraggingAndGetPoints()
        .map(p => new ImmutableVector3(p.x, p.y, p.z - zOffset))

      const [start, , end] = points

      // sometimes there is a really slight missmatch of the z value of the
      // upper/lower points. They are not exactly on the roof slab, hence we
      // need to project it to the original slab again
      const { originalRoofPoints } = currentDrawState.current as {
        originalRoofPoints: ImmutableVector3[]
      }

      const pointsOnRoofSlab = points.map(p =>
        pointToPolygonArea(p, originalRoofPoints, clipPoints),
      )

      // even after projecting the points there is a really really slight
      // missmatch of z. Hence we equalize it here if there are similar heights.
      const pointsEqualized = equalizeClosePolygonEdgesHeight(pointsOnRoofSlab)

      if (start.distanceTo(end) >= minShapeSize) {
        const sortedPoints = prepareRoofShapeFromPoints(pointsEqualized)

        onElementDrawn(drawTarget.guid, sortedPoints)
      }
    }

    onRoofDrawEnd?.()
    currentDrawState.current = {}
  }

  useStructuralPlanningDrawerEsc(() => {
    setDrawTarget(null)
    drawControllerRef.current?.abortDrawing()
    currentDrawState.current = {}
  }, !!drawTarget)

  useTypeInteraction(type)

  return (
    <>
      {/* DRAWING */}
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={false}
        enableIndicator={enableIndicator}
        color={scenePalette.elements3d.vertical_slabs as string}
        additionalSnapTargets={targets}
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        isValidDrawTarget={isValidDrawTarget}
        isValidDrawStart={intersections => {
          return some(intersections, intersection =>
            drawElements.includes(intersection.object.userData?.elementType),
          )
        }}
        snapAngle={5}
      />
      <DraggableRectangle
        ref={rectangleRef}
        // define rectangle far out of user sight in order to prevent flickering
        // of length indicator at 0,0,0
        points={[
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
          new ImmutableVector3(1000, 1000, 1000),
        ]}
        rectangleName={RectangleElements.Line}
        handleName={RectangleElements.Handle}
        isVisible={!!drawTarget}
        color={scenePalette.elements3d.vertical_slabs as string}
        showIndicators={false}
        rectangleProps={{
          opacity: 1,
        }}
      />

      {drawTarget && (
        <>
          {clipPoints ? (
            <SimpleShape
              points={drawTarget.points}
              translucent
              name={drawTargetName}
              meshMaterialProps={{ opacity: 0 }}
            />
          ) : (
            <TargetPlane points={drawTarget.points} name={drawTargetName} />
          )}
        </>
      )}
    </>
  )
}

export default RectangularShapeDrawer
