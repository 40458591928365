import { ReactElement, useCallback, useMemo } from 'react'
import { ColorRepresentation, Vector3 } from 'three'
import { useTheme } from '@mui/material'
import { toImmutable } from '@modugen/scene/lib/utils'
import { ThreeEvent } from '@react-three/fiber'
import { useSceneStore } from 'src/components/pages/Editor/stores/sceneStore'
import MemberMeshElement from '../MemberMeshElement'
import SimpleCylinderMesh from '../SimpleCylinderMesh'

const defaultPositionThickness = 0.15

interface Props {
  data: Position
  height?: number
  width?: number
  position_type: 'rip' | 'lintel'
  outlines?: boolean
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  isSelected?: boolean
  isHighlighted?: boolean
  isTarget?: boolean
  visible?: boolean
  checkPosition?: number
}

const PositionMeshElement = ({
  data,
  height,
  width,
  position_type,
  onClick,
  checkPosition,
  isSelected = false,
  isHighlighted = false,
  isTarget = false,
  visible = true,
}: Props): ReactElement => {
  const theme = useTheme()

  const color = useMemo(() => {
    if (isHighlighted) return theme.scenePalette.selection
    if (isTarget) return theme.scenePalette.elements3d.targets
  }, [isHighlighted, isTarget, theme.scenePalette.elements3d.targets, theme.scenePalette.selection])

  const outlinesColor = useMemo(
    () => (isSelected ? theme.scenePalette.highlightDark : undefined),
    [isSelected, theme.scenePalette.highlightDark],
  )

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[data.position_guid], [data.position_guid]),
  ) as ColorRepresentation | undefined

  const getPositionVectorFromCheckPosition = (checkPosition: number) => {
    const length = data.start.distanceTo(data.end)
    const absolutePosition = length * checkPosition

    // Rips only go in the z direction
    if (position_type === 'rip')
      return new Vector3(data.start.x, data.start.y, data.start.z + absolutePosition)

    // Lintels can go in x or y direction depending on orientation of the wall
    if (data.start.x !== data.end.x)
      return new Vector3(data.start.x + absolutePosition, data.start.y, data.start.z)
    else return new Vector3(data.start.x, data.start.y + absolutePosition, data.start.z)
  }

  return (
    <>
      <MemberMeshElement
        name={data.position_guid}
        start={data.start}
        end={data.end}
        coordinateSystem={data.coordinate_system}
        userData={{
          guid: data.position_guid,
          elementType: position_type,
        }}
        onClick={onClick}
        cursor={onClick ? 'pointer' : undefined}
        color={mappedColor || color}
        outlinesColor={outlinesColor}
        height={height || defaultPositionThickness}
        width={width || defaultPositionThickness}
        visible={visible}
        outlines={isSelected}
      />
      {isSelected && checkPosition != null && visible && (
        <SimpleCylinderMesh
          position={toImmutable(getPositionVectorFromCheckPosition(checkPosition))}
          isActive={true}
          color="red"
          max_scale={7}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={() => {}}
          disableDepthTest={true}
        />
      )}
    </>
  )
}

export default PositionMeshElement
