import { ReactElement } from 'react'
import { Button } from '@mui/material'
import SaveButton from '@ui/actions/SaveButton'
import { DialogBase } from '@ui/feedback'
import AssemblyVibrationCheckSettingsFormFields from './components/AssemblyVibrationCheckSettingsFormFields'

interface Props {
  open: boolean
  onReset: () => void
  onClose: () => void
}

const VibrationCheckSettingsDialog = ({ open, onReset, onClose }: Props): ReactElement => {
  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={`Schwingungsnachweise`}
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <AssemblyVibrationCheckSettingsFormFields />
      <>
        <Button onClick={onReset} variant="contained">
          Zurücksetzen
        </Button>
        <SaveButton onClick={onClose}>Speichern</SaveButton>
      </>
    </DialogBase>
  )
}

export default VibrationCheckSettingsDialog
