import { ReactElement } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Collapse,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@ui/structure'
import AdditionalCheckInformation from '../AdditionalCheckInformation'
import LoadCaseAbbreviation from '../LoadCaseAbbreviation'
import ShearCheckSettingsFormFields from './components/ShearCheckSettingsFormFields'

interface Props {
  typeReadable: string
  check: StandardPositionCheck
  index: number
  isOpen: boolean
  setOpenIndex: (index: number | null) => void
  checkboxDisabled?: boolean
  openCheck: (checkPosition?: number) => void
}

const ShearCheckRow = ({
  typeReadable,
  check,
  isOpen,
  index,
  setOpenIndex,
  checkboxDisabled = false,
  openCheck,
}: Props): ReactElement => {
  const { max_utilization, relative_position, decisive_load_case_combination } = check

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        onClick={() => {
          if (isOpen) {
            openCheck(undefined)
            setOpenIndex(null)
          } else {
            openCheck(check.relative_position)
            setOpenIndex(index)
          }
        }}
        tabIndex={-1}
        key={index}
        sx={{ '& > .MuiTableCell-root': { borderBottom: 0, paddingY: 0 } }}
      >
        <TableCell sx={{ paddingLeft: 0 }}>
          <IconButton
            size="small"
            onClick={() => {
              setOpenIndex(isOpen ? null : index)
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ paddingLeft: 0 }}>
          <Tooltip title={typeReadable} placement="top">
            <Typography>{typeReadable}</Typography>
          </Tooltip>
        </TableCell>

        <TableCell>{max_utilization.toFixed(2)}</TableCell>

        <TableCell>{relative_position.toFixed(2)}</TableCell>
      </TableRow>

      <TableRow sx={{ '& > .MuiTableCell-root': { mb: 20 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box>
              <Grid container mt={0} ml={0}>
                {decisive_load_case_combination?.data && decisive_load_case_combination.data && (
                  <Typography>
                    {decisive_load_case_combination.data.map(([v1, v2, loadCase], i) => {
                      return (
                        <>
                          {v1}*{v2}*
                          <LoadCaseAbbreviation key={i} combination={loadCase} />
                          {i !== decisive_load_case_combination.data.length - 1 && ' + '}
                        </>
                      )
                    })}
                  </Typography>
                )}
              </Grid>

              <Stack my={2} spacing={2}>
                <AdditionalCheckInformation check={check} />
                <ShearCheckSettingsFormFields checkboxDisabled={checkboxDisabled} />
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ShearCheckRow
