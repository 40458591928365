import { useRef, useEffect } from 'react'
import {
  Vector3,
  ArrowHelper,
  MeshBasicMaterial,
  ColorRepresentation,
  Mesh,
  BufferGeometry,
} from 'three'
import { useTheme } from '@mui/material'
import { useUIStore } from '@stores'
import Label from '../Label'

interface Props {
  direction: Vector3
  origin: Vector3
  length: number
  label?: string
  color?: string
  highlighted?: boolean
  headLength?: number
  headWidth?: number
  onClick?: () => void
}

const Arrow = ({
  direction,
  origin,
  length,
  label,
  color,
  onClick = () => null,
  highlighted,
  headLength = 0.15,
  headWidth = 0.1,
}: Props) => {
  const arrowRef = useRef<ArrowHelper>(null)
  const theme = useTheme()
  const displayDirection = direction.clone().setZ(-1)
  const arrowColor = color || theme.palette.common.black

  const showHtmlSceneLabels = useUIStore(state => state.showHtmlSceneLabels)

  useEffect(() => {
    if (!arrowRef.current) return

    const material = arrowRef.current.cone.material as MeshBasicMaterial
    material.opacity = 0.5
    material.transparent = true
  }, [])

  return (
    <group key={length}>
      <arrowHelper
        ref={arrowRef}
        args={[
          displayDirection,
          origin,
          length,
          arrowColor as ColorRepresentation,
          headLength,
          headWidth,
        ]}
        cone={new Mesh(new BufferGeometry(), new MeshBasicMaterial({ depthTest: false }))}
      />
      {label && (
        <Label
          highlighted={highlighted}
          onClick={onClick}
          label={label}
          position={origin}
          visible={showHtmlSceneLabels}
        />
      )}
    </group>
  )
}

export default Arrow
