import { ReactElement, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Typography, Stack, IconButton } from '@mui/material'

interface Props {
  title: string
  count: number
  onVisible: (visible: boolean) => void
  onDelete: () => void
}

const SelectedItem = ({ title, count, onVisible, onDelete }: Props): ReactElement | null => {
  const [visible, setVisible] = useState(true)

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p={1}
      borderRadius={1}
      sx={{ backgroundColor: 'grey.200' }}
    >
      <Stack direction="row" alignItems="center">
        <Typography fontWeight="600">{title}:</Typography>
        &nbsp;{count} Elemente
      </Stack>
      <Stack direction="row">
        <IconButton
          onClick={() => {
            onVisible(!visible)
            setVisible(!visible)
          }}
          size="small"
          sx={{
            alignSelf: 'center',
          }}
        >
          {visible ? <Visibility fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
        </IconButton>
        <IconButton
          onClick={() => {
            onDelete()
          }}
          size="small"
          sx={{
            margin: -1,
            alignSelf: 'center',
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default SelectedItem
