import { ReactElement } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import { AssemblyList } from 'src/components/manager/assemblies'

interface Props {
  assemblies: Assembly[]
  assignments: AssemblyAssignment[]

  onDelete: (assembly: Assembly) => void
  onDownload: (assembly: Assembly) => Promise<void>
  onStore: (assembly: Assembly) => Promise<CLTAssembly | TimberFrameAssembly>
  createMutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
  editMutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
}

const AssemblySelection = ({
  assemblies,
  assignments,
  onDelete,
  onDownload,
  onStore,
  createMutation,
  editMutation,
}: Props): ReactElement => {
  const { setValue } = useFormContext()

  const selection = useWatch({}) as SelectedAssemblies

  return (
    <AssemblyList
      assemblies={assemblies}
      assignments={assignments}
      onSelect={(category, assembly, isSelected) =>
        setValue(`${category}.standard`, isSelected ? assembly.guid : undefined)
      }
      onDelete={onDelete}
      onDownload={onDownload}
      editMutation={editMutation}
      createMutation={createMutation}
      onStore={onStore}
      selectedAssemblies={selection}
    />
  )
}

export default AssemblySelection
