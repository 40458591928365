import { ReactElement, useRef, useState } from 'react'
import * as React from 'react'
import { getLineCoordinateSystem } from '@editorUtils'
import { useTapelineSnapTargets } from '@scene'
import { max, some, toNumber } from 'lodash-es'
import { Object3D, Plane, Vector2 } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import { DraggableLineRef } from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import {
  SnapTargetElementType,
  useDefault2DSnapConfigForElementType,
} from 'src/components/pages/Editor/hooks/useDefaultSnapConfigForElementType'
import getPlaneLineIntersection from 'src/components/pages/Editor/utils/getPlaneLineIntersection'
import { useModelSnapTargets, useRipSnapTargets, useSlabElevation } from '../../hooks'

interface TransientWallState {
  isCreating?: boolean
  isFirstMove?: boolean
  isUpdating?: boolean
  lineRef?: React.RefObject<DraggableLineRef>
  drawStartSnapGuid?: string
}

enum RectangleElements {
  Line = 'RectangleLine',
  Handle = 'RectangleHandle',
}

interface Props {
  storey: string
}

const ColumnDrawer2D = ({ storey }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const transientWallState = useRef<TransientWallState>({})

  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)
  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)

  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const [enableIndicator, setEnableIndicator] = useState(true)
  const addColumn = useModelStore(state => state.addColumn)

  const slabElevation = useSlabElevation(storey)

  const snapTargets = useModelSnapTargets({ xyOnly: true })
  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const ripSnapTargets = useRipSnapTargets()

  const model = useModelStore(state => state.model)

  const maxStorey = max(
    Object.keys(model.storey_boundaries).map(s => toNumber(s)),
  )?.toString() as string

  const isDrawnWallSegment = (object?: Object3D): boolean => {
    if (!object) return false
    return (Object.values(RectangleElements) as string[]).includes(object.name)
  }

  const isValidDrawTarget = (object: Object3D): boolean => {
    let isValidTarget = !isDrawnWallSegment(object)

    const { current: tscurr } = transientWallState

    // exclude the active line in case we are creating or updating a line
    if ((tscurr.isCreating || tscurr.isUpdating) && tscurr.lineRef?.current) {
      isValidTarget =
        isValidTarget &&
        object !== tscurr.lineRef.current.startHandleRef.current &&
        object !== tscurr.lineRef.current.endHandleRef.current
    }

    return isValidTarget
  }

  const onDrawClick = (transientDrawState: TransientDrawState) => {
    const { drawPoint } = transientDrawState

    if (!drawPoint || !slabElevation) return

    let zEnd = slabElevation.upper

    // in case we draw a column in the roof we need to project the draw point
    // onto the planes of the roof slab to find the end
    if (storey === maxStorey) {
      const roofPlanes = model.roof_slabs.map(roofSlab => {
        const points = roofSlab.shape.points
        return new Plane().setFromCoplanarPoints(points[0].v, points[1].v, points[2].v)
      })
      roofPlanes.forEach(plane => {
        const intersection = getPlaneLineIntersection(plane, new Vector2(drawPoint.x, drawPoint.y))
        if (intersection && intersection.z < zEnd && intersection.z > slabElevation.lower) {
          // Only override z-end if the roof-plane intersection is lower than the currently found endpoint.
          // This prevents overriding the zEnd with the intersection of an arbitrary other plane, since all
          // planes are infinite and hence intersecting.
          zEnd = intersection.z
        }
      })
    }

    const start = new ImmutableVector3(drawPoint.x, drawPoint.y, slabElevation.lower)
    const end = new ImmutableVector3(drawPoint.x, drawPoint.y, zEnd)

    const column = {
      guid: uuid(),
      storey,
      shape: {
        start,
        end,
      },
      is_local: true,
      coordinate_system: getLineCoordinateSystem(start, end),
      type: 'columns' as ElementTypes,
    }

    setActiveElement(column.guid)
    addColumn(column)
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { validIntersections } = transientDrawState

    // check if the mouse intersects a wall handle
    const intersectedWallHandle = validIntersections?.find(
      intersection => intersection.object.name === RectangleElements.Handle,
    )

    // hide the indicator and move the draw point to the handle if yes
    if (intersectedWallHandle) {
      if (enableIndicator) setEnableIndicator(false)
    } else {
      if (!enableIndicator) setEnableIndicator(true)
    }
  }

  // EFFECTS
  useDefault2DSnapConfigForElementType(SnapTargetElementType.COLUMNS)

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        enableIndicator={enableIndicator}
        color={scenePalette.elements3d.vertical_slabs as string}
        xyOnly
        additionalSnapTargets={[
          ...snapTargets,
          ...tapelineSnapTargets,
          ...ripSnapTargets,
          ...tapelineCenterTargets,
        ]}
        onDrawEnd={onDrawClick}
        onMouseMove={onDrawMouseMove}
        isValidDrawTarget={isValidDrawTarget}
        allowNegative={true}
        snapAngle={5}
        orthoSnap={snapOrthogonal}
        indicatorType={'crosshair'}
        isValidDrawStart={intersections => {
          return !some(
            intersections,
            intersection => intersection.object.userData.type === 'column',
          )
        }}
      />
    </>
  )
}

export default ColumnDrawer2D
