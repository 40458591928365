import { ReactNode, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useControlStore } from '@editorStores'

interface Props {
  children: ReactNode
}

export const WidgetPortal = ({ children }: Props): ReactElement => {
  const widgetPortalElement = useControlStore(state => state.widgetPortalElement)

  return <>{widgetPortalElement && createPortal(<>{children}</>, widgetPortalElement)}</>
}
