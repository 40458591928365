import { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { get, some, isString } from 'lodash-es'
import { AccordionProps, Accordion } from '@mui/material'

interface Props extends AccordionProps {
  fields: string[] | string
}

const FormAccordion = ({ fields, sx = {}, ...accordionProps }: Props): ReactElement => {
  const {
    formState: { errors, submitCount },
    watch,
  } = useFormContext()
  const isFieldString = isString(fields)
  const fieldWatch = watch(isFieldString ? [fields] : fields)

  const hasErrors = useMemo(() => {
    if (!submitCount) return false
    if (isFieldString) return !!get(errors, fields)

    return some(fields, fieldName => !!get(errors, fieldName))
  }, [errors, fields, fieldWatch, submitCount])

  return (
    <Accordion
      {...accordionProps}
      sx={{
        '& .MuiAccordionSummary-root': {
          color: ({ palette }) => (hasErrors ? `${palette.error.main} !important` : ''),
          // background: ({ palette }) => (hasErrors ? `${palette.error.light} !important` : ''),
        },
        borderColor: ({ palette }) => (hasErrors ? `${palette.error.main} !important` : ''),
        ...sx,
      }}
    />
  )
}

export default FormAccordion
