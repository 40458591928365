import { ReactElement, useMemo } from 'react'
import { filter } from 'lodash-es'
import { ColorRepresentation, LineCurve3 } from 'three'
import { useTheme } from '@mui/material'
import { createMeshStandardMaterialMemoized } from '../utils'

interface Props {
  domains: Domain[]
  domainGuids?: Set<string>
  hiddenElements?: Set<string>
  color?: string | ColorRepresentation
  width?: number
}

const DomainMesh = ({
  domains,
  domainGuids,
  hiddenElements = new Set(),
  color,
  width = 0.05,
}: Props): ReactElement => {
  const theme = useTheme()
  const geometries = useMemo(() => {
    const visibleDomains = filter(
      domains,
      ({ guid, element_guid }) =>
        !hiddenElements.has(element_guid as string) && (!domainGuids || domainGuids.has(guid)),
    )

    return visibleDomains.map(({ start, end }) => {
      return new LineCurve3(start.v, end.v)
    })
  }, [domains, domainGuids, hiddenElements])

  const material = createMeshStandardMaterialMemoized({
    color: (color || theme.scenePalette.selection) as ColorRepresentation | undefined,
    polygonOffset: true,
    polygonOffsetUnits: -250,
  })

  return (
    <>
      {geometries.map((geometry, index) => (
        <mesh key={index} material={material}>
          <tubeGeometry args={[geometry, 100, width, 50, false]} />
        </mesh>
      ))}
    </>
  )
}

export default DomainMesh
