import { ReactElement } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { LoadingIndicator } from '@ui/feedback'
import { useResultsInvalidation } from '@editorHooks'
import { getSeismicSettings } from '@queries'
import { saveSeismicSettings, uploadSeismicResultsFile } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import useGlobalLoadsQueryParams from '../../hooks/useGlobalLoadsQueryParams'
import SeismicSettingsForm from './components/SeismicSettingsForm'
import {
  seismicMethodsTabConfig,
  SeismicMethodTab,
} from './components/SeismicSettingsForm/constants'
import SimpflifiedSeismicTab from './components/SimplifiedSeismicTab'

const SeismicSettingsTab = (): ReactElement => {
  const defaultValue: SeismicMethodTab = 'excel'
  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const {
    params: { activeSeismicMethodTab },
    actions: { setActiveSeismicMethodTab },
  } = useGlobalLoadsQueryParams()

  const { isFetching, data } = useQuery(
    getSeismicSettings.getKey(projectId),
    () => getSeismicSettings.request(projectId),
    {
      onError: () => enqueueSnackbar('Fehler beim Laden der Erdbebenlasten', { variant: 'error' }),
      refetchOnMount: 'always',
    },
  ) as { isFetching: boolean; data?: SeismicSettings }

  const { isLoading, mutate } = useMutation(
    (data: SeismicSettings) => saveSeismicSettings.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        queryClient.invalidateQueries(getSeismicSettings.getKey(projectId))
        enqueueSnackbar('Erdbebenlasten erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Erdbebenlasten'), {
          variant: 'error',
        })
      },
    },
  )

  const { isLoading: isUploading, mutate: mutateUploadFile } = useMutation(
    (data: File) => uploadSeismicResultsFile.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        enqueueSnackbar('H-Lasten aus Erdbeben aus RFEM erfolgreich importiert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Upload der H-Lasten aus Erdbeben'), {
          variant: 'error',
        })
      },
    },
  )

  const onSubmit = (data: SeismicSettings) => {
    if (data.seismic_results_file !== undefined) {
      mutateUploadFile(data.seismic_results_file)
    } else {
      mutate(data)
    }
  }

  if (isFetching) return <LoadingIndicator />

  return (
    <TabContext value={activeSeismicMethodTab || defaultValue}>
      <TabList onChange={(_, value) => setActiveSeismicMethodTab(value)}>
        <Tab {...seismicMethodsTabConfig.excel}></Tab>
        <Tab {...seismicMethodsTabConfig.simplified}></Tab>
      </TabList>
      <TabPanel value={seismicMethodsTabConfig.excel.value} sx={{ p: 0 }}>
        <SeismicSettingsForm
          isLoading={isFetching}
          isSaving={isLoading || isUploading}
          onSubmit={onSubmit}
          data={data}
        />
      </TabPanel>
      <TabPanel value={seismicMethodsTabConfig.simplified.value} sx={{ p: 0 }}>
        <SimpflifiedSeismicTab />
      </TabPanel>
    </TabContext>
  )
}

export default SeismicSettingsTab
