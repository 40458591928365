import { useMemo } from 'react'
import { TargetPlane } from '@scene'
import { reduce } from 'd3'
import { v4 as uuid } from 'uuid'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'
import {
  SnapTargetElementType,
  useDefault2DSnapConfigForElementType,
} from 'src/components/pages/Editor/hooks/useDefaultSnapConfigForElementType'
import RectangularShapeDrawer2D from '../RectangularShapeDrawer2D'

interface Props {
  storey: string
}

const VerticalRoofDrawer2D = ({ storey }: Props) => {
  const model = useModelStore(state => state.model)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const addVerticalRoofSlab = useModelStore(state => state.addVerticalRoofSlab)
  const elements = useGuidToElement()
  const drawRoofOnElement = useEditElementStore(state => state.drawRoofOnElement)

  // artificial id used for storey
  const storeyId = useMemo(() => uuid(), [storey])

  const storeyPoints = useMemo(() => {
    const upperBoundary = model.storey_boundaries[storey][1]
    return [
      new ImmutableVector3(-100, -100, upperBoundary),
      new ImmutableVector3(-100, 100, upperBoundary),
      new ImmutableVector3(100, 100, upperBoundary),
      new ImmutableVector3(100, -100, upperBoundary),
    ]
  }, [model.storey_boundaries, storey])

  const storeyTarget = useMemo(() => {
    return {
      [storeyId]: storeyPoints,
    }
  }, [model.storey_boundaries, storeyId])

  const targetElements = useMemo(
    () =>
      reduce(
        [...model.roof_slabs, ...model.slabs],
        (collector, element) => ({ ...collector, [element.guid]: element.shape.points }),
        {} as Record<string, ImmutableVector3[]>,
      ),
    [model],
  )
  useDefault2DSnapConfigForElementType(SnapTargetElementType.VERTICAL_ROOF_SLABS)

  return (
    <>
      <RectangularShapeDrawer2D
        targetElements={drawRoofOnElement ? targetElements : storeyTarget}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onRectangleDrawn={(points, guid) => {
          const roof = {
            guid: uuid(),
            shape: { points: points, shape_type: 'Rectangular' },
            storey: drawRoofOnElement ? elements[guid].storey : storey,
            type: 'vertical_roof_slabs' as ElementTypes,
            is_local: true,
          } as ShapeObject

          setActiveElement(roof.guid)
          addVerticalRoofSlab(roof)
        }}
      />
      {!drawRoofOnElement && <TargetPlane name={storeyId} points={storeyPoints} />}
    </>
  )
}

export default VerticalRoofDrawer2D
