import { useResultsQueryParams } from '@resultsHooks'
import { Button, Typography } from '@mui/material'
import { useElementLabel } from '@editorHooks'
import { tabConfig } from '../../../../constants'

interface Props {
  source?: string
}

const LoadSourceLabel = ({ source }: Props) => {
  const {
    actions: { setIsLocalMode },
  } = useResultsQueryParams()
  const getLabel = useElementLabel()
  const sourceLabel = source ? getLabel(source) : ''
  return (
    <>
      {source ? (
        <Button onClick={() => setIsLocalMode(true, source as string, tabConfig.loads.value)}>
          {sourceLabel}
        </Button>
      ) : (
        <Typography>{sourceLabel}</Typography>
      )}
    </>
  )
}

export default LoadSourceLabel
