import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import {
  get2DLineOrthoDirection,
  infiniteLineIntersection,
  projectPointOntoPlane,
  sortPointsCounterClockwise,
} from '@editorUtils'
import { TargetPlane, toVector2 } from '@scene'
import { find, isNull, round } from 'lodash-es'
import { Plane as PlaneThree } from 'three'
import {
  DrawController,
  InteractiveLine,
  ScalingIndicator,
  InteractiveLineRef,
  InteractiveLineHandles,
} from '@modugen/scene/lib'
import { TransientDrawState } from '@modugen/scene/lib/controllers/DrawController'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useControlStore } from '@editorStores'
import { nthLooped } from '@utils'
import { roofDrawingDecimals } from 'src/components/pages/Editor/config'

interface Props {
  elements: ShapeObject[]
  updateElement: (slab: { guid: string; shape: PolygonShape }) => void
  activePointIndex: number | null
  activeElement: string | null
  pointDrawingEnabled: boolean
  onDrawNewPoint?: () => void
}

const RoofDrawing = ({
  elements,
  updateElement,
  activePointIndex,
  activeElement,
  pointDrawingEnabled,
  onDrawNewPoint,
}: Props): ReactElement => {
  const edgeLineRefs = useRef<Array<InteractiveLineRef>>([])

  const [activeEdgeIndex, setActiveEdgeIndex] = useState<null | number>(null)

  // MEMOS

  const roof = useMemo(
    () => (activeElement ? find(elements, { guid: activeElement }) : undefined),
    [elements, activeElement],
  )

  const roofPlane = useMemo(
    () =>
      roof
        ? new PlaneThree().setFromCoplanarPoints(
            roof.shape.points[0].v,
            roof.shape.points[1].v,
            roof.shape.points[2].v,
          )
        : undefined,
    [roof],
  )

  const roofEdges = useMemo(
    () =>
      roof
        ? roof.shape.points.map((p, i) => {
            const nextP = nthLooped(roof.shape.points, i + 1)

            return [p, nextP]
          })
        : undefined,
    [roof],
  )

  // EFFECTS

  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)

  useEffect(() => {
    if (activeElement) {
      addHiddenElementIds([activeElement])
      return () => removeHiddenElementIds([activeElement])
    }
  }, [activeElement])

  // EVENTS
  const moveRoofEdge = (state: TransientDrawState) => {
    if (isNull(activeEdgeIndex) || !state.drawPoint || !roofPlane) return

    // What we basically do here is use the current line to create a plane that
    // is vertical and extend the previous and next line to find any
    // intersection point on the plane to update the corner points of the roof

    const edge = edgeLineRefs.current[activeEdgeIndex]

    if (!edge.transientLine.current) return

    if (roofEdges) {
      const [edgeStart, edgeEnd] = roofEdges[activeEdgeIndex]

      const edgeOrthogonalDirection2 = get2DLineOrthoDirection(
        toVector2(edgeStart),
        toVector2(edgeEnd),
      )

      const edgeOrthogonalDirection = new ImmutableVector3(
        edgeOrthogonalDirection2.x,
        edgeOrthogonalDirection2.y,
      )

      const cuttingPlane = new PlaneThree().setFromNormalAndCoplanarPoint(
        edgeOrthogonalDirection.v,
        state.drawPoint.v,
      )

      const [previousEdgeStart, previousEdgeEnd] = nthLooped(roofEdges, activeEdgeIndex - 1)
      const [nextEdgeStart, nextEdgeEnd] = nthLooped(roofEdges, activeEdgeIndex + 1)

      const intersectionPrevious = infiniteLineIntersection(
        cuttingPlane,
        previousEdgeStart,
        previousEdgeEnd,
      )

      const intersectionNext = infiniteLineIntersection(cuttingPlane, nextEdgeStart, nextEdgeEnd)

      if (intersectionPrevious && intersectionNext) {
        // In order to pass the checks by the backend the point must be
        // projected exactly onto the plane defined by the existing points of
        // the roof slab
        const intersectionPreviousProjected = projectPointOntoPlane(
          roofPlane,
          new ImmutableVector3(
            round(intersectionPrevious.x, roofDrawingDecimals),
            round(intersectionPrevious.y, roofDrawingDecimals),
            intersectionPrevious.z,
          ),
        )

        const intersectionNextProjected = projectPointOntoPlane(
          roofPlane,
          new ImmutableVector3(
            round(intersectionNext.x, roofDrawingDecimals),
            round(intersectionNext.y, roofDrawingDecimals),
            intersectionNext.z,
          ),
        )

        const previousLineRef = nthLooped(edgeLineRefs.current, activeEdgeIndex - 1)
        if (intersectionPrevious) {
          previousLineRef.updateHandle(InteractiveLineHandles.End, intersectionPreviousProjected)
        }

        const nextLineRef = nthLooped(edgeLineRefs.current, activeEdgeIndex + 1)
        if (intersectionNext) {
          nextLineRef.updateHandle(InteractiveLineHandles.Start, intersectionNextProjected)
        }

        edge.updateLine([intersectionPreviousProjected, intersectionNextProjected])
      }
    }
  }

  const drawEnd = () => {
    if (!activeElement || !roofEdges || isNull(activeEdgeIndex)) return

    const roofPoints = edgeLineRefs.current.map(line => {
      if (line?.transientLine.current) {
        return line.transientLine.current[0]
      }
      return roofEdges[activeEdgeIndex][0]
    })

    updateElement({
      guid: activeElement,
      shape: {
        points: roofPoints,
      },
    })

    setActiveEdgeIndex(null)
  }

  const drawNewPoint = (state: TransientDrawState) => {
    if (!activeElement || !roof || !state.drawPoint) return

    const plane = new PlaneThree().setFromCoplanarPoints(
      roof.shape.points[0].v,
      roof.shape.points[1].v,
      roof.shape.points[2].v,
    )

    const drawPoint = new ImmutableVector3(
      round(state.drawPoint.x, roofDrawingDecimals),
      round(state.drawPoint.y, roofDrawingDecimals),
      state.drawPoint.z,
    )

    const projectedDrawPoint = projectPointOntoPlane(plane, drawPoint)

    if (!projectedDrawPoint) return

    const newPoints = [...roof.shape.points, projectedDrawPoint]

    const sortedPoints = sortPointsCounterClockwise(newPoints)

    updateElement({
      guid: activeElement,
      shape: {
        points: sortedPoints,
      },
    })
    onDrawNewPoint?.()
  }

  if (!roof) return <></>

  return (
    <>
      {/* DrawController used for moving the edges of an existing roof shape */}
      <DrawController
        enabled={!isNull(activeEdgeIndex)}
        onMouseMove={moveRoofEdge}
        onDrawEnd={drawEnd}
        enableIndicator={false}
        isValidDrawTarget={object => object.name === 'draw-plane'}
      />

      {/* DrawController used to add new corner points to the existing roof shape */}
      <DrawController enabled={pointDrawingEnabled} onDrawEnd={drawNewPoint} enableIndicator />

      {/* Showing the corner the user is currently editing in the scene (if textfield is active in the form) */}
      <ScalingIndicator
        position={roof.shape.points[activePointIndex || 0].v}
        color="blue"
        visible={!isNull(activePointIndex)}
      />

      {roofEdges?.map(([start, end], i) => (
        <InteractiveLine
          key={`${activeElement}+${i}-${start.x}-${start.y}-${start.z}-${end.x}-${end.y}-${end.z}-`}
          ref={element => {
            edgeLineRefs.current[i] = element as InteractiveLineRef
          }}
          line={[start, end]}
          color={i === activeEdgeIndex ? 'blue' : '#707070'}
          disableDepthTest
          isSelected={activeEdgeIndex === i}
          onClick={() => setActiveEdgeIndex(i)}
          showHandles={false}
          nonSelectable={!isNull(activeEdgeIndex)}
          clickDisabled={!isNull(activeEdgeIndex)}
        />
      ))}

      {roof && <TargetPlane points={roof.shape.points} />}
    </>
  )
}

export default RoofDrawing
