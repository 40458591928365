import { ReactElement, ReactNode } from 'react'
import { ExpandMore } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import Drawer from '@mui/material/Drawer'

interface Props {
  children?: ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  expanded: boolean
  setIsDrawerExpanded: (expanded: boolean) => void
  sideDrawerExpanded: boolean
}

const BottomDrawer = ({
  children,
  isOpen,
  setIsOpen,
  expanded,
  setIsDrawerExpanded,
  sideDrawerExpanded,
}: Props): ReactElement => {
  return (
    <>
      {isOpen ? (
        <Drawer
          anchor={'bottom'}
          open={true}
          variant={'persistent'}
          sx={({ distances }) => ({
            flex: '0 0 auto',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              // Width is hacky due to not really understanding the styles for the left navigation bar.
              // it appears to be 66px when unopened though
              width: sideDrawerExpanded
                ? `calc(100vw - ${distances.editorDrawerWidthExpanded} - 66px)`
                : `calc(100vw - ${distances.editorDrawerWidth} - 66px)`,
              height: expanded
                ? distances.bottomDrawerHeightExpanded
                : distances.bottomDrawerHeight,
              maxHeight: `60vh`,
              overflow: 'visible',
              marginLeft: '66px',
            },
            position: 'absolute',
          })}
        >
          <Tooltip title="Expand" placement="top" enterDelay={500}>
            <IconButton
              aria-label="expand-bottom-drawer"
              size="medium"
              sx={theme => ({
                position: 'absolute',
                left: 'calc(100%/2)',
                top: -12,
                zIndex: 100000,
                transform: expanded ? '' : 'rotate(-180deg)',
                backgroundColor: '#ffffff',
                borderWidth: 1,
                borderColor: theme.palette.grey[300],
                borderStyle: 'solid',
                padding: 0,
                minHeight: 0,
                minWidth: 0,
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#3c52b2',
                },
              })}
              onClick={() => setIsDrawerExpanded(!expanded)}
              data-cy="expand-bottom-drawer-button"
            >
              <ExpandMore fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {children}
        </Drawer>
      ) : (
        <Drawer
          anchor={'bottom'}
          open={true}
          variant={'persistent'}
          sx={({ distances }) => ({
            flex: '0 0 auto',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              // Width is hacky due to not really understanding the styles for the left navigation bar.
              // it appears to be 66px when unopened though
              width: sideDrawerExpanded
                ? `calc(100vw - ${distances.editorDrawerWidthExpanded} - 66px)`
                : `calc(100vw - ${distances.editorDrawerWidth} - 66px)`,
              height: distances.bottomDrawerInactiveHeight,
              maxHeight: `60vh`,
              overflow: 'visible',
              marginLeft: '66px',
            },
            position: 'absolute',
          })}
        >
          <Tooltip title="Open Verbinder Table" placement="top-end" enterDelay={500}>
            <IconButton
              aria-label="open-anchor-table"
              size="medium"
              sx={theme => ({
                position: 'absolute',
                right: 20,
                top: -12,
                zIndex: 100000,
                transform: 'rotate(-180deg)',
                backgroundColor: '#ffffff',
                borderWidth: 1,
                borderColor: theme.palette.grey[300],
                borderStyle: 'solid',
                padding: 0,
                minHeight: 0,
                minWidth: 0,
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#3c52b2',
                },
              })}
              onClick={() => setIsOpen(true)}
              data-cy="open-anchor-table-button"
            >
              <ExpandMore fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Drawer>
      )}
    </>
  )
}
export default BottomDrawer
