import { ReactElement, useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { PositionMeshElement } from '@scene'
import { find } from 'lodash-es'
import { useModelStore, useSystemManagerStore } from '@editorStores'

interface Props {
  selectedLintel?: string
}

const WallLintelDrawer3D = ({ selectedLintel }: Props): ReactElement => {
  const lintels = useModelStore(state => state.model.lintels)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const lintel = useMemo(
    () => find(lintels, { position_guid: selectedLintel }),
    [selectedLintel, lintels],
  )

  const guidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  return (
    <>
      {lintel && (
        <PositionMeshElement
          position_type="lintel"
          data={lintel}
          height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
          width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
          isSelected
        />
      )}
    </>
  )
}

export default WallLintelDrawer3D
