import { ReactElement } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { loadCategoryTitles } from './constants'

interface Props {
  selectedCategories?: LoadCategory[]
  onSelectCategories?: (categories: LoadCategory[]) => void
}

const LoadCaseSelect = ({ selectedCategories = [], onSelectCategories }: Props): ReactElement => {
  const handleChange = (event: SelectChangeEvent<typeof selectedCategories>) => {
    event.stopPropagation()

    const {
      target: { value },
    } = event
    onSelectCategories?.(
      // On autofill we get a stringified value.
      (typeof value === 'string' ? value.split(',') : value) as LoadCategory[],
    )
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel size="small">Lastfall Kategorie</InputLabel>
      <Select
        multiple
        value={selectedCategories}
        onChange={handleChange}
        input={<OutlinedInput label="Lastfall Kategorie" />}
        renderValue={selected => selected.map(category => loadCategoryTitles[category]).join(', ')}
        size="small"
      >
        {Object.entries(loadCategoryTitles).map(([category, title]) => (
          <MenuItem key={category} value={category}>
            <Checkbox checked={selectedCategories.indexOf(category as LoadCategory) > -1} />
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LoadCaseSelect
