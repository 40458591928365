import { ReactElement, ReactNode } from 'react'
import produce from 'immer'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'
import { StoreReInitializer } from 'src/state/utils'
import { validate } from '../middleware'
import { createInitialState } from '../utils'

interface Props {
  children: ReactNode
  selectedAssemblies: SelectedAssemblies
  assemblies: Assembly[]
  assemblyAssignment: AssemblyAssignment[]
  connections: ProjectConnection[]
  prioritization: ProjectConnectionPrioritization
  elementCrossSections: ElementCS[]
  elementCrossSectionsSelection: ElementCSSelection | null
  elementCrossSectionAssignment: ElementCSAssignment[]
}

interface ServerState {
  selectedAssemblies: SelectedAssemblies
  assemblies: Assembly[]
  assemblyAssignment: AssemblyAssignment[]
  connections: ProjectConnection[]
  prioritization: ProjectConnectionPrioritization
  elementCrossSections: ElementCS[]
  elementCrossSectionsSelection: ElementCSSelection | null
  elementCrossSectionAssignment: ElementCSAssignment[]
}

interface SystemManagerStoreType extends SelectedAssemblies {
  setSelectedAssembly: HandleAssemblySelect
  deselectAssembly: HandleAssemblySelect
  assemblies: Assembly[]
  selectedAssemblies: SelectedAssemblies
  assemblyAssignment: AssemblyAssignment[]
  connections: ProjectConnection[]
  prioritization: ProjectConnectionPrioritization
  setPrioritization: (prio: ProjectConnectionPrioritization) => void
  elementCrossSections: ElementCS[]
  elementCrossSectionsSelection: ElementCSSelection | null
  elementCrossSectionAssignment: ElementCSAssignment[]
  setSingleCrossSection: (element_guid: string, crossSection: CrossSection) => void
}

const selectedAssemblyInitial = {
  standard: undefined,
}

const initialState = {
  assemblies: [],
  selectedAssemblies: {
    Inner: { ...selectedAssemblyInitial },
    Outer: { ...selectedAssemblyInitial },
    Ceiling: { ...selectedAssemblyInitial },
    Roof: { ...selectedAssemblyInitial },
  },
  assemblyAssignment: [],
  connections: [],
  prioritization: {},
  elementCrossSections: [],
  elementCrossSectionsSelection: null,
  elementCrossSectionAssignment: [],
}

const createAssemblyStoreState = (serverState: ServerState): SystemManagerStoreType => {
  return createInitialState(serverState, initialState)
}

const createStore = (serverState: ServerState) => {
  return create(
    validate(
      combine(createAssemblyStoreState(serverState), set => ({
        setSelectedAssembly: (assembly: Assembly) =>
          set(
            produce(state => {
              const { guid, usage_type } = assembly
              state.selectedAssemblies[usage_type].standard = guid
            }),
          ),

        deselectAssembly: (assembly: Assembly) =>
          set(
            produce(state => {
              const { guid, usage_type } = assembly
              const { standard } = state.selectedAssemblies[assembly.usage_type]

              state.selectedAssemblies[usage_type].standard = standard === guid ? null : standard
            }),
          ),

        setPrioritization: (prioritization: ProjectConnectionPrioritization) =>
          set({ prioritization }),

        setSingleCrossSection: (element_guid: string, crossSection: CrossSection) => {
          set(
            produce(state => {
              const index = state.elementCrossSectionAssignment.findIndex(
                (element: ElementCSAssignment) => {
                  return element_guid === element.element_guid
                },
              )
              state.elementCrossSectionAssignment[index] = {
                element_guid: element_guid,
                element_cs: crossSection,
              }
            }),
          )
        },
      })),
    ),
  )
}

const {
  Provider,
  useStore: useSystemManagerStore,
  useStoreApi,
} = createContext<SystemManagerStoreType>()

const SystemManagerStoreProvider = ({ children, ...props }: Props): ReactElement => (
  <Provider createStore={() => createStore({ ...props })}>
    <StoreReInitializer data={props} storeApi={useStoreApi} />
    {children}
  </Provider>
)

export { SystemManagerStoreProvider, useSystemManagerStore }
