import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { IconButton, Typography, Tooltip } from '@mui/material'
import { Box } from '@ui/structure'
import { useEditorNavigation } from '@editorHooks'
import { useUIStore } from 'src/state/stores'
import { Drawer, CloseIcon } from './styles'

interface Props {
  transparent?: boolean
}

const ProcessNavigation = ({ transparent = true }: Props): ReactElement => {
  const open = useUIStore(state => state.editorNavOpen)
  const toggleOpen = useUIStore(state => state.toggleEditorNavOpen)
  const { currentRouteIndex, currentConfig } = useEditorNavigation()
  const navigate = useNavigate()

  return (
    <Drawer variant="permanent" open={open} transparent={!open && transparent}>
      <Box display="flex" flexDirection="column" width="100%" alignItems="start" px={1.5}>
        {currentConfig.map(({ icon: Icon, route, label }, index) => {
          const active = currentRouteIndex === index

          return (
            <Box
              key={route + label}
              onClick={() => navigate(route)}
              sx={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                width: '100%',
                background: ({ palette }) =>
                  (open && active) || (active && !open && !transparent)
                    ? palette.grey[100]
                    : 'transparent',
                border: ({ palette }) =>
                  `1px solid ${!open && active && transparent ? palette.grey[400] : 'transparent'}`,
                borderRadius: '4px',
                marginBottom: ({ spacing }) => (active ? spacing(6) : 0),
                transition: 'margin-bottom 0.25s ease',
                paddingRight: ({ spacing }) => (open ? spacing(1) : 0),
                paddingLeft: '1px',

                '&:hover': {
                  cursor: active ? 'pointer' : 'default',
                },

                '&:after': {
                  content: active && index + 1 !== currentConfig.length ? '""' : 'none',
                  position: 'absolute',
                  bottom: 0,
                  left: '16px',
                  transform: ({ spacing }) => `translateY(calc(100% + ${spacing(1)}))`,
                  height: ({ spacing }) => spacing(5),
                  width: '1px',
                  background: ({ palette }) => palette.grey[700],
                },
              }}
            >
              <Tooltip title={open ? '' : label} placement="right">
                <IconButton sx={{ background: 'transparent !important' }}>
                  <Icon width={20} height={20} />
                </IconButton>
              </Tooltip>
              <Typography
                sx={{
                  display: open ? 'block' : 'none',
                  // transition: 'opacity 0.25s ease',
                  fontSize: 12,
                  fontWeight: 500,
                  marginLeft: ({ spacing }) => spacing(1),
                }}
              >
                {label}
              </Typography>
            </Box>
          )
        })}
      </Box>

      <IconButton onClick={() => toggleOpen()} className="open-nav" sx={{ alignSelf: 'center' }}>
        <CloseIcon open={open}>
          <ArrowRightAltIcon fontSize="small" />
        </CloseIcon>
      </IconButton>
    </Drawer>
  )
}

export default ProcessNavigation
