import { ReactElement } from 'react'
import { find } from 'lodash-es'
import { MenuItem, Select } from '@mui/material'
import { FormControl } from '@ui/forms'
import { roofAreaTypes, roofAreaTypeConfig } from '../../constants'

const GableRoofFields = ({
  config,
  setConfigField,
  disabled,
}: RoofFormFieldProps): ReactElement => {
  return (
    <FormControl label="Angeströmte Fläche">
      <Select
        disabled={disabled}
        value={config.flowArea}
        onChange={event => {
          setConfigField('flowArea', event.target.value as RoofAreaTypes)
        }}
        renderValue={value => {
          const label = find(roofAreaTypeConfig, ['value', value])?.label
          return `${label}`
        }}
        size="small"
        fullWidth
        data-cy="gable-roof-area-type"
      >
        {roofAreaTypes.map(type => (
          <MenuItem key={type} value={type} data-cy={`gable-roof-area-type-select-option-${type}`}>
            {find(roofAreaTypeConfig, ['value', type])?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GableRoofFields
