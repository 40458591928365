import {
  Children,
  ReactElement,
  useState,
  ReactNode,
  isValidElement,
  cloneElement,
  MouseEvent,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isString, isFunction } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'
import DialogBase from '../DialogBase'

interface Props {
  title: string
  text?: string | ReactElement
  confirmLabel?: string
  cancelLabel?: string | null
  onConfirm?: () => Promise<Record<string, unknown> | void> | void
  isOpen?: boolean
  confirmHotkey?: string
  children: ReactElement
}

const AlertDialog = ({
  title,
  text,
  isOpen = false,
  confirmLabel = 'Bestätigen',
  cancelLabel = 'Abbrechen',
  onConfirm,
  confirmHotkey,
  children,
}: Props): ReactElement => {
  const [open, setOpen] = useState<boolean>(isOpen)
  const [loading, setLoading] = useState<boolean>(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const childrenWithProps = Children.map<ReactNode, ReactNode>(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        // @ts-ignore
        onClick: (event: MouseEvent) => {
          event.stopPropagation()
          handleClickOpen()
        },
      })
    }
    return child
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    if (!isFunction(onConfirm)) return handleClose()

    const isAsync = onConfirm.constructor.name === 'AsyncFunction'

    if (isAsync) {
      setLoading(true)
      // typescript cannot handle async and non async type
      // @ts-ignore
      onConfirm().finally(() => {
        setLoading(false)
        handleClose()
      })

      return
    }

    onConfirm()
    handleClose()
  }

  useHotkeys(
    confirmHotkey || '',
    handleClickOpen,
    {
      enabled: confirmHotkey !== undefined,
      preventDefault: true,
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
    [handleClickOpen],
  )

  return (
    <>
      {childrenWithProps}
      <DialogBase open={open} onClose={handleClose} title={title}>
        {isString(text) ? (
          <Typography data-cy="alert-dialog-title">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Typography>
        ) : (
          text
        )}
        <>
          {cancelLabel && <Button onClick={handleClose}>{cancelLabel}</Button>}
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            autoFocus={confirmHotkey === undefined}
            data-cy="alert-dialog-confirm"
          >
            {confirmLabel}
          </LoadingButton>
        </>
      </DialogBase>
    </>
  )
}

export default AlertDialog
