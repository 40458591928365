import { ReactElement } from 'react'
import { Form } from '@ui/forms'
import ElementCSFormFields from '../ElementCSFormFields'
import { materialTypeFromCS } from '../ElementCSFormFields/utils'
import { defaultElementCSValues } from './schema'

interface Props {
  defaultValues: ElementCS
  onSubmit: (values: ElementCS) => void
  id: string
  type: ElementCSType
  showTypeOptions?: boolean
}

/**
 * Very similar to SingleCSForm, except there we use `CrossSection` as schema
 * directly, while here we still wrap the CrossSection in ElementCS.
 */
const ElementCSForm = ({
  defaultValues,
  onSubmit,
  id,
  type,
  showTypeOptions,
}: Props): ReactElement => {
  // Get materialType from the material itself
  const defaultValuesWithMaterialType = {
    ...defaultElementCSValues(type),
    ...defaultValues,
    materialType: materialTypeFromCS(defaultValues.cross_section),
  }

  return (
    <Form id={id} onSubmit={onSubmit} defaultValues={defaultValuesWithMaterialType}>
      <ElementCSFormFields showTypeOptions={showTypeOptions} />
    </Form>
  )
}

export default ElementCSForm
