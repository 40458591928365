import { ReactElement, useMemo } from 'react'
import { inRange } from 'lodash-es'
import { Stack } from '@mui/material'
import { DeleteButton, SaveButton } from '@ui/actions'
import { ErrorFieldBase, Form } from '@ui/forms'
import FormFields from '../FormFields'
import { verticalRoofSlabSchema } from './schema'

interface Props {
  handleSubmit: (slab: ShapeObject) => void
  handleDelete: () => void
  roof: ShapeObject
  isDeleting?: boolean

  updateOrientation: (slabGuid: string, orientation: string) => void
  updateShape: (shape: { guid: string; shape: PolygonShape }) => void
  updateStorey: (guid: string, storey: string) => void

  storeys: string[]
  activeStorey?: string
}

const GeometryForm = ({
  roof,
  handleSubmit,
  handleDelete,
  isDeleting = false,

  updateOrientation,
  updateShape,
  updateStorey,

  storeys,
  activeStorey,
}: Props): ReactElement | null => {
  const defaultValues = useMemo(() => {
    const shape = roof.shape
    const points = shape.points
    const numPoints = shape.points.length
    if (numPoints <= 2) {
      return {
        guid: roof.guid,
        shape: shape,
        orientation: null,
      }
    }
    const p1 = points[0]
    const p2 = points[1]
    const direction = p2.sub(p1).normalize()
    const orientation = inRange(direction.x, -0.01, 0.01) ? 'horizontal' : 'vertical'

    return {
      guid: roof.guid,
      shape: shape,
      orientation,
      storey: roof.storey,
    }
  }, [roof])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorMessage = useMemo(() => {
    if (roof.type === 'vertical_slabs' && roof.storey === 'Dach')
      return "Aktuell unterstützt das Backend keine Deckenpositionen, denen das Stockwerk 'Dach' zugewiesen ist."
  }, [roof])

  return (
    <Form
      id={'GeometryForm'}
      defaultValues={defaultValues}
      validationSchema={verticalRoofSlabSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validationContext={{
        element_type: roof.type,
      }}
      data-cy="rectangular-shape-geometry-form"
    >
      {({ formState: { isSubmitting } }) => (
        <Stack
          spacing={1.25}
          border={1}
          p={1}
          pt={1.5}
          pb={1.25}
          borderColor="grey.200"
          borderRadius={1}
          sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
        >
          <FormFields
            storeys={storeys}
            updateOrientation={updateOrientation}
            updateShape={updateShape}
            updateStorey={updateStorey}
          />
          {activeStorey !== undefined && activeStorey !== roof.storey && (
            <ErrorFieldBase
              message="Das eingezeichnete Element ist nicht im ausgewählten Stockwerk"
              severity="warning"
            />
          )}

          <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
            <SaveButton
              type="submit"
              loading={isSubmitting}
              fullWidth
              disabled={isDeleting}
              data-cy="btn-submit"
            >
              Speichern
            </SaveButton>
            <DeleteButton
              onClick={handleDelete}
              size="small"
              fullWidth
              loading={isDeleting}
              disabled={isSubmitting}
              data-cy="btn-delete-vertical-roof"
            >
              Löschen
            </DeleteButton>
          </Stack>
        </Stack>
      )}
    </Form>
  )
}

export default GeometryForm
