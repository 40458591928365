import { ReactElement, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import reduce from 'lodash-es/reduce'
import reject from 'lodash-es/reject'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { Box } from '@ui/structure'
import { swapElements } from '@utils'
import AddConnection from './components/AddConnection'
import ListItem from './components/ListItem'

interface Props {
  fieldName: string
  title: string

  availableConnections: Array<Connection | ProjectConnection>
  'data-cy'?: string
}

const ConnectionPrioritizationList = ({
  fieldName,
  title,
  availableConnections,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const { setValue } = useFormContext()

  const assignedIds = useWatch({ name: fieldName }) as string[]

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return

    const swapped = swapElements(assignedIds, result.source.index, result.destination.index)

    setValue(fieldName, swapped)
  }

  // only use connections for the dropdown that have not been aassigned already
  const filteredAvailableConnections = useMemo(
    () => reject(availableConnections, c => assignedIds.includes(c.guid)),
    [availableConnections, assignedIds],
  )

  const connectionToGuid = useMemo(
    () =>
      reduce(
        availableConnections,
        (collector, connection) => ({ ...collector, [connection.guid]: connection }),
        {} as Record<string, Connection>,
      ),
    [availableConnections],
  )

  const assignedConnections = useMemo(
    () => assignedIds.map(guid => connectionToGuid[guid]),
    [assignedIds, connectionToGuid],
  )

  const append = (id: string) => setValue(fieldName, [...assignedIds, id])

  const removeIndex = (index: number) =>
    setValue(
      fieldName,
      reject(assignedIds, (id, i) => i === index),
    )

  return (
    <Stack direction="column">
      <Box>
        <Box display="flex" mb={1} justifyContent="space-between">
          <Typography variant="h6" mb={1}>
            {title} {`(${assignedIds.length})`}
          </Typography>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box pl={4}>Prio</Box>
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Kommentar</TableCell>
              <TableCell>
                {
                  <>
                    R<sub>d</sub> Erdbeben (kN)
                  </>
                }
              </TableCell>
              <TableCell>
                {
                  <>
                    R<sub>d</sub> Wind (kN)
                  </>
                }
              </TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ width: '100%' }}
                >
                  {assignedConnections.map((connection, index) => (
                    <Draggable key={index} draggableId={connection.guid + index} index={index}>
                      {(provided, snapshot) => (
                        <ListItem
                          key={fieldName + connection.guid}
                          prio={index + 1}
                          connection={connection}
                          provided={provided}
                          isDragging={snapshot.isDragging}
                          onDelete={() => removeIndex(index)}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <TableFooter>
            <AddConnection
              options={filteredAvailableConnections}
              onAdd={connection => {
                append(connection.guid)
              }}
              data-cy={`${dataCy}-add-connection`}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default ConnectionPrioritizationList
