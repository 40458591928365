import { ReactElement } from 'react'
import { useController } from 'react-hook-form'
import { find, isUndefined, isNull } from 'lodash-es'
import { Select as MuiSelect, MenuItem, Typography, BaseSelectProps } from '@mui/material'
import FormControl from '../FormControl'

interface Props extends BaseSelectProps {
  name: string
  options: {
    value: string | number
    label: string
  }[]
  placeholder?: string
  label?: string | ReactElement
  disabled?: boolean
  tooltip?: TooltipContents
  'data-cy'?: string
}

const Select = ({
  name,
  options,
  placeholder = '',
  label = '',
  disabled = false,
  tooltip,
  'data-cy': dataCy,
  ...props
}: Props): ReactElement => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
  })

  return (
    <FormControl label={label} error={error?.message} tooltip={tooltip}>
      <MuiSelect
        onChange={onChange}
        onBlur={onBlur}
        value={isNull(value) ? '' : value}
        label={placeholder}
        name={fieldName}
        inputRef={ref}
        size="small"
        disabled={disabled}
        displayEmpty
        data-cy={`${dataCy}-dropdown`}
        renderValue={value => {
          if (isNull(value) || isUndefined(value)) {
            return (
              <Typography
                sx={({ palette }) => ({
                  color: palette.grey[500],
                })}
              >
                {placeholder}
              </Typography>
            )
          }

          return find(options, ['value', value])?.label
        }}
        {...props}
      >
        {options.map(({ value, label }, index: number) => (
          <MenuItem
            key={value.toString() + index}
            value={value}
            data-cy={`${dataCy}-item-${index}`}
          >
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
