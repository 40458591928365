import { ReactElement } from 'react'
import { find } from 'lodash-es'
import { InputAdornment } from '@mui/material'
import { RelativePositionField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  guid: string
  length: number
}

const FormFields = ({ guid, length }: Props): ReactElement => {
  const updateSlabBeam = useModelStore(state => state.updateSlabBeam)

  useStoreSubscription({
    writeCallback: ({ relative_position, slab_guid }) => {
      const slabs = useModelStore.getState().model.vertical_slabs
      const slab = find(slabs, { guid: slab_guid }) as ShapeObject

      const interpolate = (a: Point, b: Point, ratio: number) => {
        const diffVec = b.sub(a)
        return a.addScaledVector(diffVec, ratio)
      }

      const P0 = slab.shape.points[0]
      const P1 = slab.shape.points[1]
      const P2 = slab.shape.points[2]
      const P3 = slab.shape.points[3]

      const start = interpolate(P0, P1, relative_position)
      const end = interpolate(P3, P2, relative_position)

      updateSlabBeam(slab_guid, {
        relative_position: relative_position,
        guid: guid,
        shape: {
          start: start,
          end: end,
        },
      } as SlabBeam)
    },
  })

  return (
    <RelativePositionField
      label="Absolute Position"
      length={length}
      size="small"
      sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
      name="relative_position"
      InputProps={{
        endAdornment: <InputAdornment position="end">m</InputAdornment>,
      }}
      data-cy="absolute-slab-beam-position-input"
    />
  )
}

export default FormFields
