import { ReactElement, useMemo, useState } from 'react'
import { UseMutationResult } from 'react-query'
import { map } from 'lodash-es'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { CreateElementCSDialog, EditElementCSDialog } from '..'
import { ElementCSDataGrid } from '../ElementCSDataGrid'
import { defaultElementCSValues } from '../ElementCSForm/schema'
import { elementCSTypeToLabel, tablesConfig } from './constants'

type ComponentsByType = {
  [key in ElementCSType]?: ElementCS[]
}

function getStandardByType(elementCSSelection: ElementCSSelection, type: ElementCSType) {
  if (type === 'Rip') {
    return undefined
  } else if (type === 'Lintel') {
    return undefined
  } else if (type === 'Column') {
    return elementCSSelection.column
  } else if (type === 'Purlin') {
    return elementCSSelection.purlin
  } else if (type === 'Beam') {
    return elementCSSelection.beam
  } else {
    return undefined
  }
}

interface Props {
  elementCrossSections: ElementCS[]
  editCSDialogText: string
  onDelete?: (elementCS: ElementCS) => void
  onSelect?: (elementCS: ElementCS) => void
  selectedElementCrossSections?: ElementCSSelection

  createMutation:
    | UseMutationResult<ElementCS, unknown, ElementCS, unknown>
    | UseMutationResult<ProjectElementCS, unknown, ProjectElementCS, unknown>

  editMutation:
    | UseMutationResult<ElementCS, unknown, ElementCS, unknown>
    | UseMutationResult<ProjectElementCS, unknown, ProjectElementCS, unknown>
}

const ElementCSList = ({
  elementCrossSections,
  editCSDialogText,
  onDelete,
  createMutation,
  editMutation,
  onSelect,
  selectedElementCrossSections,
}: Props): ReactElement => {
  const [createValues, setCreateValues] = useState<ElementCS | null>(null)
  const [editValues, setEditValues] = useState<ElementCS | null>(null)

  const componentsByType = useMemo(
    () =>
      (elementCrossSections || []).reduce<ComponentsByType>((result, elementCrossSection) => {
        const { kind } = elementCrossSection

        result[kind] = [...(result[kind] || []), elementCrossSection]

        return result
      }, {}),
    [elementCrossSections],
  )

  const CreateButton = ({ type }: { type: ElementCSType }) => (
    <Button
      onClick={() =>
        setCreateValues({
          ...defaultElementCSValues(type),
        } as unknown as ElementCS)
      }
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        alignSelf: 'center',
      }}
      data-cy={`create-element-cs-${type}-btn`}
    >
      anlegen
    </Button>
  )

  return (
    <>
      <Stack direction="column" spacing={3}>
        {map(
          tablesConfig,
          (
            config: {
              value: ElementCSType
              label: string
            },
            key: ElementCSType,
          ) => {
            const components = componentsByType[config.value]
            const hasElementCrossSections = !!components?.length
            const elementCSItems = components || []

            const standardCSSelectable =
              !!selectedElementCrossSections && !['Rip', 'Lintel'].includes(config.value)
            const standardForType =
              selectedElementCrossSections &&
              getStandardByType(selectedElementCrossSections, config.value)

            return (
              <Box key={key}>
                <Box display="flex" mb={1} justifyContent="space-between">
                  <Typography variant="h6" mb={1}>
                    {config.label}
                  </Typography>
                  {hasElementCrossSections && <CreateButton type={key} />}
                </Box>
                {!hasElementCrossSections && (
                  <Paper>
                    <Box padding={3} textAlign="center">
                      <Typography>
                        Sie haben noch keine Querschnitte für {elementCSTypeToLabel[key]} angelegt
                      </Typography>
                      <Box mt={2}>
                        <CreateButton type={key} />
                      </Box>
                    </Box>
                  </Paper>
                )}
                {hasElementCrossSections && (
                  <ElementCSDataGrid
                    elementCrossSections={elementCSItems}
                    standardCS={standardForType}
                    standardCSSelectable={standardCSSelectable}
                    editCSDialogText={editCSDialogText}
                    onDelete={arg => onDelete?.(arg)}
                    onEdit={arg => setEditValues(arg)}
                    onDuplicate={arg => setCreateValues(arg)}
                    onSetAsStandard={arg => onSelect?.(arg)}
                  />
                )}
              </Box>
            )
          },
        )}
      </Stack>

      {createValues && (
        <CreateElementCSDialog
          mutation={createMutation}
          open
          onClose={() => setCreateValues(null)}
          elementCrossSection={createValues}
        />
      )}
      {editValues && (
        <EditElementCSDialog
          mutation={editMutation}
          open
          onClose={() => setEditValues(null)}
          elementCrossSection={editValues}
        />
      )}
    </>
  )
}

export default ElementCSList
