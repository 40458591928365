import { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useStructuralPlanningStore } from '@editorStores'

const LoadModeFilters = (): ReactElement => {
  const visibility = useStructuralPlanningStore(state => state.structuralPlanningElementVisibility)
  const changeVisibility = useStructuralPlanningStore(
    state => state.changeStructuralPlanningElementVisibility,
  )

  return (
    <>
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.stiffening}
        onChange={() => changeVisibility('stiffening', !visibility.stiffening)}
        label="Aussteiffende Elemente"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.verticalTransmitters}
        onChange={() => changeVisibility('verticalTransmitters', !visibility.verticalTransmitters)}
        label="Vertikale Auflager"
      />
      <SwitchLabeled
        checked={visibility.connectors}
        onChange={() => changeVisibility('connectors', !visibility.connectors)}
        label="Zugauflager"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.pointLoads}
        onChange={() => changeVisibility('pointLoads', !visibility.pointLoads)}
        label="Punktlasten"
      />
      <SwitchLabeled
        checked={visibility.lineLoads}
        onChange={() => changeVisibility('lineLoads', !visibility.lineLoads)}
        label="Linienlasten"
      />
      <SwitchLabeled
        checked={visibility.areaLoads}
        onChange={() => changeVisibility('areaLoads', !visibility.areaLoads)}
        label="Flächenlasten"
      />
    </>
  )
}

export default LoadModeFilters
