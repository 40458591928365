import { ReactElement } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material'
import { useElementLabel } from '@editorHooks'

interface Props {
  util: {
    guid: string
    maxUtilization?: number
    sv0?: number
  }[]
  onSelect: (guid: string) => void
  selectedGuid?: string
}

const MaxSegmentUtilizationList = ({ util, onSelect, selectedGuid }: Props): ReactElement => {
  const { palette } = useTheme()
  const getElementLabel = useElementLabel()

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
      <Table sx={{ '.MuiTableCell-root': { paddingX: 1, paddingY: 1.5 } }} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Bauteilname</TableCell>
            <TableCell align="right">Ausnutzung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {util.map(({ guid, maxUtilization }, index) => {
            const color = maxUtilization && maxUtilization > 1 ? `${palette.error.main}` : undefined

            return (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => onSelect(guid)}
                hover
                selected={selectedGuid === guid}
              >
                <TableCell sx={{ color }}>{getElementLabel(guid)}</TableCell>
                <TableCell sx={{ color }} align="right">
                  {maxUtilization?.toFixed(2).replace('.', ',')}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MaxSegmentUtilizationList
