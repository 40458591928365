import { ReactElement } from 'react'
import { ArrowsDirectional, DimensionsIndicator } from '@scene'
import { MarqueeSelectionController } from '@modugen/scene/lib/controllers/MarqueeSelectionController'
import { useElementSelectionStore } from '@editorStores'
import useGlobalLoadsQueryParams from '../../../../hooks/useGlobalLoadsQueryParams'

const SeismicSettingsScene = (): ReactElement => {
  const selectionEnabled = useElementSelectionStore(state => state.selectionEnabled)

  const {
    params: { activeSeismicAngle, buildingDimension, activeSeismicMethodTab },
  } = useGlobalLoadsQueryParams()

  return (
    <>
      {activeSeismicAngle && activeSeismicMethodTab === 'excel' && (
        <ArrowsDirectional key={activeSeismicAngle} angle={activeSeismicAngle} />
      )}
      {buildingDimension && <DimensionsIndicator dimension={buildingDimension} />}
      {selectionEnabled && <MarqueeSelectionController />}
    </>
  )
}

export default SeismicSettingsScene
