import { ReactElement, useMemo, useState } from 'react'
import { reject } from 'lodash'
import { TabContext, TabPanel, TabList, LoadingButton } from '@mui/lab'
import { Button, Collapse, Divider, List, ListItem, Stack, Typography } from '@mui/material'
import DIN_EN_1991_1_4_NAB_TAB_B2 from '@img/wind-loads/DIN-EN-1991-1-4-NA-B-TAB-B2.png'
import VerticalWalls722 from '@img/wind-loads/vertical-walls-722.png'
import { SaveButton } from '@ui/actions'
import { Form, FormTab, mergeDefaults } from '@ui/forms'
import { Box } from '@ui/structure'
import { useModelStore } from '@editorStores'
import useGlobalLoadsQueryParams from '../../../../hooks/useGlobalLoadsQueryParams'
import BaseParameterFields from '../BaseParameterFields'
import ManualWindLoads from '../ManualWindLoads'
import OuterWallAssignment from '../OuterWallAssignment'
import RoofAssignment from '../RoofAssignment'
import { tabConfig } from './constants'
import { createManualWindLoadsSchema, createWindLoadsSchema } from './schema'

interface Props {
  id: string
  onSubmit: (values: WindLoadsFormValues) => void
  defaultValues: WindLoadsFormValues
  baseParameterActions: BaseParameterFormActions
  wallAssignmentParamsActions: WallAssignmentParamsActions
  isLoading: boolean
  isSaving: boolean

  onSubmitManualWindLoads: (data: WindloadResults) => void
  isLoadingManualWindLoads: boolean
  isSavingManualWindLoads: boolean

  ignoredSlabs: string[]
}

const WindLoadsInfoText = () => {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <Button onClick={handleToggle} variant="contained" color="info">
        {open ? 'Info ausblenden' : 'Info einblenden'}
      </Button>

      <Collapse in={open}>
        <Box
          sx={{
            border: '1px solid #000', // Adjust the border thickness and color as needed
            borderRadius: '8px', // Optional: adds rounded corners
            padding: '2px', // Adds space between the border and the content
          }}
        >
          <Stack spacing={2}>
            <List>
              <ListItem>
                Für die Windlast wird der Ansatz zu genauen Erfassung der Bodenrauigkeit (DIN EN
                1991-1-4/NA-B Tab. B.2) verwendet.
              </ListItem>
              <ListItem>
                <Typography>
                  Weiterhin werden die nach Schneider 7.2.2 über die Bezugshöhe z<sub>e</sub>{' '}
                  definierten Profile für q<sub>p</sub>(z) angesetzt.
                </Typography>
              </ListItem>
            </List>
            <Divider textAlign="center">
              <Typography variant="h6">Beispiel</Typography>
            </Divider>
            <List>
              <ListItem>
                GK I, WZ I, z<sub>e</sub> = 3
              </ListItem>
              <ListItem>
                <Typography>
                  q<sub>p</sub>(z) = q<sub>b</sub> * (Böengesch. Druck Profil abh. von z bzw. z_e)
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  q<sub>p</sub> = 0,32 kN/m<sup>2</sup> * 2,6 * (z
                  <sub>e</sub> / 10)<sup>0,19</sup>
                </Typography>
              </ListItem>
            </List>
            <Divider textAlign="center">
              <Typography variant="h6">DIN EN-1991-1-4/NA-B Tab. B2</Typography>
            </Divider>
            <img
              src={DIN_EN_1991_1_4_NAB_TAB_B2}
              alt="Q-Zonen für Vertikale Wände nach Schneider 7.2.2."
              style={{ width: '100%', height: '100%' }}
            />
            <Divider textAlign="center">
              <Typography variant="h6">Schneider 7.2.2.</Typography>
            </Divider>
            <img
              src={VerticalWalls722}
              alt="Q-Zonen für Vertikale Wände nach Schneider 7.2.2."
              style={{ width: '100%', height: '100%' }}
            />
          </Stack>
        </Box>
      </Collapse>
    </>
  )
}

const WindLoadsForm = ({
  id,
  onSubmit,
  defaultValues,
  baseParameterActions,
  wallAssignmentParamsActions: wallAssignmentActions,
  isLoading,
  isSaving,

  onSubmitManualWindLoads,
  isLoadingManualWindLoads,
  isSavingManualWindLoads,

  ignoredSlabs = [],
}: Props): ReactElement => {
  const {
    params: { windAssignmentTab },
    actions: { toggleWindAssignmentTab },
  } = useGlobalLoadsQueryParams()

  const { model } = useModelStore()

  const isResultsTab = windAssignmentTab === 'results'

  const windLoadsSchema = useMemo(() => {
    if (isResultsTab) {
      const slabs = reject(model.slabs, slab => ignoredSlabs.includes(slab.guid))
      return createManualWindLoadsSchema(slabs.map(({ guid }) => guid))
    }

    return createWindLoadsSchema()
  }, [model.roof_slabs.length, model.slabs, isResultsTab, ignoredSlabs])

  const { value: defaultValue } = tabConfig['base-params']

  const defaultInitialValues = useMemo(
    () => ({
      ...mergeDefaults(defaultValues, windLoadsSchema.getDefault()),
    }),
    [defaultValues],
  )

  return (
    <TabContext value={windAssignmentTab || defaultValue}>
      <Stack spacing={2}>
        <Form
          key={isResultsTab ? 'manual' : 'computation'}
          id={id}
          onSubmit={(data, event) => {
            // @ts-ignore
            if (event.nativeEvent.submitter.name === 'compute-manual-wind-loads') {
              onSubmitManualWindLoads(data.results)
            } else {
              onSubmit(data)
            }
          }}
          validationSchema={windLoadsSchema}
          defaultValues={defaultInitialValues}
        >
          <Box marginX={-2} mt={-2}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiTab-root': {
                  fontSize: 12,
                  paddingX: ({ spacing }) => spacing(1.5),
                },
              }}
            >
              <TabList onChange={(_, value) => toggleWindAssignmentTab(value)}>
                <FormTab
                  {...tabConfig['base-params']}
                  fields={['baseParameters', 'dimensions']}
                  data-cy="tab-base-params"
                />
                <FormTab
                  {...tabConfig['outer-walls']}
                  fields="outerWallAssignment"
                  data-cy="tab-outer-walls"
                />
                <FormTab {...tabConfig.roofs} fields="roofAssignment" data-cy="tab-roofs" />
                <Divider orientation="vertical" style={{ height: 30, alignSelf: 'center' }} />
                <FormTab {...tabConfig.results} fields="results" data-cy="tab-results" />
              </TabList>
            </Box>
            <TabPanel value={tabConfig['base-params'].value}>
              <BaseParameterFields {...baseParameterActions} />
            </TabPanel>
            <TabPanel value={tabConfig['outer-walls'].value}>
              {windAssignmentTab === tabConfig['outer-walls'].value && (
                <OuterWallAssignment {...wallAssignmentActions} />
              )}
            </TabPanel>
            <TabPanel value={tabConfig.roofs.value}>
              {windAssignmentTab === tabConfig.roofs.value && (
                <RoofAssignment {...wallAssignmentActions} />
              )}
            </TabPanel>
            <TabPanel value={tabConfig.results.value}>
              {windAssignmentTab === tabConfig.results.value && (
                <ManualWindLoads {...wallAssignmentActions} />
              )}
            </TabPanel>
          </Box>
        </Form>

        {windAssignmentTab === 'results' ? (
          <SaveButton
            disabled={isLoadingManualWindLoads}
            loading={isSavingManualWindLoads}
            form="windloads-form"
            type="submit"
            data-cy="save-manual-wind-loads-btn"
            name="compute-manual-wind-loads"
          >
            Speichern
          </SaveButton>
        ) : (
          <LoadingButton
            disabled={isLoading}
            loading={isSaving}
            variant="outlined"
            form="windloads-form"
            type="submit"
            data-cy="save-btn"
          >
            Berechnen
          </LoadingButton>
        )}
        {windAssignmentTab === 'base-params' && <WindLoadsInfoText />}
      </Stack>
    </TabContext>
  )
}

export default WindLoadsForm
