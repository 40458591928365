import { useEditElementStore, useModelStore } from '@editorStores'
import { useSelectionMode } from '@editorHooks'
import RoofDrawing from '../RoofDrawing'
import { roofPointSelectionKey } from '../RoofForm/components/FormFields'

const ActualRoofDrawing = () => {
  const { selectionModeKey, unsetSelectionMode } = useSelectionMode()

  const updateRoof = useModelStore(state => state.updateRoof)
  const roofSlabs = useModelStore(state => state.model.roof_slabs)

  const activeRoofPointIndex = useEditElementStore(state => state.activeRoofPointIndex)
  const activeElement = useEditElementStore(state => state.activeElement)

  return (
    <RoofDrawing
      elements={roofSlabs}
      updateElement={updateRoof}
      activePointIndex={activeRoofPointIndex}
      activeElement={activeElement}
      pointDrawingEnabled={selectionModeKey === roofPointSelectionKey}
      onDrawNewPoint={unsetSelectionMode}
    />
  )
}

export default ActualRoofDrawing
