import { useMemo } from 'react'
import { getPolygonNormal } from '@scene'
import { DoubleSide } from 'three'
import { getCenterMultiple } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Plane } from '@react-three/drei'

interface Props {
  points: ImmutableVector3[]
  name?: string
}

/**
 * invisible plane acting as draw target (basically the roof but extended)
 */
const TargetPlane = ({ points, name = 'draw-plane' }: Props) => {
  const roofNormal = useMemo(() => getPolygonNormal(points), [points])

  const roofCenter = useMemo(() => getCenterMultiple(points), [points])

  const roofPlaneRotationX = useMemo(() => -Math.atan2(roofNormal.y, roofNormal.z), [roofNormal])
  const roofPlaneRotationY = useMemo(
    () => -Math.atan2(-roofNormal.x, Math.sqrt(roofNormal.y ** 2 + roofNormal.z ** 2)),
    [roofNormal],
  )

  return (
    <Plane
      args={[200, 200]}
      position={roofCenter.v.setZ(roofCenter.z + 0.1)}
      rotation={[roofPlaneRotationX, roofPlaneRotationY, 0]}
      name={name}
      renderOrder={100}
    >
      <meshStandardMaterial color="blue" side={DoubleSide} opacity={0} transparent />
    </Plane>
  )
}

export default TargetPlane
