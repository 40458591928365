import { Typography, TypographyProps } from '@mui/material'

const UtilizationTextIcon = () => {
  return <Typography fontSize="inherit">η</Typography>
}

const UtilizationC90TextIcon = () => {
  return (
    <Typography fontSize="inherit">
      η<sub>c,90</sub>
    </Typography>
  )
}

const KC90TextIcon = () => {
  return (
    <Typography fontSize="inherit">
      k<sub>c,90</sub>
    </Typography>
  )
}

const RipHeightTextIcon = (props: TypographyProps) => {
  return (
    <Typography fontSize="inherit" {...props}>
      H<sub>Rippe</sub>
    </Typography>
  )
}

const RipWidthTextIcon = (props: TypographyProps) => {
  return (
    <Typography fontSize="inherit" {...props}>
      B<sub>Rippe</sub>
    </Typography>
  )
}

const LintelWidthTextIcon = (props: TypographyProps) => {
  return (
    <Typography fontSize="inherit" {...props}>
      B<sub>Sturz</sub>
    </Typography>
  )
}

const SupportLengthTextIcon = (props: TypographyProps) => {
  return (
    <Typography fontSize="inherit" {...props}>
      l<sub>A</sub>+ü
    </Typography>
  )
}

const SupportWidthTextIcon = (props: TypographyProps) => {
  return (
    <Typography fontSize="inherit" {...props}>
      b
    </Typography>
  )
}

export {
  UtilizationTextIcon,
  UtilizationC90TextIcon,
  KC90TextIcon,
  RipHeightTextIcon,
  RipWidthTextIcon,
  LintelWidthTextIcon,
  SupportLengthTextIcon,
  SupportWidthTextIcon,
}
