import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { AxiosError } from 'axios'
import { reject } from 'lodash-es'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { Errors, Form } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { Box } from '@ui/structure'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import {
  getChecksForCrossSections,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postStartCalcMemberChecks,
} from '@queries'
import { saveSingleMemberCheckSettings, updateElementCrossSectionAssignment } from '@mutations'
import { FormFields as SingleElementCSFormFields } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'
import { getDefaultValues as getDefaultValuesSingleCSForm } from 'src/components/pages/Editor/components/SingleElementCSForm/components/SingleCSForm/schema'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { buildErrorMessage } from 'src/constants'
import queryClient from 'src/state/client'
import { getSupportsOfElement } from '../../utils'
import LintelCompressionSettingsFormFields, {
  BundleItem,
} from '../LintelCompressionSettingsForm/formFields'
import { getDefaultFormValues as getDefaultFormValuesCompressionSettingsForm } from '../LintelCompressionSettingsForm/utils'
import {
  formBundlesToUpdatedCheckSettings,
  UtilizationPreview,
} from './components/UtilizationPreview'
// Import the image
import lintelCompressionDiagram from './lintelCompressionDiagram.png'
import { lintelCSAndAPFormSchema } from './schema'

interface FormData {
  crossSection: CrossSectionFormDataType
  formBundles: BundleItem[]
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
  checkSettings: SettingsOnMember
  checks: StandardPositionCheck[]
}

interface MutationData {
  crossSection: ElementCSAssignment
  settingsOnMember: SettingsOnMember
  formBundles: BundleItem[]
  checks: StandardPositionCheck[]
}

interface Props {
  elementGuid: string
  checksOnLintel: StandardPositionCheck[]
  checkSettings: SettingsOnMember
  isLoading: boolean
  preSubmit?: () => void
  postSubmit?: () => void
}

const LintelCSAndAPForm = ({
  elementGuid,
  checksOnLintel,
  checkSettings,
  isLoading,
  preSubmit,
  postSubmit,
}: Props) => {
  const { projectId } = useParams()
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const setStructuralChecks = useResultsStore(state => state.setStructuralChecks)
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const crossSection = elementGuidToCrossSection[elementGuid].element_cs

  const elementsSupportingSelected = verticalTransmissionGraph
    ? getSupportsOfElement(elementGuid, verticalTransmissionGraph)
    : []
  const supportCompressionChecks = useMemo(
    () =>
      (checksOnLintel.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]) || [],
    [checksOnLintel],
  )

  const defaultValues = {
    ...getDefaultFormValuesCompressionSettingsForm(
      elementGuidToCrossSection,
      checkSettings,
      supportCompressionChecks,
      elementsSupportingSelected,
    ),
    ...getDefaultValuesSingleCSForm(crossSection),
    checks: checksOnLintel,
    elementGuid: elementGuid,
  }

  const { mutateAsync: onSubmit, isLoading: isLoadingInternal } = useMutation(
    (data: MutationData) => {
      const updateCrossSections = async () => {
        for (const bundle of data.formBundles) {
          await updateElementCrossSectionAssignment.request(
            projectId as string,
            bundle.targetCrossSection,
          )
        }
        await updateElementCrossSectionAssignment.request(projectId as string, data.crossSection)
      }
      return updateCrossSections().then(
        async () =>
          await saveSingleMemberCheckSettings.request(projectId as string, data.settingsOnMember),
      )
    },
    {
      onMutate: async (data: MutationData) => {
        const updatedStructuralChecks = [
          ...reject(structuralChecks, check => check.element_guid === elementGuid),
          ...data.checks,
        ]
        setStructuralChecks(updatedStructuralChecks)
        setSingleCrossSection(data.crossSection.element_guid, data.crossSection.element_cs)
        setSingleMemberCheckSetting(data.settingsOnMember)
        data.formBundles.forEach(bundle => {
          const updatedCrossSection = bundle.targetCrossSection
          if (updatedCrossSection)
            setSingleCrossSection(updatedCrossSection.element_guid, updatedCrossSection.element_cs)
        })
      },
      onSuccess: () => {
        enqueueSnackbar('Querschnitt und Auflagereinstellungen erfolgreich gespeichert', {
          variant: 'success',
          preventDuplicate: true,
        })
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        const infoMessage = '[Backend] Aktualisiere MemberCheckDependency ...'
        enqueueSnackbar(infoMessage, { variant: 'info', key: infoMessage, preventDuplicate: true })
        postStartCalcMemberChecks.request(projectId as string).then(() => {
          closeSnackbar(infoMessage)
          const successMessage = '[Backend] MemberCheckDependency aktualisiert.'
          enqueueSnackbar(successMessage, {
            variant: 'success',
            key: successMessage,
            preventDuplicate: true,
          })
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
      },
    },
  )

  const handleSubmit = (data: FormData) => {
    preSubmit?.()

    const formBundles = data.formBundles
    const newLintelCS = {
      material: data.crossSection.material,
      materialType: data.crossSection.materialType,
      shape:
        data.crossSection.materialType === 'steelMaterial'
          ? data.crossSection.steelShape
          : data.crossSection.shape,
      usage_class: data.crossSection.usage_class,
    } as CrossSection
    onSubmit({
      crossSection: {
        element_guid: elementGuid,
        element_cs: newLintelCS,
      },
      settingsOnMember: formBundlesToUpdatedCheckSettings(data.checkSettings, formBundles),
      formBundles: formBundles,
      checks: data.checks,
    })

    postSubmit?.()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      validationSchema={lintelCSAndAPFormSchema}
      key={elementGuid}
      data-cy={`frm-lintel-cs-and-ap`}
    >
      <Stack direction="column" spacing={2} maxWidth="900px">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <UtilizationPreview elementGuid={elementGuid} />
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
            <SingleElementCSFormFields elementType="lintels" isDisabled={false} />
          </Box>
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
            <img
              src={lintelCompressionDiagram}
              alt="Lintel Compression Diagram"
              style={{ maxWidth: '300px', maxHeight: '300px' }}
            />
          </Box>
        </Stack>
        <LintelCompressionSettingsFormFields />
        <Errors />
        <SubmitButton
          loading={isLoading || isLoadingInternal}
          fullWidth={false}
          data-cy={`btn-lintel-cs-and-ap-submit`}
        />
      </Stack>
    </Form>
  )
}

export default LintelCSAndAPForm
