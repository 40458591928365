import { ReactElement } from 'react'
import { Form } from '@ui/forms'
import CLTLayerFormFields from './CLTLayerFormFields'
import { cltLayerSchema } from './schema'
import { layerToFormState } from './utils'

const CLTLayerForm = ({ id, onSubmit, defaultValues }: LayerFormProps): ReactElement => {
  return (
    <Form
      id={id}
      validationSchema={cltLayerSchema}
      defaultValues={
        layerToFormState(defaultValues as CLTAssemblyLayer) || cltLayerSchema.getDefault()
      }
      onSubmit={values => onSubmit(values.layer)}
    >
      <CLTLayerFormFields />
    </Form>
  )
}

export default CLTLayerForm
