import { ReactElement, useMemo } from 'react'
import { find } from 'lodash-es'
import { Stack } from '@mui/material'
import { useModelStore } from '@editorStores'
import { WallLintelForm } from '../FloorplanDrawer/components/WallLintelForm'

interface Props {
  lintel?: string
}

const WallLintelForm3D = ({ lintel: lintelGuid }: Props): ReactElement => {
  const lintels = useModelStore(state => state.model.lintels)

  const lintel = useMemo(() => find(lintels, { position_guid: lintelGuid }), [lintels, lintelGuid])

  return (
    <Stack direction="column" spacing={2}>
      {lintelGuid && lintel && <WallLintelForm positionGuid={lintelGuid} />}
    </Stack>
  )
}

export default WallLintelForm3D
