import { ReactElement, useMemo } from 'react'
import { SaveButton } from '@ui/actions'
import { LoadingIndicator } from '@ui/feedback'
import { Form } from '@ui/forms'
import { globalFireSettingsSchema } from '../schema'
import FormFields from './FormFields'

interface Props {
  isLoading: boolean
  data?: GlobalFireSettings
  isSaving: boolean
  onSave: (data: GlobalFireSettings) => void
}

const GlobalFireSettings = ({ isLoading, data, isSaving, onSave }: Props): ReactElement => {
  const defaultValues = useMemo(() => {
    const defaultValues = {
      hot_dimensioning_settings: data?.hot_dimensioning_settings,
      fire_protection_settings: data?.fire_protection_settings,
    }
    return defaultValues
  }, [data])

  if (isLoading) return <LoadingIndicator />

  return (
    <Form
      onSubmit={onSave}
      defaultValues={defaultValues}
      validationSchema={globalFireSettingsSchema}
      data-cy="global-fire-settings-form"
    >
      <FormFields />
      <SaveButton loading={isSaving} type="submit" data-cy="save-btn">
        Speichern
      </SaveButton>
    </Form>
  )
}

export default GlobalFireSettings
