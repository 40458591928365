import { ReactElement } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box } from '@ui/structure'
import HorizontalDistributionForm from './HorizontalDistributionForm'

interface Props {
  onClose: () => void
  selectedElements: string[]
  elementType: ElementTypes
}

const RoofSlabForm = (props: Props): ReactElement | null => {
  const [activeTab, setActiveTab] = useQueryParam('roof_slab_tab', StringParam)

  return (
    <Box>
      <TabContext value={activeTab || '0'}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setActiveTab(value)} aria-label="lab API tabs example">
            <Tab value="0" label="Kräfteverteilung" />{' '}
          </TabList>
        </Box>
        <TabPanel value="0">
          <HorizontalDistributionForm {...props} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default RoofSlabForm
