import { toNumber } from 'lodash-es'
import { number, object, string } from 'yup'

export const proposalSchema = object({
  anchorGuid: string().required(),
  wallGuid: string().required(),
  collectionLeft: number().required(),
  collectionRight: number().required(),
  formula: string().required(),
  load: number().required(),
})

export const newProposalSchema = object({
  anchorGuid: string().required(),
  wallGuid: string().required(),
  collectionLeft: number()
    .nullable()
    .min(0)
    .test({
      name: 'is-bigger-than-wall',
      message: 'An dieser Stelle kann kein Einzug gemacht werden.',
      test: function () {
        const { collectionLeft } = this.parent as { collectionLeft?: string }
        const { wallLength, relSupportPosition } = this.options.context as {
          wallLength: number
          relSupportPosition: number
        }

        const val = toNumber(collectionLeft)
        const upper = toNumber(relSupportPosition)
        const lower = upper - val / wallLength

        if (lower < 0)
          return this.createError({
            message: `Scheinbar ist der Einzug größer als die Wand. Wandlänge: ${wallLength}`,
          })

        return true
      },
    }),
  collectionRight: number()
    .nullable()
    .min(0)
    .test({
      name: 'is-',
      message: 'An dieser Stelle kann kein Einzug gemacht werden.',
      test: function () {
        const { collectionRight } = this.parent as { collectionRight?: string }
        const { wallLength, relSupportPosition } = this.options.context as {
          wallLength: number
          relSupportPosition: number
        }

        const val = toNumber(collectionRight)
        const upper = toNumber(relSupportPosition)
        const lower = upper + val / wallLength

        if (lower > 1)
          return this.createError({
            message: `Scheinbar ist der Einzug größer als die Wand. Wandlänge: ${wallLength}`,
          })

        return true
      },
    }),
})
