export const wallTabs = {
  geometry: {
    tab: 'geometry',
    label: 'Bauteil',
  },
  verticalTransmission: {
    tab: 'vertical-transmission',
    label: 'Vertikale Verbindungen',
  },
  connector: {
    tab: 'connector',
    label: 'Zugverbindungen',
  },
}
