import { ReactElement } from 'react'
import { ArrowsDirectional, DimensionsIndicator } from '@scene'
import { MarqueeSelectionController } from '@modugen/scene/lib/controllers/MarqueeSelectionController'
import { useElementSelectionStore } from '@editorStores'
import useGlobalLoadsQueryParams from '../../../../hooks/useGlobalLoadsQueryParams'

const WindloadsScene = (): ReactElement => {
  const selectionEnabled = useElementSelectionStore(state => state.selectionEnabled)

  const {
    params: { activeRoofAssignmentTab, buildingDimension },
  } = useGlobalLoadsQueryParams()

  return (
    <>
      {activeRoofAssignmentTab && (
        <ArrowsDirectional key={activeRoofAssignmentTab} angle={activeRoofAssignmentTab} />
      )}
      {buildingDimension && <DimensionsIndicator dimension={buildingDimension} />}
      {selectionEnabled && <MarqueeSelectionController />}
    </>
  )
}

export default WindloadsScene
