import { ReactElement, useRef, useState } from 'react'
import { isUndefined } from 'lodash-es'
import { ColorRepresentation, Object3D, Vector2 } from 'three'
import {
  DraggableLine,
  DraggableLineRef,
  LineHandles,
} from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { getOrientedWorldNormalsForIntersection } from '@modugen/scene/lib/controllers/DrawController/utils'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useControlStore } from '@editorStores'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'

interface Props {
  minLength?: number
  enabled?: boolean
  color?: string | ColorRepresentation

  additionalSnapTargets?: Object3D[]

  onDrawStart?: () => void
  onDrawEnd?: (start: Vector2, end: Vector2) => void
}

const Drawer2D = ({
  minLength,
  enabled = true,
  onDrawStart,
  onDrawEnd,
  color,
  additionalSnapTargets,
}: Props): ReactElement => {
  const drawControllerRef = useRef<DrawControllerRef>(null)
  const lineRef = useRef<DraggableLineRef>(null)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const [showTempLine, setShowTempLine] = useState(false)

  useStructuralPlanningDrawerEsc(() => {
    setShowTempLine(false)
    drawControllerRef.current?.abortDrawing()
  }, showTempLine)

  // snapping

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)

  const onDrawStartLine = (transientDrawState: TransientDrawState) => {
    if (transientDrawState.drawPoint && lineRef.current) {
      setShowTempLine(true)
      onDrawStart?.()

      // in order to prevent zero-width lines on every mousedown, we only
      // prepare the state but do not start drawing. setHandleAndStartDragging()
      // gets triggered inside mousemove based on the state set here

      // the angle snapping origin needs to be defined outside of the draw
      // controller to allow for flexibility (see onLineEditStart)
      const { drawPoint, drawTarget } = transientDrawState

      if (drawPoint && drawTarget) {
        // define the plane angular snapping needs to work inside
        const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

        if (snappingNormals) {
          drawControllerRef.current?.setAngleSnappingOrigin({
            origin: drawPoint,
            ...snappingNormals,
          })
        }
      }
    }
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { drawPoint } = transientDrawState

    if (!drawPoint) return

    if (lineRef.current?.activeHandle) {
      lineRef.current.updateActiveHandle(drawPoint)
    } else {
      lineRef.current?.setHandleAndStartDragging(LineHandles.End, drawPoint, drawPoint)
    }
  }

  const onDrawEndLine = () => {
    setShowTempLine(false)

    const { current: tscurr } = lineRef

    if (tscurr && tscurr.activeHandle) {
      const line = tscurr.stopDraggingAndGetLine()

      if (isUndefined(minLength) || line.start.distanceTo(line.end) >= minLength) {
        onDrawEnd?.(new Vector2(line.start.x, line.start.y), new Vector2(line.end.x, line.end.y))
      }
    }
  }

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={enabled && !isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        orthoSnap={snapOrthogonal}
        onDrawStart={onDrawStartLine}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEndLine}
        xyOnly
        indicatorType="crosshair"
        snapAngle={5}
        allowNegative={true}
        additionalSnapTargets={additionalSnapTargets}
      />

      <DraggableLine
        ref={lineRef}
        line={{
          start: new ImmutableVector3(-100, -100, -100),
          end: new ImmutableVector3(-100, -100, -100),
        }}
        isVisible={showTempLine}
        color={color as string}
        showIndicators={false}
      />
    </>
  )
}

export default Drawer2D
