import { ReactElement } from 'react'
import { MemberCheckResults, SupportResults } from '@resultsComponents'
import { Calculate } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { Info } from '@ui/feedback'
import Box from '@ui/structure/Box'
import PositionResultsGrid from '../PositionResultsGrid'
import LintelResultsGrid from '../PositionResultsGrid/components/LintelResultsGrid'

const positionTypesWithShearForces: PositionGroupingType[] = [
  'beams',
  'purlins',
  'wall-lintels',
  'roof-slab-beams',
  'slab-beams',
]

interface Props {
  grouping: MemberPositionBundleWithMaxUtilization[] | null
  positionType: PositionGroupingType
  designForces?: DesignSupportForce[]

  // member checks
  checksOfPosition?: CombinedPositionCheck[]
  settingsOnMember?: SettingsOnMember | null

  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

const GroupingsTab = ({
  grouping,
  designForces,

  checksOfPosition,
  settingsOnMember,

  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: Props): ReactElement => {
  if (!grouping)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
      </Box>
    )

  if (grouping.length === 0)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Keine Elemente vorhanden</Info>
      </Box>
    )

  return (
    <Stack direction="column" spacing={2}>
      {positionType === 'wall-lintels' ? (
        <LintelResultsGrid
          bundles={grouping}
          positionType={positionType}
          isDownloadingExportDocument={isDownloadingExportDocument}
          onClickDownloadExportDocument={onClickDownloadExportDocument}
          showShearCheckColumn
        />
      ) : (
        <PositionResultsGrid
          bundles={grouping}
          positionType={positionType}
          isDownloadingExportDocument={isDownloadingExportDocument}
          onClickDownloadExportDocument={onClickDownloadExportDocument}
          showShearCheckColumn={positionTypesWithShearForces.includes(positionType)}
        />
      )}

      {designForces && <SupportResults designForces={designForces} defaultExpanded />}

      {checksOfPosition && (
        <MemberCheckResults
          checksOfPosition={checksOfPosition}
          settingsOnMember={settingsOnMember || null}
          disableSaving
        />
      )}
    </Stack>
  )
}

export default GroupingsTab
