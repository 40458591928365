import { ReactElement, ReactNode } from 'react'
import produce from 'immer'
import { cloneDeep, findIndex, filter } from 'lodash-es'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'

interface Props {
  children: ReactNode
}

interface ElementLoadStoreInitialType {
  loads: ElementLoad[]
  loadResults: ElementLoad[]
}

interface ElementLoadStoreType extends ElementLoadStoreInitialType {
  setLoadsPerElements: (loadsPerElements: ElementLoad[]) => void
  setLoadResults: (loadsPerElements: ElementLoad[]) => void
  editLoadsPerElement: (load: ElementLoad) => void
  addLoadPerElement: (load: ElementLoad) => void
  removeLoad: (loadGuid: string) => void
  clear: () => void
}

const initialState: ElementLoadStoreInitialType = {
  loads: [],
  loadResults: [],
}

const createStore = () =>
  create(
    combine(cloneDeep(initialState), set => ({
      clear: () => set(initialState),

      setLoadResults: (loads: ElementLoad[]) => {
        set(
          produce(state => {
            state.loadResults = loads
          }),
        )
      },

      setLoadsPerElements: (loads: ElementLoad[]) =>
        set(
          produce(state => {
            state.loads = loads
          }),
        ),

      addLoadPerElement: (load: ElementLoad) =>
        set(
          produce(state => {
            state.loads.push(load)
          }),
        ),

      editLoadsPerElement: (load: ElementLoad) =>
        set(
          produce(state => {
            const index = findIndex(state.loads, ['guid', load?.guid])

            if (index === -1) return

            state.loads[index] = load
          }),
        ),

      removeLoad: (loadGuid: string) =>
        set(
          produce(state => {
            state.loads = filter(state.loads, load => load?.guid !== loadGuid)
          }),
        ),
    })),
  )

const {
  Provider,
  useStore: useElementLoadStore,
  useStoreApi: useElementStoreApi,
} = createContext<ElementLoadStoreType>()

const ElementLoadStoreProvider = ({ children }: Props): ReactElement => (
  <Provider createStore={createStore}>{children}</Provider>
)

export { ElementLoadStoreProvider, useElementLoadStore, useElementStoreApi }
