import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { Typography, Stack, MenuItem, Divider } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Switch, TextField } from '@ui/forms'
import { Box } from '@ui/structure'
import { roofSlabConfig } from '../../constants'

interface Props {
  selectedElement?: string
  upperMostCeiling?: string

  isSavingDistributionDeactivation: boolean
  isSavingTransmissionGraph: boolean
}

const FormFields = ({
  selectedElement,
  upperMostCeiling,
  isSavingDistributionDeactivation,
  isSavingTransmissionGraph,
}: Props): ReactElement => {
  const { watch } = useFormContext()
  const upperMostCeilingNotStiffening = watch('upperMostCeilingNotStiffening')

  const upperMostCeilingSelected =
    upperMostCeiling && selectedElement && upperMostCeiling === selectedElement

  return (
    <>
      {upperMostCeilingSelected && (
        <>
          <Stack mb={1.5} mt={1.5}>
            <Stack direction="row" alignItems="center">
              <Switch
                name="upperMostCeilingNotStiffening"
                size="small"
                data-cy="top-slab-stiffening-switch"
              />
              <Typography sx={{ p: 1 }} noWrap>
                Verteilung für dieses Element deaktivieren
              </Typography>
            </Stack>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <SaveButton
                size="small"
                type="submit"
                name="deactivate-distribution"
                loading={isSavingDistributionDeactivation}
                disabled={isSavingTransmissionGraph}
                data-cy="top-slab-stiffening-submit"
              >
                Speichern
              </SaveButton>
            </Box>
          </Stack>

          <Divider sx={{ mb: 3, mt: 3 }} />
        </>
      )}
      <Typography mb={1}>Weisen Sie diesem Element eine Verteilungsmethode zu</Typography>
      <TextField
        name="distribution"
        select
        inputLabel="Verteilungsmethode"
        fullWidth
        sx={{ bgcolor: 'grey.50' }}
        disabled={upperMostCeilingSelected && upperMostCeilingNotStiffening}
      >
        {roofSlabConfig.map(({ label, value }, index) => (
          <MenuItem value={value} key={index}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}

export default FormFields
