import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useResultsQueryParams } from '@resultsHooks'
import { filter, reject } from 'lodash-es'
import { Calculate } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { Info } from '@ui/feedback'
import Box from '@ui/structure/Box'
import { useControlStore, useResultsStore } from '@editorStores'
import { AreaLoadTable, LineLoadTable, PointLoadTable } from './components'

interface Props {
  loads: Array<DomainLoad | AreaLoad> | null
}

const LoadResults = ({ loads }: Props): ReactElement => {
  const {
    params: { loadTab, selectedLoad },
    actions: { setLoadTab, selectLoad },
  } = useResultsQueryParams()

  const hiddenLoads = useResultsStore(state => state.hiddenLoads)
  const [localHiddenLoads, setLocalHiddenLoads] = useState<string[]>([])
  const setHiddenLoads = useResultsStore(state => state.setHiddenLoads)
  const clearHiddenLoads = useResultsStore(state => state.clearHiddenLoads)
  const loadTracingMap = useResultsStore(state => state.loadTracingMap)

  const isAnchorCalculationMode = useControlStore(state => state.isAnchorCalculationMode)

  const toggleHideLoad = useCallback(
    (load: string) => {
      if (localHiddenLoads.includes(load)) {
        setLocalHiddenLoads(reject(localHiddenLoads, hiddenLoad => hiddenLoad === load))
      } else {
        setLocalHiddenLoads([...localHiddenLoads, load])
      }
    },
    [localHiddenLoads],
  )

  const toggleShowSingleLoad = useCallback(
    (load: string) => {
      if (!loads) return

      const allLoadGuids = loads.map(el => el.guid)

      if (!localHiddenLoads.includes(load) && localHiddenLoads.length === allLoadGuids.length - 1) {
        setLocalHiddenLoads([])
        return
      }

      setLocalHiddenLoads(reject(allLoadGuids, guid => load === guid))
    },
    [loads, localHiddenLoads],
  )

  const { lineLoads, pointLoads, areaLoads } = useMemo(
    () => ({
      lineLoads: loads && (filter(loads, ['load_type', 'line-load']) as LineLoadWithDomain[]),
      pointLoads: loads && (filter(loads, ['load_type', 'point-load']) as PointLoadWithDomain[]),
      areaLoads: loads && (filter(loads, ['load_type', 'area-load']) as AreaLoad[]),
    }),
    [loads],
  )

  const [selectedCategories, setSelectedCategories] = useState<LoadCategory[]>(
    isAnchorCalculationMode ? ['Dead'] : ['Dead', 'Live', 'Snow', 'SnowPlus', 'Wind', 'EarthQuake'],
  )

  const filteredLineLoads = useMemo(
    () => filter(lineLoads, load => selectedCategories.includes(load.load_case.category)),
    [selectedCategories, lineLoads],
  )

  const filteredPointLoads = useMemo(
    () => filter(pointLoads, load => selectedCategories.includes(load.load_case.category)),
    [selectedCategories, pointLoads],
  )

  const filteredAreaLoads = useMemo(
    () => filter(areaLoads, load => selectedCategories.includes(load.load_case.category)),
    [selectedCategories, areaLoads],
  )

  const allHiddenLoads = useMemo(
    () => [
      ...localHiddenLoads,
      ...filter(
        [...(lineLoads || []), ...(pointLoads || []), ...(areaLoads || [])],
        load => !selectedCategories.includes(load.load_case.category),
      ).map(load => load.guid),
    ],
    [localHiddenLoads, lineLoads, pointLoads, areaLoads, selectedCategories],
  )

  useEffect(() => {
    setHiddenLoads(allHiddenLoads)

    return clearHiddenLoads
  }, [allHiddenLoads])

  return (
    <Box margin={-2}>
      <TabContext value={loadTab || 'point-load'}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList
            scrollButtons="auto"
            onChange={(_, value) => {
              setLoadTab(value)
              setLocalHiddenLoads([])
            }}
          >
            <Tab value="point-load" label="Punktlasten" />
            <Tab value="line-load" label="Linienlasten" />
            <Tab value="area-load" label="Flächenlasten" />
          </TabList>
        </Box>
        <TabPanel value="point-load">
          {filteredPointLoads && loadTracingMap ? (
            <PointLoadTable
              pointLoads={filteredPointLoads}
              openLoad={selectedLoad}
              selectLoad={selectLoad}
              hiddenLoads={hiddenLoads}
              onToggleHideLoad={toggleHideLoad}
              onToggleShowSingleLoad={toggleShowSingleLoad}
              selectedCategories={selectedCategories}
              onSelectCategories={setSelectedCategories}
              loadTracingMap={loadTracingMap}
            />
          ) : (
            <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
          )}
        </TabPanel>
        <TabPanel value="line-load">
          {filteredLineLoads && loadTracingMap ? (
            <LineLoadTable
              lineLoads={filteredLineLoads}
              openLoad={selectedLoad}
              selectLoad={selectLoad}
              hiddenLoads={localHiddenLoads}
              onToggleHideLoad={toggleHideLoad}
              selectedCategories={selectedCategories}
              onSelectCategories={setSelectedCategories}
              onToggleShowSingleLoad={toggleShowSingleLoad}
              loadTracingMap={loadTracingMap}
            />
          ) : (
            <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
          )}
        </TabPanel>
        <TabPanel value="area-load">
          {filteredAreaLoads ? (
            <AreaLoadTable
              areaLoads={filteredAreaLoads}
              openLoad={selectedLoad}
              selectLoad={selectLoad}
              hiddenLoads={hiddenLoads}
              onToggleHideLoad={toggleHideLoad}
              onToggleShowSingleLoad={toggleShowSingleLoad}
              selectedCategories={selectedCategories}
              onSelectCategories={setSelectedCategories}
            />
          ) : (
            <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default LoadResults
