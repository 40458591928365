import { ReactElement, ReactNode } from 'react'
import { CircularProgress, IconButton } from '@mui/material'

interface Props {
  isLoading: boolean
  onClick: () => void
  children: ReactNode
}

const IconButtonProgress = ({ isLoading, onClick, children }: Props): ReactElement => (
  <IconButton onClick={onClick} disabled={isLoading}>
    {isLoading ? <CircularProgress size={18.5} /> : children}
  </IconButton>
)

export default IconButtonProgress
