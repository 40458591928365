import { ReactElement } from 'react'
import { getPlaneFromShape, LocalCoordinateSystem } from '@editorUtils'
import { toNumber } from 'lodash-es'
import { Vector3 } from 'three'
import { Stack } from '@mui/material'
import { RectangleXYZ } from '@modugen/scene/lib/types'
import {
  getFlatShapeNormal,
  getRectCornersFromStartAndEndPoint,
  toImmutable,
} from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { TextField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  isEditable: boolean
  wall: ShapeObject
  opening: Opening
}

const FormFields = ({ isEditable, opening, wall }: Props): ReactElement => {
  const updateOpening = useModelStore(state => state.updateOpening)

  useStoreSubscription({
    writeCallback: ({ xStart, yStart, xEnd, yEnd }) => {
      const start = new Vector3(toNumber(xStart), toNumber(yStart))
      const end = new Vector3(toNumber(xEnd), toNumber(yEnd))

      const points = getRectCornersFromStartAndEndPoint(
        new ImmutableVector3(start.x, start.y, 0),
        new ImmutableVector3(end.x, end.y, 0),
      )

      const wallShapeAsVectors = wall.shape.points as RectangleXYZ
      const matrix = LocalCoordinateSystem.makeFromPolygon(wallShapeAsVectors).matrixTransform

      const normal = getFlatShapeNormal(wallShapeAsVectors)

      const openingPoints = points.map(p => p.applyMatrix4(matrix).addScaledVector(normal, 0.1))

      const wallPlane = getPlaneFromShape(wallShapeAsVectors)

      const projectedOpeningPoints = openingPoints.map(p =>
        toImmutable(wallPlane.projectPoint(p.v, new Vector3())),
      )

      updateOpening(wall.guid, {
        ...opening,
        shape: {
          guid: opening.shape.guid,
          points: projectedOpeningPoints,
        },
      })
    },
  })

  return (
    <Stack
      spacing={1.25}
      border={1}
      p={1}
      pt={1.5}
      pb={1.25}
      borderColor="grey.200"
      borderRadius={1}
      sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xStart"
          unit="m"
          disabled={!isEditable}
        />

        <TextField
          label="Y Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yStart"
          unit="m"
          disabled={!isEditable}
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xEnd"
          unit="m"
          disabled={!isEditable}
        />

        <TextField
          label="Y Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yEnd"
          unit="m"
          disabled={!isEditable}
        />
      </Stack>
    </Stack>
  )
}

export default FormFields
