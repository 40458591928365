import { ReactElement } from 'react'
import { useStoreSubscription } from '@editorHooks'

const StoreSubscription = (props: StoreSubscription): ReactElement => {
  useStoreSubscription(props)

  return <></>
}

export default StoreSubscription
