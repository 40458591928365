import { ReactElement } from 'react'
import { Stack } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { TextField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

const FormFields = (): ReactElement => {
  const updateColumn = useModelStore(state => state.updateColumn)

  useStoreSubscription({
    writeCallback: ({ guid, x, y, zStart, zEnd }) => {
      updateColumn({
        guid,
        shape: {
          start: new ImmutableVector3(Number(x), Number(y), Number(zStart)),
          end: new ImmutableVector3(Number(x), Number(y), Number(zEnd)),
        },
      })
    },
  })

  return (
    <Stack
      spacing={1.25}
      border={1}
      p={1}
      pt={1.5}
      pb={1.25}
      borderColor="grey.200"
      borderRadius={1}
      sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
    >
      <Stack direction="column" spacing={1} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            label="X"
            type="number"
            size="small"
            sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
            name="x"
            unit="m"
          />
          <TextField
            label="Y"
            type="number"
            size="small"
            sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
            name="y"
            unit="m"
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            label="Z Start"
            type="number"
            size="small"
            sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
            name="zStart"
            unit="m"
            disabled={true}
          />
          <TextField
            label="Z Ende"
            type="number"
            size="small"
            sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
            name="zEnd"
            unit="m"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FormFields
