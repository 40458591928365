import { ReactElement } from 'react'
import { Grid2, Stack, Typography } from '@mui/material'
import { InfoTooltip } from '@ui/feedback'
import { MillimeterField, UnitConvertedField } from '@ui/forms'
import { Box } from '@ui/structure'

interface SteelBasePlateConfigProps {
  disabled?: boolean
}

const SteelBasePlateConfig = ({
  disabled = false,
}: SteelBasePlateConfigProps): ReactElement | null => {
  return (
    <Box
      p={1}
      border={1}
      borderColor="grey.200"
      borderRadius={1}
      data-cy="steel-base-plate-fields-container"
    >
      <Typography variant="h6">Fußpunkt</Typography>

      <Stack direction="column" spacing={2}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <MillimeterField
              name={`configuration.base_plate_geometry.thickness`}
              label="Dicke"
              numeralFormatter="0" // 0 decimals
              disabled={disabled}
            />
          </Grid2>
          <Grid2 size={12}>
            <MillimeterField
              name={`configuration.base_plate_geometry.length`}
              label="Länge (parallel zum Steg)"
              numeralFormatter="0" // 0 decimals
              disabled={disabled}
            />
          </Grid2>
          <Grid2 size={12}>
            <MillimeterField
              name={`configuration.base_plate_geometry.width`}
              label="Breite (parallel zum Flansch)"
              numeralFormatter="0" // 0 decimals
              disabled={disabled}
            />
          </Grid2>
          <Grid2 size={12}>
            <Grid2 container spacing={2}>
              <Grid2 size={11}>
                <UnitConvertedField
                  name={`configuration.design_support_material_strength`}
                  conversionFactor={1000000}
                  label="Design-Widerstand des darunterliegenden Materials"
                  type="number"
                  unitLabel="N/mm²"
                  disabled={disabled}
                />
              </Grid2>
              <Grid2 size={1}>
                <InfoTooltip
                  text="für Holz: der Design-Widerstand muss bereits mit allen Beiwerten wie γ_M und k_mod multipliziert werden"
                  secondLineText="für Beton/Mörtel: der Design-Widerstand muss mit dem Faktor 2/3 (β_j) multipliziert werden (s. EC3-1-8 6.2.5 (2010-12))"
                />
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <MillimeterField
              name={`configuration.weld_thickness`}
              label="Schweißnahtdicke"
              numeralFormatter="0" // 0 decimals
              disabled={disabled}
            />
          </Grid2>
        </Grid2>
      </Stack>
    </Box>
  )
}

export default SteelBasePlateConfig
