import { createRef } from 'react'
import { AnchorDataGridRef } from '../components/AnchorDataGrid'

const tableRef = createRef<AnchorDataGridRef | null>()

const useAnchorTable = () => {
  const setRef = (ref: AnchorDataGridRef) => {
    // @ts-ignore
    tableRef.current = ref
  }

  return {
    tableRef,
    setRef,
  }
}

export default useAnchorTable
