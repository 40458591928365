import { ReactElement, ReactNode } from 'react'
import { Grid, Typography, useTheme } from '@mui/material'
import { Box } from '@ui/structure'

interface Option {
  label: string
  value: string
  Icon?: ReactNode
}

interface Props {
  value: any // eslint-disable-line
  onClick: (value: any) => void // eslint-disable-line
  options: Option[]
}

const LoadOption = ({ onClick, options, value: currentValue }: Props): ReactElement => {
  const theme = useTheme()

  return (
    <>
      {options.map(({ value, label, Icon }) => (
        <Box sx={{ width: theme.spacing(25) }} key={value} data-cy={`load-option-${value}`}>
          <Grid
            onClick={event => {
              event.stopPropagation()
              onClick(value)
            }}
            container
            sx={theme => ({
              p: theme.spacing(0.5),
              backgroundColor: value === currentValue ? theme.palette.grey[400] : undefined,
            })}
            direction="row"
          >
            <Grid item md={10}>
              <Typography>{label}</Typography>
            </Grid>
            {Icon && (
              <Grid item md={2}>
                {Icon}
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </>
  )
}

export default LoadOption
