import { useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'

const Snowfall = ({ width = 30, height = 30, depth = 30, count = 5000 }) => {
  const particlesRef = useRef()
  const [positions] = useState(() => {
    const temp = new Float32Array(count * 3)
    for (let i = 0; i < count; i++) {
      temp[i * 3] = Math.random() * width - width / 2 // X-axis
      temp[i * 3 + 1] = Math.random() * depth - depth / 2 // Y-axis
      temp[i * 3 + 2] = Math.random() * height // Z-axis (Up direction)
    }
    return temp
  })

  const [velocities] = useState(() => {
    const temp = new Float32Array(count)
    for (let i = 0; i < count; i++) {
      temp[i] = Math.random() * 0.02 + 0.01 // Random fall speed
    }
    return temp
  })

  useFrame(() => {
    // @ts-ignore
    const positionsArray = particlesRef.current.geometry.attributes.position.array
    for (let i = 0; i < count; i++) {
      // Update the Z position for each particle
      positionsArray[i * 3 + 2] -= velocities[i]
      // Reset the particle position when it falls below the ground
      if (positionsArray[i * 3 + 2] < 0) {
        positionsArray[i * 3 + 2] = height
      }
    }
    // @ts-ignore
    particlesRef.current.geometry.attributes.position.needsUpdate = true
  })

  return (
    // @ts-ignore
    <points ref={particlesRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={positions}
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial size={0.1} color="white" transparent opacity={0.8} />
    </points>
  )
}

export default Snowfall
