import { ReactElement } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material'
import FormControl from '../FormControl'

interface Props extends Omit<CheckboxProps, 'onChange'> {
  name: string
  disabled?: boolean
  'data-cy'?: string
  tooltip?: TooltipContents
  onChange?: (checked: boolean) => void
}

const Checkbox = ({
  name,
  disabled,
  'data-cy': dataCy,
  tooltip,
  onChange: onChangeProp,
  ...props
}: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, name: fieldName, value },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <FormControl error={error?.message} fullWidth={false} tooltip={tooltip}>
      <MuiCheckbox
        name={fieldName}
        checked={value}
        onChange={e => {
          onChange(e.target.checked)
          onChangeProp?.(e.target.checked)
        }}
        data-cy={dataCy || `cy.${fieldName}`}
        disabled={disabled}
        {...props}
      />
    </FormControl>
  )
}

export default Checkbox
