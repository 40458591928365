import { ReactElement, useCallback, useMemo, useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useResultsQueryParams } from '@resultsHooks'
import { orderBy } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { getCheckTypeSortValue, memberCheckTypeToReadableMap } from '../utils'
import MemberCheckRow from './MemberCheckRow'

interface Props {
  positionChecks: CombinedPositionCheck[]
  elementGuid?: string | null
  download?: UseMutateFunction<
    {
      data: Blob
      filename: string
    },
    unknown,
    string,
    unknown
  > | null
  isLoadingDownload?: boolean
}

interface CheckWithIndexForSettings {
  check: CombinedPositionCheck
  index: number | undefined
}

const OtherMemberChecksTable = ({
  positionChecks,
  elementGuid = null,
  download = null,
  isLoadingDownload = false,
}: Props): ReactElement | null => {
  const [sortBy, setSortBy] = useState<'type' | 'utilization' | 'position'>('type')
  const [sortAsc, setSortAsc] = useState(true)
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const {
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const sortFilter = useCallback(
    (checkWithSetting: CheckWithIndexForSettings) => {
      const check = checkWithSetting.check

      let sortFilter: MemberCheckType | number = check.max_utilization

      if (
        sortBy === 'position' &&
        check.check_category_type !== 'Vibration' &&
        check.check_type !== 'Vibration' &&
        check.check_category_type !== 'HotDimensioning' &&
        check.check_type !== 'HotDimensioning'
      )
        sortFilter = check.relative_position
      if (sortBy === 'type') {
        sortFilter = getCheckTypeSortValue(check.check_type as MemberCheckType)
      }

      return sortFilter
    },
    [sortBy],
  )

  const checkWithIndex: CheckWithIndexForSettings[] = useMemo(() => {
    return positionChecks.map(check => {
      return { check: check, index: undefined }
    })
  }, [positionChecks])

  const sortedChecks = useMemo(
    () => orderBy(checkWithIndex, [sortFilter], sortAsc ? 'asc' : 'desc'),
    [checkWithIndex, sortAsc, sortFilter],
  )

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '35%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'type'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'type') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('type')
                  }}
                >
                  <Tooltip title="Typ des Nachweises" placement="top">
                    <Typography>Typ</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={sortBy === 'utilization'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'utilization') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('utilization')
                  }}
                >
                  <Tooltip title="Maximale Ausnutzung" placement="top">
                    <Typography>Ausnutzung</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sortBy === 'position'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'position') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('position')
                  }}
                >
                  <Tooltip title="Relative Position" placement="top">
                    <Typography>Position</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-cy="member-checks-table-body">
            {sortedChecks.map(({ check }, i) => {
              const typeReadable =
                memberCheckTypeToReadableMap[check.check_type] || check.check_type

              if (
                (check.check_category_type === 'Vibration' && check.check_type === 'Vibration') ||
                (check.check_category_type === 'HotDimensioning' &&
                  check.check_type === 'HotDimensioning')
              )
                return <></>
              return (
                <MemberCheckRow
                  isOpen={i === openIndex}
                  setOpenIndex={setOpenIndex}
                  typeReadable={typeReadable}
                  check={check}
                  index={i}
                  key={i}
                  openCheck={setSelectedCheckPosition}
                ></MemberCheckRow>
              )
            })}
            {positionChecks.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center" m={1}>
                    Keine Nachweise auf dem Element vorhanden
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {download && elementGuid && (
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <LoadingButton
            loading={isLoadingDownload}
            variant="outlined"
            size="small"
            onClick={() => download(elementGuid)}
          >
            Export (docx)
          </LoadingButton>
        </Stack>
      )}
    </>
  )
}

export default OtherMemberChecksTable
