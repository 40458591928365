import { ReactElement } from 'react'
import { find } from 'lodash-es'
import { Select } from '@ui/forms'
import { LoadCaseId, loadCaseOptions } from '../constants'

interface Props {
  value: LoadCaseId
  onSelect: (loadCase: {
    identifier: string
    category: LoadCategory
    loadCaseType: LoadCaseTypes
  }) => void
  disabled: boolean
}

const LoadCaseSelect = ({ value, onSelect, disabled }: Props): ReactElement => {
  const options = Object.entries(loadCaseOptions).map(([key, value]) => ({
    value: key,
    label: value.label,
  }))

  return (
    <Select
      label="Lastfall auswählen"
      // as we overwrite onChange and value this does not matter
      name="undefined"
      options={options}
      onChange={e => {
        const option = find(loadCaseOptions, (v, key) => e.target.value === key)
        if (option) onSelect(option)
      }}
      // options are composed of category and load case type, hence we can
      // safely set the value here like this (see definition of options)
      value={value}
      disabled={disabled}
    />
  )
}

export default LoadCaseSelect
