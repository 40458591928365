import { ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getLineCoordinateSystem } from '@editorUtils'
import { ShapeMesh } from '@scene'
import { find, max, some, toNumber } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material/styles'
import {
  DraggableLine,
  DraggableLineRef,
  LineHandles,
} from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { getOrientedWorldNormalsForIntersection } from '@modugen/scene/lib/controllers/DrawController/utils'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { roofStoreyKey, useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'
import useWallSnapTargets from 'src/components/pages/Editor/pages/StructuralPlanning/components/FloorplanDrawer/hooks/useModelSnapTargets'
import useTapelineSnapTargets from 'src/components/scene/hooks/useTapelineSnapTargets'
import Beam3D, { BeamElements } from './components/Beam3D'

interface TransientLineState {
  lineRef?: RefObject<DraggableLineRef>
}

const minPurlinLength = 0.1

const BeamDrawer3D = (): ReactElement => {
  const { scenePalette } = useTheme()

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const lineRef = useRef<DraggableLineRef>(null)
  const [activeTarget, setActiveTarget] = useState<string | null>(null)

  const elements = useGuidToElement()

  const typeVisibility = useModelStore(state => state.visibilityByType)
  const visibleStoreys = useModelStore(state => state.visibleStoreys)
  const model = useModelStore(state => state.model)
  const addBeam = useModelStore(state => state.addBeam)
  const removeBeam = useModelStore(state => state.removeBeam)
  const maxStorey = max(
    Object.keys(model.storey_boundaries).map(s => toNumber(s)),
  )?.toString() as string

  const activeElement = useEditElementStore(state => state.activeElement)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const setSnapToAngles = useControlStore(state => state.setSnapToAngles)
  const setIsDrawingActive = useControlStore(state => state.setIsDrawingActive)
  const setSnappingFeaturesEnabled = useControlStore(state => state.setSnappingFeaturesEnabled)

  const setAdditionalSnapTargets = useTapelineStore(state => state.setAdditionalSnapTargets)
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const setSnapToAnglesTapelines = useTapelineStore(state => state.setSnapToAngles)

  const [showTempLine, setShowTempLine] = useState(false)

  const transientLineState = useRef<TransientLineState>({})

  const localBeam = useMemo(() => find(model.beams, 'is_local'), [model.beams])

  const wallLineTargets = useWallSnapTargets({})

  const tapelineTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  const snapTargets = useMemo(
    () => [...wallLineTargets, ...tapelineTargets, ...tapelineCenterTargets],
    [wallLineTargets, tapelineTargets, tapelineCenterTargets],
  )

  // EFFECTS

  useEffect(() => {
    setAdditionalSnapTargets(snapTargets)

    return () => {
      setAdditionalSnapTargets(undefined)
    }
  }, [setAdditionalSnapTargets, snapTargets])

  useEffect(() => {
    setIsDrawingActive(true)

    setSnapToAngles(true)
    setSnapToAnglesTapelines(true)

    return () => setIsDrawingActive(false)
  }, [])

  useEffect(() => {
    if (!isTapelineActive) {
      setSnappingFeaturesEnabled({
        snapToCornersAndEdges: true,
        snapOrthogonal: true,
        snapToAngle: true,
        snapToTapelineCenters: true,
      })
    }

    return () => {
      setSnappingFeaturesEnabled({
        snapOrthogonal: true,
        snapToCornersAndEdges: true,
        snapToAngle: true,
        snapToTapelineCenters: true,
      })
    }
  }, [isTapelineActive])

  // EVENTS

  const onDrawStart = useCallback((state: TransientDrawState) => {
    const { drawPoint, drawTarget } = state

    if (drawPoint && drawTarget) {
      const guid = drawTarget.object.name
      setActiveTarget(guid)
    }

    if (lineRef.current && drawPoint) {
      setShowTempLine(true)

      lineRef.current?.setHandleAndStartDragging(LineHandles.End, drawPoint, drawPoint)
      transientLineState.current.lineRef = lineRef

      // the angle snapping origin needs to be defined outside of the draw
      // controller to allow for flexibility (see onLineEditStart)

      if (drawPoint && drawTarget) {
        if (snapToAngles) {
          // define the plane angular snapping needs to work inside
          const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

          if (snappingNormals) {
            drawControllerRef.current?.setAngleSnappingOrigin({
              origin: drawPoint,
              ...snappingNormals,
            })
          }
        }
      }
    }
  }, [])

  const onDrawEnd = () => {
    setShowTempLine(false)

    const { lineRef } = transientLineState.current

    if (lineRef?.current && activeTarget) {
      const drawnLine = lineRef.current.stopDraggingAndGetLine()

      const zStart = drawnLine.start.z
      const zEnd = drawnLine.end.z

      const isHorizontal = zStart.toFixed(2) === zEnd.toFixed(2)

      // in order to place the purlin on the exact same height and therefore
      // prevent any errors in the backend we have to move the end to the same
      // height if both ends are similar to each other
      const zEndFinal = isHorizontal ? drawnLine.start.z : drawnLine.end.z

      if (drawnLine.start.distanceTo(drawnLine.end) >= minPurlinLength) {
        const start = drawnLine.start
        const end = new ImmutableVector3(drawnLine.end.x, drawnLine.end.y, zEndFinal)
        const targetElement = elements[activeTarget]
        const beamStorey = targetElement.type === 'roof_slabs' ? maxStorey : targetElement.storey
        const beam = {
          guid: uuid(),
          shape: {
            start,
            end,
          },
          storey: beamStorey,
          is_local: true,
          coordinate_system: getLineCoordinateSystem(start, end),
        }

        addBeam(beam)
        setActiveElement(beam.guid)
        transientLineState.current = {}
      }
    }
  }

  const onDrawMouseMove = useCallback((state: TransientDrawState) => {
    if (state.drawPoint && transientLineState.current.lineRef) {
      lineRef.current?.updateActiveHandle(state.drawPoint)
    }
  }, [])

  useStructuralPlanningDrawerEsc(() => {
    if (showTempLine) {
      setShowTempLine(false)
      drawControllerRef.current?.abortDrawing()
      drawControllerRef.current?.setAngleSnappingOrigin(undefined)
      transientLineState.current = {}
    }
  }, showTempLine)

  return (
    <>
      {/* REDRAW HIDDEN ROOF SLABS */}
      {model.roof_slabs.map(roof_slab => {
        return (
          <ShapeMesh
            key={roof_slab.guid}
            data={roof_slab}
            translucent
            shapeColor={scenePalette.elements3d.roof_slabs}
            noPointerInteractions
            meshMaterialProps={{
              opacity: 0.1,
              transparent: true,
            }}
            visible={typeVisibility.roof_slabs && visibleStoreys.has(roofStoreyKey)}
          />
        )
      })}

      {/* RENDER PREVIOUSLY HIDDEN PURLINS */}

      {model.beams.map(beam => (
        <Beam3D
          key={beam.guid}
          beam={beam}
          isActive={activeElement === beam.guid}
          onClick={() => {
            if (localBeam) removeBeam(localBeam.guid)
            setActiveElement(beam.guid)
          }}
        />
      ))}

      {/* DRAWING INTERACTION */}

      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        enableIndicator
        color={scenePalette.elements3d.beams as string}
        additionalSnapTargets={snapTargets}
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        snapAngle={90}
        isValidDrawStart={intersections => {
          return !some(
            intersections,
            intersection => intersection.object.name === BeamElements.Line,
          )
        }}
      />

      {/* TEMP LINE */}

      <DraggableLine
        ref={lineRef}
        line={{ start: new ImmutableVector3(0, 0, 1000), end: new ImmutableVector3(0, 0, 1001) }}
        lineName={BeamElements.Line}
        handleName={BeamElements.Handle}
        color={scenePalette.elements3d.beams as string}
        showIndicators={false}
        isVisible={showTempLine}
      />
    </>
  )
}

export default BeamDrawer3D
