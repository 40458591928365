import { ReactElement } from 'react'
import Save from '@mui/icons-material/Save'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface Props extends LoadingButtonProps {
  children: string
}

const SaveButton = ({ children, ...props }: Props): ReactElement => (
  <LoadingButton {...props} variant="contained" color="primary" startIcon={<Save />}>
    {children}
  </LoadingButton>
)

export default SaveButton
