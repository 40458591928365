import { ReactElement, useEffect, useState } from 'react'
import { getModelBoundingBox } from '@editorUtils'
import { DoubleSide } from 'three'
import { useTheme } from '@mui/material/styles'
import { useSprings } from '@react-spring/core'
import { a } from '@react-spring/three'
import { useModelStore } from '@editorStores'
import { springConfigByAngle } from './constants'
import { arrowPositionsByAngle } from './utils'

interface Props {
  angle: WindAngle
}

const ArrowsDirectional = ({ angle = '90' }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const model = useModelStore(state => state.model)
  const [vertices, setVertices] = useState<Float32Array[]>([])

  useEffect(() => {
    if (!model) return

    const bbox = getModelBoundingBox(model)
    const vertices = arrowPositionsByAngle(angle, bbox)

    setVertices(vertices)
  }, [model, angle])

  const [springs] = useSprings(3, springConfigByAngle[angle], [angle])

  return (
    <mesh key={angle}>
      {vertices.map((vertices, index: number) => (
        <a.mesh {...springs[index]} key={angle + index}>
          <bufferGeometry attach="geometry">
            <bufferAttribute
              attach={'attributes-position'}
              count={3}
              array={vertices}
              itemSize={3}
            />
          </bufferGeometry>
          <a.meshBasicMaterial
            opacity={springs[index].opacity}
            transparent={true}
            side={DoubleSide}
            attach="material"
            color={scenePalette.helpers}
          />
        </a.mesh>
      ))}
    </mesh>
  )
}

export default ArrowsDirectional
