import { ReactElement, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { Form, UnitConvertedField } from '@ui/forms'
import { useResultsInvalidation } from '@editorHooks'
import { getSimplifiedSeismicModelInputs, getSimplifiedSeismicResults } from '@queries'
import { postSimplifiedSeismicModelInputs } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import useStoreysForSeismic from '../../hooks/useStoreysForSeismic'
import { inputsPerStoreySchema } from './schema'

const SimplifiedSeismicModelInputsForm = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()

  const invalidateResultsQueries = useResultsInvalidation()
  const queryClient = useQueryClient()

  const { data: seismicModelInputs } = useQuery({
    queryKey: getSimplifiedSeismicModelInputs.getKey(projectId),
    queryFn: () => getSimplifiedSeismicModelInputs.request(projectId),
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler laden der Modelldaten'), {
        variant: 'error',
      })
    },
    refetchOnMount: true,
  })

  const storeys = useStoreysForSeismic()

  const { isLoading: isSaving, mutate } = useMutation(
    (data: SeismicSettings) => postSimplifiedSeismicModelInputs.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResultsQueries(projectId as string)
        queryClient.invalidateQueries(getSimplifiedSeismicModelInputs.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicResults.getKey(projectId))
        enqueueSnackbar('Modelldaten erfolgreich gespeichert und Ergebnisse berechnet', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Modelldaten'), {
          variant: 'error',
        })
      },
    },
  )

  const defaultValues = useMemo(() => {
    const schemaDefault = inputsPerStoreySchema.getDefault()
    if (!seismicModelInputs) {
      return schemaDefault
    }
    return { data: seismicModelInputs }
  }, [seismicModelInputs])

  if (!seismicModelInputs) {
    return (
      <Typography>
        Noch keine Modell-Daten verfügbar. Zuerst die Configuration speichern.
      </Typography>
    )
  }

  return (
    <Form
      validationSchema={inputsPerStoreySchema}
      defaultValues={defaultValues}
      onSubmit={data => {
        // Use .data because defining an array as root type seems not possible
        // with the yup validation
        mutate(data.data)
      }}
      enableReinitialize
    >
      <Grid container spacing={0} paddingTop={1} paddingBottom={1}>
        <Grid item paddingBottom={1} xs={12}>
          <Typography variant="h6" textAlign="center">
            Modelldaten
          </Typography>
        </Grid>
        {storeys.map(storey => (
          <Accordion key={`accordion-${storey}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{storey === 0 ? 'EG' : `${storey}. Stock`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <UnitConvertedField
                    label="X"
                    name={`data.${storey}.mass_point.location.x`}
                    unitLabel="m"
                    conversionFactor={1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <UnitConvertedField
                    label="Y"
                    name={`data.${storey}.mass_point.location.y`}
                    unitLabel="m"
                    conversionFactor={1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label="Masse"
                    name={`data.${storey}.mass_point.mass`}
                    unitLabel="t"
                    conversionFactor={1000}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label="X"
                    name={`data.${storey}.stiffness_point.location.x`}
                    unitLabel="m"
                    conversionFactor={1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label="Y"
                    name={`data.${storey}.stiffness_point.location.y`}
                    unitLabel="m"
                    conversionFactor={1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label="S_x"
                    name={`data.${storey}.stiffness_point.stiffness.x`}
                    unitLabel="kN/m"
                    conversionFactor={1000}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label="S_y"
                    name={`data.${storey}.stiffness_point.stiffness.y`}
                    unitLabel="kN/m"
                    conversionFactor={1000}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UnitConvertedField
                    label="Stockwerkshöhe"
                    name={`data.${storey}.height`}
                    unitLabel="m"
                    conversionFactor={1}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            type="submit"
            data-cy="save-simplified-seismic-input-btn"
          >
            Speichern und Ersatzkräfte berechnen
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default SimplifiedSeismicModelInputsForm
