import { ReactElement, useMemo } from 'react'
import { DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { extrudeBeam } from '../PositionMemberMeshElement/utils'
import { getShapeMatrixTransform, getShapeObject } from '../utils'

const slabMemberMeasureThickness = 0.1

interface PropsV2 {
  slabBeam: SlabBeam
  stepSize: number
}

const BeamStepSizeAreaVisual = ({ slabBeam, stepSize }: PropsV2): ReactElement => {
  const theme = useTheme()

  const points = extrudeBeam(slabBeam, slabMemberMeasureThickness, stepSize)

  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, [], matrixTransform),
    [points, matrixTransform],
  )

  return (
    <>
      <BasicMesh
        name={slabBeam.guid}
        matrix={matrixTransform}
        matrixAutoUpdate={false}
        layers={sceneConfig.R3FNonSelectableObjectLayer}
      >
        <extrudeGeometry
          args={[
            shapeObject,
            {
              depth: 0.1,
              bevelEnabled: false,
            },
          ]}
        />

        <meshStandardMaterial
          side={DoubleSide}
          color={theme.scenePalette.elements3d.ripStepSize}
          transparent={true}
          opacity={0.4}
        />
      </BasicMesh>
    </>
  )
}

export { BeamStepSizeAreaVisual }
