import { ReactElement, useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import {
  Autocomplete,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

interface Props {
  options: Connection[]
  onAdd: (c: Connection) => void
  'data-cy'?: string
}

const AddConnection = ({ options, onAdd, 'data-cy': dataCy }: Props): ReactElement => {
  const [value, setValue] = useState<null | Connection>(null)

  useEffect(() => {
    if (value) {
      onAdd(value)
      setValue(null)
    }
  }, [value])

  return (
    <TableRow>
      <TableCell>
        <IconButton size="small" disableRipple sx={{ cursor: 'auto', pl: 0 }}>
          <Add fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell sx={{ width: 300 }}>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            size="small"
            options={options}
            sx={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label="auswählen zum hinzufügen" data-cy={dataCy} />
            )}
            getOptionLabel={option => `${option.name}`}
            renderOption={(props, option) => (
              <Typography {...props} data-cy={`${dataCy}-option`}>
                {option.name}
              </Typography>
            )}
            value={value}
            onChange={(e, v) => setValue(v)}
          />
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default AddConnection
