import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { merge } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Errors, Form } from '@ui/forms'
import { useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { getProjectConnectionPrioritization, getProjectConnections } from '@queries'
import { ConnectionList } from 'src/components/manager/connections'
import ConnectionPrioritizationList from 'src/components/manager/connections/ConnectionPrioritizationList'
import { buildErrorMessage } from 'src/constants/errors'
import {
  createProjectConnection,
  deleteProjectConnection,
  editProjectConnection,
  saveProjectPrioritization,
} from 'src/state/mutations/connections'
import { schema } from './schema'

const ConnectionManagerForm = (): ReactElement => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const connections = useSystemManagerStore(state => state.connections)
  const connectionPrioritization = useSystemManagerStore(state => state.prioritization)
  const invalidateResults = useResultsInvalidation()

  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo<ProjectConnectionPrioritization>(() => {
    const schemaDefault = schema.getDefault()
    return merge(schemaDefault, connectionPrioritization)
  }, [connections])

  const { mutate: onSave, isLoading: isSaving } = useMutation(
    (connectionPrioritization: ProjectConnectionPrioritization) =>
      saveProjectPrioritization.request({ projectId, input: connectionPrioritization }),
    {
      onSuccess: async () => {
        enqueueSnackbar('Zugverbindungspriorisierung erfolgreich gespeichert', {
          variant: 'success',
        })

        await queryClient.invalidateQueries(getProjectConnectionPrioritization.getKey(projectId))

        invalidateResults(projectId as string)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindungspriorisierung'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const { mutate: onDeleteConnection } = useMutation(
    (connectionId: string) => deleteProjectConnection.request(projectId as string, connectionId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectConnections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Zugverbindung erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Zugverbindungen'), {
          variant: 'error',
        })
      },
    },
  )

  const editConnectionMutationResult = useMutation(
    (data: ProjectConnection) => editProjectConnection.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectConnections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Zugverbindung erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindung'), {
          variant: 'error',
        })
      },
    },
  )

  const createConnectionMutationResult = useMutation(
    (data: ProjectConnection) => createProjectConnection.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectConnections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Zugverbindung erfolgreich angelegt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindung'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <Form
      id="project-system-manager-connections"
      onSubmit={prioritization => onSave(prioritization)}
      validationSchema={schema}
      defaultValues={defaultValues}
    >
      <Stack direction="column" spacing={5}>
        <ConnectionList
          connections={connections}
          onDelete={({ guid }) => onDeleteConnection(guid)}
          editMutation={editConnectionMutationResult}
          createMutation={createConnectionMutationResult}
          defaultRowsPerPage={5}
        />

        <ConnectionPrioritizationList
          fieldName="inner_inner"
          title="Innen-Innen"
          availableConnections={connections}
          data-cy="inner"
        />

        <ConnectionPrioritizationList
          fieldName="inner_foundation"
          title="Innen-Fundament"
          availableConnections={connections}
          data-cy="inner-foundation"
        />

        <ConnectionPrioritizationList
          fieldName="outer_outer"
          title="Außen-Außen"
          availableConnections={connections}
          data-cy="outer"
        />

        <ConnectionPrioritizationList
          fieldName="outer_foundation"
          title="Außen-Fundament"
          availableConnections={connections}
          data-cy="outer-foundation"
        />
        <Stack spacing={2}>
          <Errors />
          <SaveButton
            loading={isSaving}
            type="submit"
            data-cy="system-manager-connections-submit"
            sx={{
              width: 'fit-content',
              alignSelf: 'end',
            }}
          >
            Speichern
          </SaveButton>
        </Stack>
      </Stack>
    </Form>
  )
}

export default ConnectionManagerForm
