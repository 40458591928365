import { ReactElement, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { filter, isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Typography, TextField, MenuItem, Button, Grid } from '@mui/material'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import {
  getTensileTransmissionGraph,
  getStiffeningIntervals,
  getStiffeningProposal,
} from '@queries'
import { saveStiffeningProposal } from '@mutations'
import { getElementLabels } from 'src/state/queries/labels'
import { getHorizontalForcesPerSegment } from 'src/state/queries/results'
import { stiffeningOptions } from './constants'

interface Props {
  onSubmitSuccess: () => void
}

const SelectionForm = ({ onSubmitSuccess }: Props): ReactElement => {
  const [stiffening, setStiffening] = useState<StiffeningElementStiffening>('Stiffening')
  const selectedIds = useStructuralPlanningStore(state => state.selectedIds)
  const addSelectedIds = useStructuralPlanningStore(state => state.addSelectedIds)
  const proposal = useStructuralPlanningStore(state => state.proposal)
  const deselectAllIds = useStructuralPlanningStore(state => state.deselectAllIds)
  const assignStiffeningIntervals = useStructuralPlanningStore(
    state => state.assignStiffeningIntervals,
  )

  const selectableIntervals = filter(
    proposal,
    interval => interval.selectable === 'Selectable' && !isUndefined(interval.localId),
  )
  const allIds = useMemo(
    () => selectableIntervals.map(interval => interval.localId) as string[],
    [],
  )

  const handleStiffeningSelection = () => {
    assignStiffeningIntervals(selectedIds, stiffening)
    deselectAllIds()
  }

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const { mutate: saveProposal, isLoading: isSaving } = useMutation(
    (data: StiffeningProposal) => saveStiffeningProposal.request(projectId, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getStiffeningProposal.getKey(projectId))
        await queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId))
        await queryClient.invalidateQueries(getElementLabels.getKey(projectId))
        await queryClient.invalidateQueries(getTensileTransmissionGraph.getKey(projectId))
        await queryClient.invalidateQueries(getHorizontalForcesPerSegment.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Aussteifende Elemente erfolgreich gespeichert', { variant: 'success' })
        onSubmitSuccess()
      },
      onError: () => {
        enqueueSnackbar('Fehler beim speichern der Aussteifenden Elemente', { variant: 'error' })
      },
    },
  )

  const handleSaveProposal = () => {
    const { proposal } = useStructuralPlanningStore.getState()
    return saveProposal(proposal)
  }

  const selectAllIds = () => {
    addSelectedIds(allIds)
  }

  return (
    <>
      <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
        <Typography variant="h6" textAlign="center">
          Ausgewählt: <span data-cy="selected-count">{selectedIds.size}</span>
        </Typography>
        <Typography mb={1}>
          Wählen Sie Elemente aus und weisen Sie diesen eine Konfiguration zu
        </Typography>
        <TextField
          select
          disabled={!selectedIds.size}
          label="Aussteifend"
          value={stiffening}
          onChange={event => setStiffening(event.target.value as StiffeningElementStiffening)}
          size="small"
          fullWidth
          sx={{ mt: 1, bgcolor: 'grey.50' }}
          data-cy="select-stiffening"
        >
          {stiffeningOptions.map(({ label, value }, index) => (
            <MenuItem data-cy={`select-stiffening-${value}`} value={value} key={index}>
              {label}
            </MenuItem>
          ))}
        </TextField>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Button
              onClick={handleStiffeningSelection}
              disabled={!selectedIds.size}
              size="small"
              variant="contained"
              data-cy="btn-submit"
              fullWidth
            >
              Zuweisen
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              onClick={deselectAllIds}
              disabled={!selectedIds.size}
              size="small"
              variant="outlined"
              fullWidth
            >
              Auswahl aufheben
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button onClick={selectAllIds} size="small" variant="outlined" fullWidth>
              Alle auswählen
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="end" mt={2}>
        <LoadingButton
          data-cy="btn-save"
          variant="contained"
          onClick={handleSaveProposal}
          loading={isSaving}
        >
          Konfiguration speichern
        </LoadingButton>
      </Box>
    </>
  )
}

export default SelectionForm
