import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { get, cloneDeep, isEqual, isUndefined } from 'lodash-es'
import { useDebouncedCallback } from 'use-debounce'

interface Arguments {
  writeCallback: (values: any) => any // eslint-disable-line
  compareValues?: boolean
  fieldName?: string
  timeout?: number
  active?: boolean
}

const useStoreSubscription = ({
  writeCallback,
  fieldName,
  timeout = 100,
  compareValues = false,
  active = true,
}: Arguments) => {
  const { watch, getValues } = useFormContext()
  const debouncedWrite = useDebouncedCallback(values => writeCallback(cloneDeep(values)), timeout)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevValues = useRef<any>()

  useEffect(() => {
    const values = getValues()
    const fieldValues = fieldName ? get(values, fieldName) : values
    prevValues.current = cloneDeep(fieldValues)
  }, [])

  useEffect(() => {
    if (active) {
      const subscription = watch(values => {
        const fieldValues = fieldName ? get(values, fieldName) : values

        if (
          !isUndefined(prevValues.current) &&
          (!compareValues || (compareValues && !isEqual(fieldValues, prevValues.current)))
        ) {
          prevValues.current = cloneDeep(fieldValues)
          debouncedWrite(fieldValues)
        } else if (isUndefined(prevValues.current)) {
          prevValues.current = cloneDeep(fieldValues)
        }
      })

      return () => subscription.unsubscribe()
    }
  }, [compareValues, active])
}

export default useStoreSubscription
