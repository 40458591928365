import {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  useCornerConnectionAdditionalLoadProposalState,
  useResultsQueryParams,
} from '@resultsHooks'
import { AxiosError } from 'axios'
import { CellChange } from 'handsontable/common'
import 'handsontable/dist/handsontable.full.min.css'
import { registerAllModules } from 'handsontable/registry'
import { HyperFormula } from 'hyperformula'
import { debounce, findIndex, isNull, isUndefined, max } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Warning } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SaveIcon from '@mui/icons-material/Save'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Popover,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { HotColumn, HotTable } from '@handsontable/react'
import HotTableClass from '@handsontable/react/hotTableClass'
import { useControlStore, useResultsStore } from '@editorStores'
import { useElementLabel, useSupportGuidToElement } from '@editorHooks'
import { getAnchorChecks, getAnchorInterventionTableData } from '@queries'
import { saveManualLoadsPerSupport } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { CellColourerAndRounder, CellRounder } from './components/HotRendererCells'
import { anchorTableColumns, loadSources } from './constants'
import './table.css'
import { getLoadsOrFunctionsForRow, getValidatedRowOrError } from './utils'

// register Handsontable's modules
registerAllModules()

interface Ref {
  selectCell: (row: number, column: number) => void
  insertCurrentCell: (val: string) => void
  insertCell: (row: number, column: number, val: string) => void
  selectSupportLoadInput: (guid: string) => [number, number]
}

export type { Ref as AnchorDataGridRef }

interface Props {
  disallowSelection?: boolean
}

const AnchorDataGrid = forwardRef<Ref, Props>(function AnchorDataGrid(
  { disallowSelection = false }: Props,
  ref,
): ReactElement {
  useImperativeHandle(ref, () => ({
    selectCell: (row: number, column: number) => {
      hotTableComponent.current?.hotInstance?.selectCell(row, column)
    },
    insertCurrentCell: (val: string) => {
      const selected = hotTableComponent.current?.hotInstance?.getSelected()
      if (selected && selected.length === 1) {
        hotTableComponent.current?.hotInstance?.setDataAtCell(selected[0][0], selected[0][1], val)
      }
    },
    insertCell: (row: number, column: number, val: string) => {
      const selected = hotTableComponent.current?.hotInstance?.getSelected()
      if (selected && selected.length === 1) {
        hotTableComponent.current?.hotInstance?.setDataAtCell(row, column, val)
      }
    },
    selectSupportLoadInput: (supportGuid: string) => {
      const row = findIndex(tableData, data => data.support_guid === supportGuid)
      const column = findIndex(anchorTableColumns, column => column === 'Last [kN]')
      hotTableComponent.current?.hotInstance?.selectCell(row, column)

      return [row, column]
    },
  }))

  const hotTableComponent = useRef<HotTableClass>(null)

  const { projectId } = useParams()

  const anchorInterventionTableData = useResultsStore(state => state.anchorInterventionTableData)
  const tensileTransmissionGraph = useResultsStore(state => state.tensileTransmissionGraph)

  const anchorChecks = useResultsStore(state => state.anchorChecks)

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const getElementLabel = useElementLabel()
  const setIsAnchorCalculationMode = useControlStore(state => state.setIsAnchorCalculationMode)
  const setIsBottomDrawerExpanded = useControlStore(state => state.setIsBottomDrawerExpanded)

  const supportGuidToElementType = useSupportGuidToElement(tensileTransmissionGraph)

  const {
    params: { selectedConnector },
    actions: { selectConnector },
  } = useResultsQueryParams()

  const { selectAnchor } = useCornerConnectionAdditionalLoadProposalState()

  const tableData: TableData[] = useMemo(() => {
    if (!anchorInterventionTableData) return []

    const uniqueSourceGuids = [
      ...new Set(
        anchorInterventionTableData.map(anchorInterventionRow => anchorInterventionRow.source_guid),
      ),
    ]

    // Iterate over source IDs, which gives allows us to maintain the grouping
    const rows: TableData[] = uniqueSourceGuids
      .map(sourceGuid => {
        const rowsForSource = anchorInterventionTableData.filter(
          row => row.source_guid === sourceGuid,
        )

        const rowsToReturn: TableData[] = rowsForSource.map((row, anchorIndex) => {
          const loadsCombined = getLoadsOrFunctionsForRow(row)
          const anchorCheckForSupport = anchorChecks?.find(
            check => check.element_guid === row.support_guid,
          )
          const anchorCheckFields = anchorCheckForSupport
            ? {
                anchor_name: anchorCheckForSupport.used_anchor.name,
                anchor_utilization: anchorCheckForSupport.utilization.toString(),
              }
            : {
                anchor_name: '',
                anchor_utilization: '',
              }

          return {
            support_guid: row.support_guid,
            source_guid: row.source_guid,
            element_label: getElementLabel(row.element_guid),
            segment_label: row.segment_guid ? getElementLabel(row.segment_guid) : '',
            anchor_number: anchorIndex + 1,
            modugen_load: (row.original_design_force / 1000).toFixed(2),
            modugen_load_source: row.original_design_force_source,
            ...loadsCombined,
            sum_of_loads: '', // populate later when we know the sorted index
            ...anchorCheckFields,
            comment: row.comment,
          }
        })
        // Sort the rows by load - descending (highest first)
        return rowsToReturn.sort((a, b) => Number(b.modugen_load) - Number(a.modugen_load))
      })
      // Sort by the maximum load of each group
      .sort((a, b) => {
        const maxA = max(a.map(row => Number(row.modugen_load))) as number
        const maxB = max(b.map(row => Number(row.modugen_load))) as number

        return maxB - maxA
      })
      .flat()

    const rowsWithSumFormula = rows.map((row, rowIndex) => {
      // Sum of:
      // originalDesignForce + manualLoads
      const sumCellFunction = '=D'.concat(
        String(rowIndex + 1),
        '+F',
        String(rowIndex + 1),
        '+H',
        String(rowIndex + 1),
        '+J',
        String(rowIndex + 1),
      )

      return {
        ...row,
        sum_of_loads: sumCellFunction,
      }
    })

    return rowsWithSumFormula
  }, [anchorInterventionTableData, anchorChecks, getElementLabel])

  useEffect(() => {
    const hot = hotTableComponent?.current?.hotInstance

    const rowInTable = findIndex(tableData, ['support_guid', selectedConnector])
    if (!hot || rowInTable === -1) return
    const visualRow = hot.toVisualRow(rowInTable)

    if (isUndefined(visualRow) || isNull(visualRow)) return
    const selected = hot.getSelected()
    if (!selected) {
      hot.selectRows(visualRow)
      return
    }

    const startRow = selected[0][0]
    const startCol = selected[0][1]
    if (visualRow === startRow) return
    // Only select cell in the row of choice if already selected
    hot.selectCell(visualRow, startCol)
  }, [selectedConnector, tableData])

  const { isLoading, mutate } = useMutation(
    (requestData: ManualLoadOnSupport[]) => {
      return saveManualLoadsPerSupport.request(projectId, requestData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAnchorInterventionTableData.getKey(projectId))
        queryClient.invalidateQueries(getAnchorChecks.getKey(projectId))
        enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern'), { variant: 'error' })
      },
    },
  )

  /**
   * @returns true if save is successful and false otherwise
   */
  const validateAndSave = (table: TableData[]) => {
    if (table.length === 0) {
      // Nothing to save is usually caused by data not being loaded yet.
      // saving with an empty object has dangerous side effects (wiping
      // the backend node data)
      return false
    }

    const requestData: (ManualLoadOnSupport | Error)[] = table.map((dataRow, index) =>
      getValidatedRowOrError(dataRow, index),
    )

    const errors = requestData.filter(requestDataRow => requestDataRow instanceof Error) as Error[]
    if (errors.length > 0) {
      errors.map(error => enqueueSnackbar(error.message, { variant: 'error' }))
      return false
    }

    mutate(requestData as ManualLoadOnSupport[])
    return true
  }

  const onSelectRowDebounced = debounce(
    (row: number) => selectConnector(tableData[row].support_guid),
    200,
  )

  const exportTableAsCSV = () => {
    const hot = hotTableComponent?.current?.hotInstance

    if (!hot) return

    const exportPlugin = hot.getPlugin('exportFile')

    exportPlugin.downloadFile('csv', {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: 'csv',
      filename: 'AnchorInverventionsTable-CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n',
      rowHeaders: true,
    })
  }

  const [anchorEl, setAnchorEl] = useState<{
    element: HTMLTableCellElement | null
    supportGuid: string
    rowIndex: number
    columnIndex: number
  } | null>(null)

  const onChangeCell = useCallback(
    (changes: CellChange[] | null) => {
      if (!changes) return

      if (
        changes.length === 1 &&
        changes[0][1].toString().substring(0, 14) === 'manual_source_' &&
        changes[0][3] === 'Wand über Eck'
      ) {
        const sourceColumnIndexes = anchorTableColumns
          .map((column, index) => (column === 'Herkunft' ? index : -1))
          .filter(index => index !== -1)

        const changedSourceColumnIndex =
          parseInt(changes[0][1].toString().charAt(changes[0][1].toString().length - 1), 10) - 1

        const resultLoadColumnIndex = sourceColumnIndexes[changedSourceColumnIndex] - 1

        const tableRowIndex = changes[0][0]
        const supportGuid = tableData[tableRowIndex].support_guid
        const supportElementType = supportGuidToElementType?.[supportGuid]?.type
        const allowedTypes: ElementTypes[] = ['inner_walls', 'outer_walls', 'columns']

        if (!allowedTypes.includes(supportElementType as ElementTypes)) {
          enqueueSnackbar({
            message:
              'Aktuell wird die Eingabehilfe für die Eckverschraubungen nur für Zuganker in einer Wand/Stüzte unterstützt',
            variant: 'info',
          })
          return
        }

        const cellElement = hotTableComponent.current?.hotInstance?.getCell(
          tableRowIndex,
          resultLoadColumnIndex,
        )
        if (cellElement) {
          setAnchorEl({
            element: cellElement,
            rowIndex: tableRowIndex,
            columnIndex: resultLoadColumnIndex,
            supportGuid,
          })
        }
      }
    },
    [enqueueSnackbar, supportGuidToElementType, tableData],
  )

  return (
    <>
      <Tooltip title="Save" placement="top" enterDelay={500}>
        <IconButton
          aria-label="save-manual-loads"
          disabled={isLoading}
          size="large"
          sx={(theme: Theme) => ({
            position: 'absolute',
            left: 12,
            top: -12,
            zIndex: 100000,
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#3c52b2',
            },
          })}
          onClick={() => validateAndSave(tableData)}
          data-cy="save-manual-loads-button"
        >
          <SaveIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Export" placement="top" enterDelay={500}>
        <IconButton
          aria-label="export-anchor-intervention-table"
          disabled={isLoading}
          size="large"
          sx={(theme: Theme) => ({
            position: 'absolute',
            left: 64,
            top: -12,
            zIndex: 100000,
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#3c52b2',
            },
          })}
          onClick={() => exportTableAsCSV()}
          data-cy="export-anchor-intervention-table"
        >
          <FileDownloadIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Close" placement="top" enterDelay={500}>
        <IconButton
          aria-label="close-bottom-drawer"
          size="medium"
          sx={(theme: Theme) => ({
            position: 'absolute',
            right: 20,
            top: -12,
            zIndex: 100000,
            transform: 'rotate(180deg)',
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#3c52b2',
            },
          })}
          onClick={() => {
            if (validateAndSave(tableData)) {
              // Only close when save was successful
              setIsBottomDrawerExpanded(false)
              setIsAnchorCalculationMode(false)
              selectConnector(undefined)
            }
          }}
          data-cy="close-bottom-drawer-button"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Box
        component="div"
        sx={
          disallowSelection
            ? {
                '& *': {
                  cursor: 'not-allowed',
                },
              }
            : undefined
        }
        width="100%"
        height="100%"
      >
        <HotTable
          ref={hotTableComponent}
          id="anchor-interventions-hot"
          data={tableData}
          rowHeaders={true}
          // multiColumnSorting={true} // disable for now as it messes with selecting
          width="100%"
          height="100%"
          nestedHeaders={[
            [{ label: '', colspan: 15 }], // extra row to allow for context buttons
            [
              '',
              '',
              '',
              '',
              '',
              { label: 'zusätzliche Last 1', colspan: 2 },
              { label: 'zusätzliche Last 2', colspan: 2 },
              { label: 'zusätzliche Last 3', colspan: 2 },
              '',
              { label: 'Checks (require save)', colspan: 2 },
              '',
            ],
            anchorTableColumns,
          ]}
          // disableVisualSelection={disallowSelection}
          readOnly={disallowSelection}
          afterSelection={(row, column) => {
            if (disallowSelection) return

            if (row >= 0 && column >= 0) {
              // The following line relies on the data being displayed as given, i.e.
              // no sorting (otherwise the row number would not be mappable to the
              // tableData)
              onSelectRowDebounced(row)
            }
          }}
          manualColumnResize={true}
          stretchH="last"
          formulas={{
            engine: HyperFormula,
          }}
          currentRowClassName="currentRow"
          outsideClickDeselects={false}
          // TODO: add correct license key here
          licenseKey="non-commercial-and-evaluation"
          afterChange={onChangeCell}
        >
          <HotColumn data={'element_label'} readOnly={true} />
          <HotColumn data={'segment_label'} readOnly={true} />
          <HotColumn data={'anchor_number'} readOnly={true} />
          <HotColumn data={'modugen_load'} readOnly={true}>
            <CellColourerAndRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'modugen_load_source'} readOnly={true} />
          <HotColumn data={'manual_load_1'} type="text">
            <CellRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'manual_source_1'} type="autocomplete" source={loadSources} />
          <HotColumn data={'manual_load_2'} type="text">
            <CellRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'manual_source_2'} type="autocomplete" source={loadSources} />
          <HotColumn data={'manual_load_3'} type="text">
            <CellRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'manual_source_3'} type="autocomplete" source={loadSources} />
          <HotColumn data={'sum_of_loads'} readOnly={true}>
            <CellColourerAndRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'anchor_name'} readOnly={true} />
          <HotColumn data={'anchor_utilization'} readOnly={true}>
            <CellRounder hot-renderer />
          </HotColumn>
          <HotColumn data={'comment'} />
        </HotTable>
      </Box>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl?.element}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Eingabehilfe für zusätzliche Lasten.</Typography>
              <Alert icon={<Warning fontSize="inherit" />} severity="warning">
                In dieser Zeit wird die Tabelle für Eingaben gesperrt.{' '}
              </Alert>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                if (!anchorEl) return
                selectAnchor(anchorEl.supportGuid, anchorEl.rowIndex, anchorEl.columnIndex)
                setAnchorEl(null)
              }}
              variant="contained"
            >
              Wand auswählen
            </Button>
            <Button onClick={() => setAnchorEl(null)}>Abbrechen</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  )
})

export default AnchorDataGrid
