import { ReactElement, useEffect, useMemo } from 'react'
import { filter, find, last } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { Stack } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useModelClickListeners, useSelectionMode, useTypeInteraction } from '@editorHooks'
import { useStructuralPlanningFormEsc } from '@structuralPlanningHooks'
import useStructuralPlanningQueryParams from '../../hooks/useStructuralPlanningQueryParams'
import { WallRipForm } from '../FloorplanDrawer/components/WallRipForm'
import { WALL_RIP_SELECTION } from './constants'

interface Props {
  rip?: string
  onClose: () => void
}

const WallRipForm3D = ({ rip: ripGuid, onClose }: Props): ReactElement => {
  const {
    actions: { setElements },
  } = useStructuralPlanningQueryParams()

  const rips = useModelStore(state => state.model.rips)
  const removeRip = useModelStore(state => state.removeRip)

  const rip = useMemo(() => find(rips, { position_guid: ripGuid }), [rips, ripGuid])

  const isDrawing = useEditElementStore(state => state.isDrawingRip)

  const model = useModelStore(state => state.model)

  const addRip = useModelStore(state => state.addRip)

  const { setSelectionMode, isSelectionMode, unsetSelectionMode } = useSelectionMode()

  useTypeInteraction(['inner_walls', 'outer_walls'])

  useEffect(() => {
    setSelectionMode({
      key: WALL_RIP_SELECTION,
      message: 'Wählen Sie eine Wand zum einzeichnen von Wandrippen aus',
    })

    return () => {
      unsetSelectionMode()
    }
  }, [])

  useModelClickListeners(
    event => {
      const guid = event.object.name

      const wall = find(model.walls, { guid }) as ShapeObject

      const start = wall.shape.points[0]
      const end = last(wall?.shape.points) as ImmutableVector3

      const xDirection = wall.shape.points[0].directionTo(
        last(wall.shape.points) as ImmutableVector3,
        true,
      )
      const yDirection = wall.shape.points[0].directionTo(wall.shape.points[1], true)
      const zDirection = xDirection.cross(yDirection).normalize()

      const newRip: Rip = {
        wall_guid: guid,
        position_guid: uuid(),
        domain_guid: uuid(),
        start,
        end,
        coordinate_system: {
          origin: start,
          x_direction: xDirection,
          y_direction: yDirection,
          z_direction: zDirection,
          TOLERANCE: 0,
        },
        is_local: true,
      }

      const localRips = filter(rips, { is_local: true })
      localRips.forEach(rip => {
        removeRip(rip.position_guid)
      })

      setElements([newRip.position_guid])
      addRip(newRip)
      unsetSelectionMode()
    },
    [model, rips],
    isSelectionMode,
  )

  useStructuralPlanningFormEsc(() => {
    // rip has to be saved otherwise remove
    if (rip?.is_local) {
      removeRip(rip.position_guid)
    }
    onClose()
  }, !!ripGuid)

  return (
    <Stack direction="column" spacing={2}>
      {ripGuid && rip && !isDrawing && <WallRipForm positionGuid={ripGuid} />}
    </Stack>
  )
}

export default WallRipForm3D
